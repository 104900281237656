import Modal from 'react-modal';
import { IoIosClose } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { Container } from './styled';

const customStyles = {
    content: {
        //transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px",
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalImportShopee = (props) => {

    const dispatch = useDispatch()

    const closeModal = () => {
        props.setVisibleModal(false);
        dispatch({
            type: "SAGA_LIST_PRODUCTS", payload: {
                search: "",
                perPage: 20,
                page: 1
            }
        })
    }

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
        //onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <Container>
                <iframe
                    src={`${process.env.REACT_APP_API_URL}/import/shopee?productId=${props.item.id}&token=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}`}
                    width={"100%"}
                    height={"100%"}
                />
            </Container>
            <IoIosClose onClick={() => { closeModal() }} style={{ position: "absolute", right: "10", top: "10", cursor: "pointer" }} size={30} color={"gray"} />
        </Modal>
    )
}

export default ModalImportShopee