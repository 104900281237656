import { BarLeft, ButtonDefault, Box, BoxActions, ButtonPreview, InputTextCustom, InputFileCustom, SelectCustom, BoxLoading, BoxLoadingCustom, FormCustom, InputCustomItem, ButtonSave, ErrorMessage, BoxFormTitle, BoxForm, InputCustomItemColor, TextAreaCustom, Tooltip, ContainerPreview, Submenu, BoxControlMobile, BarSubmenu, BarSubmenuItem } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { displayScript, isVisibleScript } from "../../../../utils/displayScript";
import { FaRegQuestionCircle, FaUpload } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import { ImageFileToBase64 } from "../../../../utils/convert";
import imageCompression from 'browser-image-compression';
import { set } from "date-fns";
import ModalPreviewEmail from "../../components/modal-preview-email";
import { useHistory, useLocation } from "react-router-dom";
import FormTema from "../../components/form-tema";
import FormEmail from "../../components/form-email";
import * as Yup from 'yup';
import AlertTimeUpdate from "../../../../components/Alert";
import FormLoading from "../../components/form-loading";
import FormHome from "../../components/form-home";
import { getTranslation } from "../../../../translations";
import PreviewTheme from "../../components/preview-theme";

import SetaImg from "../../../../assets/imgs/icons-sidebar/submenu-seta.svg";
import IconImgProduct from "../../../../assets/imgs/icons-sidebar/submenu-product.svg";
import IconImgProductInativo from "../../../../assets/imgs/icons-sidebar/submenu-product-inativo.svg";
import IconImgEmail from "../../../../assets/imgs/icons-sidebar/submenu-email-inativo.svg";
import IconImgEmailAtivo from "../../../../assets/imgs/icons-sidebar/submenu-email-ativo.svg";
import IconImgHome from "../../../../assets/imgs/icons-sidebar/submenu-home-inativo.svg";
import IconImgHomeAtivo from "../../../../assets/imgs/icons-sidebar/submenu-home-ativo.svg";
import BarConfigProduct from "../../components/bar-config-product-cores";
import BarConfigProductAjustes from "../../components/bar-config-product-ajustes";
import BarConfigEmail from "../../components/bar-config-email-cores";
import BarConfigEmailAjustes from "../../components/bar-config-email-ajustes";
import BarConfigHomeHabilitar from "../../components/bar-config-home-habilitar";
import BarConfigHomeConfig from "../../components/bar-config-home-config";

import { IoIosArrowBack } from "react-icons/io";
import BarConfigHomeColor from "../../components/bar-config-home-color";
import ModalAlertSave from "../../components/modal-alert-save";
import LoadingLogo from "../../../../components/Loading-logo";

const Home = () => {

    const dispatch = useDispatch()
    const selectedMenu = useLocation();
    const statusUpdateConfig = useSelector(state => state.reducerUpdateConfig)
    const config = useSelector(state => state.reducerGetConfig)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const edit = useSelector(state => state.reducerControlEdit)

    const history = useHistory()
    const reviewsFavorites = useSelector(state => state.reducerReviews)

    const [visibleModalAlertSave, setVisibleModalAlertSave] = useState(false)


    useEffect(() => {
        dispatch({ type: "SAGA_GET_CONFIG" })
    }, [])


    useEffect(() => {
        const id = "statusUpdateConfig"
        if (!(statusUpdateConfig.loading == false && !statusUpdateConfig.error == false && statusUpdateConfig.data.length == 0)) {
            if (statusUpdateConfig.loading) {
                toast.loading(translation.thema.aguarde, { toastId: id })
            } else {
                if (statusUpdateConfig.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.thema.podeLevar, type: "success", isLoading: false, hideProgressBar: false, autoClose: 5000, closeOnClick: true })
                    //formikTheme.resetForm();
                    //formikEmail.resetForm();
                    //setEmail(JSON.parse(localStorage.getItem("depoimentos@login"))?.user.email)
                    dispatch({ type: "SAGA_GET_CONFIG", })
                }
            }
        } else if (statusUpdateConfig.error) toast.update(id, { render: translation.thema.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    }, [statusUpdateConfig])

    // useEffect(() => {
    //     //console.log("Config", config)
    //     if (config.data.length != 0) {
    //         setLogo(config.data.request_email_logourl)
    //         set_visible_button_ask(config.data.visible_button_ask)
    //         set_visible_button_review(config.data.visible_button_review)
    //         set_no_review_star(config.data.no_review_star)
    //         set_visible_date_review(config.data.visible_date_review)
    //         set_itens_per_page(config.data.itens_per_page)
    //         setTemplateQuestions(config.data.template_ask)
    //         setTemplateReviews(config.data.template_review)
    //         //console.log("TESTE..", typeof config.data.request_email_logourl)
    //     }
    // }, [config])

    // useEffect(() => {
    //     console.log("logo", logo)
    // }, [logo])



    // const formikTheme = useFormik({
    //     enableReinitialize: true,
    //     initialValues: {
    //         star_color: config.data.star_color,
    //         font_color: config.data.font_color,
    //         primary_color: config.data.primary_color
    //     },
    //     validationSchema: Yup.object({
    //         star_color: Yup.string().required("Cor da estrela não pode ser vazio."),
    //         font_color: Yup.string().required("Cor da fonte não pode ser vazio."),
    //         primary_color: Yup.string().required("Cor principal não pode ser vazio."),
    //     }),
    //     onSubmit: (values) => {
    //         dispatch({
    //             type: "SAGA_UPDATE_CONFIG", payload: {
    //                 id: config.data.id,
    //                 star_color: values.star_color,
    //                 font_color: values.font_color,
    //                 primary_color: values.primary_color,
    //                 no_review_star: no_review_star,
    //                 visible_button_review: visible_button_review,
    //                 visible_button_ask: visible_button_ask,
    //                 visible_date_review: visible_date_review,
    //                 itens_per_page: itens_per_page,
    //                 template_review: templateReviews,
    //                 template_ask: templateQuestions
    //             }
    //         })
    //     }
    // });

    useEffect(() => {
        //console.log("teste edit", edit)
    }, [edit])


    const routerBar = () => {
        if (selectedMenu.pathname == "/personalizar" || selectedMenu.pathname == "/personalizar/tema") {
            if (edit == "produto/config") {
                return <BarConfigProductAjustes />
            } else {
                return <BarConfigProduct />
            }
        } else if (selectedMenu.pathname == "/personalizar/email") {
            if (edit == "email/config") {
                return <BarConfigEmailAjustes />
            } else {
                return <BarConfigEmail />
            }
        }
        else if (selectedMenu.pathname == "/personalizar/home") {
            if (edit == "home/config") {
                return <BarConfigHomeConfig />
            } else {
                return <BarConfigHomeColor />
            }
        }
    }

    useEffect(() => {
        dispatch({
            type: "SAGA_LIST_REVIEWS", payload: {
                rating: "",
                status: "all",
                search: "",
                perPage: 10,
                page: 1,
                medias: "",
                favorite: true
            }
        })
    }, [])

    const visibleFormHome = () => {
        if (selectedMenu.pathname == "/personalizar/home") {
            if (
                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "" &&
                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != null &&
                typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "undefined"
                //&&                reviewsFavorites?.data?.meta?.total > 7
            ) {
                return true
            } else {
                return false
            }
        } else {
            return true
        }

    }

    return (
        <Body>
            {/* <ModalAlertSave visible={visibleModalAlertSave} setVisible={setVisibleModalAlertSave}  /> */}
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1" }} >
                <Header />
                <Contents padding={"null"}>
                    {config.loading ?
                        // <FormLoading />
                        <div style={{ display: "flex", flex: 1, height: "100%",justifyContent: "center", alignItems: "center" }}>
                            <LoadingLogo />
                        </div>
                        :
                        <BoxControlMobile active={edit != "" ? true : false}>
                            <BarLeft isFull={visibleFormHome()} >
                                {routerBar()}
                            </BarLeft>
                            <div style={{ display: "flex", flex: "1", justifyContent: "center", height: "100%", }}>
                                {selectedMenu.pathname == "/personalizar/tema" && <FormTema />}
                                {selectedMenu.pathname == "/personalizar" && <FormTema />}
                                {selectedMenu.pathname == "/personalizar/email" && <FormEmail />}
                                {selectedMenu.pathname == "/personalizar/home" && <FormHome />}
                            </div>

                            {/* {selectedMenu.pathname == "/personalizar/tema" && <FormTema />}
                            {selectedMenu.pathname == "/personalizar" && <FormTema />}
                            {selectedMenu.pathname == "/personalizar/email" && <FormEmail />}
                            {selectedMenu.pathname == "/personalizar/home" && <FormHome />} */}
                        </BoxControlMobile>
                    }
                </Contents>
            </div>
            {isVisibleScript(false)}
        </Body >
    )
}

export default Home

