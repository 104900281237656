
import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE, DELETE_CUSTOM } from "../../../../services/api";

export function* listProducts(action) {

    if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform) {
        //console.log("listProducts action", action)
        yield put({ type: "PRODUCTS_REQUEST", })
        //yield delay(5000);
        const result = yield call(GET, { endpoint: `/products/integration/${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform}?search=${action.payload.search}&perPage=${action.payload.perPage}&page=${action.payload.page}` })
        //console.log("listProducts result", result)
        if (result === false) {
            yield put({ type: "PRODUCTS_ERROR" })
        } else {
            yield put({ type: "PRODUCTS_SUCCESS", payload: result.data.data })
        }
    } else {
        yield put({ type: "PRODUCTS_REQUEST", })
        yield put({ type: "PRODUCTS_SUCCESS", payload: [] })
    }


}

export function* updateProduct(action) {

    yield put({ type: "PRODUCT_UPDATE_REQUEST", })
    //yield delay(200);
    const result = yield call(PUT, { endpoint: `/products/${action.payload.id}`, data: action.payload })
    if (result === false) {
        yield put({ type: "PRODUCT_UPDATE_ERROR" })
    } else {
        yield put({ type: "PRODUCT_UPDATE_SUCCESS", payload: result.data })


    }
}

export function* updateDataProduct(action) {

    yield put({ type: "PRODUCT_DATA_UPDATE_REQUEST", })
    //yield delay(200);
    const result = yield call(PUT, { endpoint: `/products/platform/${action.payload.id}` })
    if (result === false) {
        yield put({ type: "PRODUCT_DATA_UPDATE_ERROR" })
    } else {

        const result2 = yield call(PUT, { endpoint: `/products/duplicate/${action.payload.id}` })

        if (result2 === false) {
            yield put({ type: "PRODUCT_DATA_UPDATE_ERROR" })
        } else {
            yield put({ type: "PRODUCT_DATA_UPDATE_SUCCESS", payload: result.data })
        }

        // yield put({ type: "PRODUCT_DATA_UPDATE_SUCCESS", payload: result.data })

        // const result2 = yield call(GET, { endpoint: `/products/integration/${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform}?search=&perPage=20}&page=1` })
        // //console.log("listProducts result", result)
        // if (result2 === false) {
        //     yield put({ type: "PRODUCTS_ERROR" })
        // } else {
        //     yield put({ type: "PRODUCTS_SUCCESS", payload: result2.data.data })
        //     const result2 = yield call(PUT, { endpoint: `/products/duplicate/${action.payload.id}` })
        // }
    }
}

export function* deleteProduct(action) {

    //console.log("deleteCupom ", action)

    yield put({ type: "PRODUCT_DELETE_REQUEST", })
    //yield delay(200);
    const result = yield call(DELETE, { endpoint: `/products/${action.payload.id}` })
    if (result === false) {
        yield put({ type: "PRODUCT_DELETE_ERROR" })
    } else {
        yield put({ type: "PRODUCT_DELETE_SUCCESS", payload: result.data })
    }
}


export function* addProduct(action) {
    //("addProduct action", action)
    yield put({ type: "PRODUCT_ADD_REQUEST", })
    //yield delay(200);
    const result = yield call(POST, { endpoint: `/products`, data: action.payload })
    if (result === false) {
        yield put({ type: "PRODUCT_ADD_ERROR" })
    } else {
        yield put({ type: "PRODUCT_ADD_SUCCESS", payload: result.data })
    }
}

export function* deleteSelectProducts(action) {

    //console.log("DeleteSelectProducts payload: ", action.payload)
    yield put({ type: "PRODUCTS_DELETE_SELECT_REQUEST", })
    //yield delay(200);
    const result = yield call(DELETE_CUSTOM, { endpoint: `/products/all`, data: action.payload })
    if (result === false) {
        yield put({ type: "PRODUCTS_DELETE_SELECT_ERROR" })
    } else {
        yield put({ type: "PRODUCTS_DELETE_SELECT_SUCCESS", payload: result.data })
    }
}