import { addDays } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { GrFormClose } from "react-icons/gr";
import { AiFillTag, AiOutlineNumber } from "react-icons/ai";
import { BsFillImageFill } from "react-icons/bs";
import { FaBoxOpen } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";

import { CheckCustom, Container, Error, Title, FormCustom, Close, ButtonCustom, InputCustomText, InputCustomDate, BoxInput, TextAreaCustom } from "./styled";
import { getTranslation } from "../../../../translations";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalEdit = (props) => {

    const statusAddProduct = useSelector(state => state.reducerAddProduct)
    const dispatch = useDispatch()
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);


    useEffect(() => {
        if (!(statusAddProduct.loading == false && !statusAddProduct.error == false && statusAddProduct.data.length == 0)) {
            const id = "statusAddCupom"
            if (statusAddProduct.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusAddProduct.error) toast.update(id, { render: "Erro ao tentar salvar cupom.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: "Salvo com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    props.setVisibleModal(false)
                    formik.resetForm();
                }
            }
        }
    }, [statusAddProduct])

    const formik = useFormik({
        initialValues: { canonical_url: '', name: "", description: '', image_url: "" },
        validationSchema: Yup.object({
            canonical_url: Yup.string().required("Preencher o campo URL do produto."),
            name: Yup.string().required("Preencher o campo nome.").max(100, "Máximo de 50 caracteres.").min(3, "Minimo de 3 caracteres."),
            //description: Yup.string().required("Preencher o campo descrição.").min(3, "Minimo de 3 caracteres.").max(100, "Máximo de 100 caracteres."),
            image_url: Yup.string().required("Preencher o campo URL da imagem."),
        }),
        onSubmit: (values) => {
            //console.log("formik")
            dispatch({ type: "SAGA_ADD_PRODUCT", payload: { ...values, user_token: JSON.parse(localStorage.getItem("depoimentos@login")).integration.user_token } })
        },
    });


    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { formik.resetForm(); props.setVisibleModal(false) }}
        >
            <Container onSubmit={formik.handleSubmit}>
                <Close onClick={() => { props.setVisibleModal(false) }}><IoIosClose size={25} color={"gray"} /></Close>
                <Title><strong>{translation.products.modalAdd.cadastrarProduto}</strong></Title>
                <FormCustom>
                    {/* <BoxInput>
                        <label>ID:</label>
                        <InputCustomText>
                            <span>
                                <AiOutlineNumber size={20} color={"gray"} />
                            </span>
                            <input type={"text"} placeholder={"01234"} name={"product_id"} value={formik.values.product_id} onChange={formik.handleChange} />
                        </InputCustomText>
                         {formik.errors.code && formik.touched.code && <Error>{formik.errors.code}</Error>}
                    </BoxInput> */}
                    <BoxInput>
                        <label>{translation.products.modalAdd.urlProduto}</label>
                        <InputCustomText>
                            <span>
                                <FaBoxOpen size={20} color={"gray"} />
                            </span>
                            <input type={"text"} placeholder={"https://meusite.com/produtos/produto-x"} name={"canonical_url"} value={formik.values.canonical_url} onChange={formik.handleChange} />
                        </InputCustomText>
                        {formik.errors.canonical_url && formik.touched.canonical_url && <Error>{formik.errors.canonical_url}</Error>}
                    </BoxInput>
                    <BoxInput>
                        <label>{translation.products.modalAdd.nome}</label>
                        <InputCustomText>
                            <span>
                                <FaBoxOpen size={20} color={"gray"} />
                            </span>
                            <input type={"text"} placeholder={"Produto x"} name={"name"} value={formik.values.name} onChange={formik.handleChange} />
                        </InputCustomText>
                        {formik.errors.name && formik.touched.name && <Error>{formik.errors.name}</Error>}
                    </BoxInput>
                    {/* <BoxInput>
                        <label>Descrição:</label>
                        <TextAreaCustom rows={3} placeholder={"O produto x tem ótima qualidade e preço baixo. Sucesso em vendas."} name={"description"} value={formik.values.description} onChange={formik.handleChange} />
                        {formik.errors.description && formik.touched.description && <Error>{formik.errors.description}</Error>}
                    </BoxInput> */}
                    <BoxInput>
                        <label>{translation.products.modalAdd.urlImagem}</label>
                        <InputCustomText>
                            <span>
                                <BsFillImageFill size={20} color={"gray"} />
                            </span>
                            <input type={"text"} placeholder={"https://www.meusite.com.br/imagens/produto-x.png"} name={"image_url"} value={formik.values.image_url} onChange={formik.handleChange} />
                        </InputCustomText>
                        {formik.errors.image_url && formik.touched.image_url && <Error>{formik.errors.image_url}</Error>}
                    </BoxInput>

                </FormCustom>
                <ButtonCustom type="submit">{translation.products.modalAdd.salvar}</ButtonCustom>
            </Container>
        </Modal>
    )
}

export default ModalEdit
