import Modal from 'react-modal';
import { CheckCustom, Container, Error, Title, FormCustom, Close, ButtonCustom, InputCustomText, InputCustomDate, BoxInput, TextAreaCustom, ListCard } from "./styled";
import CardLoadingReview from "../../../reviews/components/card-loading";
import CardQuestion from "../../../questions/components/card";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { IoIosClose } from "react-icons/io";
import { addReplies } from '../../../questions/redux/sagas/sagaQuestion';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalListQuestions = (props) => {

    const dispatch = useDispatch()
    const questions = useSelector(state => state.reducerQuestions)
    const statusUpdateQuestion = useSelector(state => state.reducerUpdateQuestion)
    const statusDeleteQuestion = useSelector(state => state.reducerDeleteQuestion)
    const statusAddReplies = useSelector(state => state.reducerAddReplies)
    const statusUpdateReplies = useSelector(state => state. reducerUpdateReplies)

    useEffect(() => {
        if(props.visible){
            dispatch({
                type: "SAGA_LIST_QUESTIONS", payload: {
                    status: "all",
                    search: props?.search,
                    perPage: 100,
                    page: 1,
                    log: "props.visible",
                    productId: props?.id
                }
            })
        }
    }, [props.visible])


    useEffect(() => {
        if (props.visible) {
            dispatch({
                type: "SAGA_LIST_QUESTIONS", payload: {
                    status: "all",
                    search: props?.search,
                    perPage: 100,
                    page: 1,
                    log: "props.visible",
                    productId: props?.id
                }
            })
        }
    }, [statusUpdateQuestion, statusDeleteQuestion, statusAddReplies, statusUpdateReplies])

    const clear = () => {
       
        props.setVisibleModal(false)
        //props.setControlUpdate(true)
    }

    useEffect(() => {
        if (questions.data?.data?.length == 0 && props.visible) {
            props.setControlUpdate(true)
        }
    }, [questions])


    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { props.setVisibleModal(false); props.setControlUpdate(true) }}
        >
            <IoIosClose onClick={() => { props.setVisibleModal(false); props.setControlUpdate(true) }} style={{ position: "absolute", top: "10px", right: "10px", cursor: "pointer" }} size={25} color={"gray"} />
            <Container>
                <ListCard>
                    {questions.loading ?
                        <>
                            <div style={{ marginBottom: "20px" }} />
                            {Array(3).fill(<CardLoadingReview />)}
                        </>
                        :
                        questions.data?.data?.length == 0 ?
                            clear() :
                            questions.data.data?.map((item) => {
                                return (<CardQuestion noVisibleCheck={true} key={item.id} item={item} />)
                            })}
                </ListCard>
            </Container>
        </Modal >
    )
}

export default ModalListQuestions
