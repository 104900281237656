import { Container, InstallIntegration, Tooltip } from "./styled"
import { BsTools } from "react-icons/bs";
import { useState } from "react";
import ModalInfor from "../../../integrations/components/Modal-Integration";
import { toast } from "react-toastify";
import ModalConfirmation from "../../../../components/Modal-Confirmation";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getTranslation } from "../../../../translations";

const CardIntegration = (props) => {

    const [visibleModalInfor, setVisibleModalInfor] = useState(false)
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)
    const statusDeleteIntegration = useSelector(state => state.reducerDeleteIntegration)
    const history = useHistory()
    const dispatch = useDispatch()

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);


    useEffect(() => {
        //console.log("teste => visibleModalConfirmation", visibleModalConfirmation)
    }, [visibleModalConfirmation])


    const controlIntegrationSteps = useSelector(state => state.reducerControlIntegrationSteps)

    useEffect(() => {
        //console.log("controlIntegrationSteps", controlIntegrationSteps)
    }, [controlIntegrationSteps])

    const validate = () => {
        //console.log("jSON", JSON.parse(localStorage.getItem("depoimentos@login"))?.integration)
        //console.log("props.platform", props.platform)

        if (props.platform.title == "Shopee") {
            history.push("/produtos")
            return null
        }

        if (props.platform.title == "Dropi") {

            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration) {
                //alert("Fazer integração")
                if (!props.install) {
                    if (!visibleModalInfor) {
                        localStorage.setItem("isIntegrationSteps", true);
                        setVisibleModalInfor(true)
                    }
                } else {
                    if (!visibleModalConfirmation) setVisibleModalConfirmation(true)
                    //setVisibleModalConfirmation(true)
                }

            } else if (props.install) {
                setVisibleModalConfirmation(true)
            } else {
                //alert("Por favor integrar uma plataforma primeiro")
                toast.info("Por favor integre sua plataforma primeiro.", { autoClose: false, })
            }
            //if (!props.install) setVisibleModalInfor(true)
        } else if (props.platform.title == "Rastreio") {
            alert("Rastreio")
        } else {
            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration) {
                if (props.platform.title.toLowerCase().replace(" ", "") === JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform) {
                    //console.log("teste 01")
                    if (!visibleModalConfirmation) setVisibleModalConfirmation(true)

                } else if (props.platform.title === "Loja Integrada" && JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform === "loja_integrada") {
                   // console.log("teste 02")
                    setVisibleModalConfirmation(true)
                } else {
                    //alert("Já existe uma plataforma integrada. Desinstale primeiro.")
                    toast.info("Já existe uma plataforma integrada.", { autoClose: false, })
                }
            } else {
                //dispatch({ type: "STEPS_CONTROL_INTEGRATION", payload: { isIntegrationSteps: true } })
                localStorage.setItem("isIntegrationSteps", true);
                if (!visibleModalInfor) setVisibleModalInfor(true)
            }
        }


    }

    const deleteIntegration = () => { dispatch({ type: "SAGA_DELETE_INTEGRATION", payload: { platform: props.platform.title == "Loja Integrada" ? "loja_integrada" : props.platform.title.toLowerCase().replace(" ", "") } }) }

    const getInfor = (platform) => {
        //console.log("plat => ", platform)
        switch (platform) {
            case "Dropi":
                return `Chave Emprender: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationDropi?.user_token}`
            case "Shopify":
                return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Nuvemshop":
                return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Custom":
                return `Site: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Montink":
                return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Hotmart":
                return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Land Page":
                return `Site: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Wix":
                return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            default:
                return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
        }
    }

    useEffect(() => {
        if (!statusDeleteIntegration.loading && !statusDeleteIntegration.error && statusDeleteIntegration.data.length == 0) {
        } else {
            const id = "statusDeleteIntegration"
            if (statusDeleteIntegration.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusDeleteIntegration.error) {
                    toast.update(id, { render: "Erro ao tentar excluir.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: "Excluído com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    setVisibleModalConfirmation(false)
                }
            }
        }
    }, [statusDeleteIntegration])

    return (
        <Container onClick={() => { validate() }}>
            {props.install && <InstallIntegration />}

            <ModalConfirmation
                confirmation={deleteIntegration}
                visible={props.platform.title == "Rastreio" ? false : visibleModalConfirmation}
                setVisibleModal={setVisibleModalConfirmation}
                title={`${translation.integrations.card.desejaExcluir} ${props.platform.title == "Custom" ? translation.integrations.integracaoManual : props.platform.title == "Nuvemshop" ? translation.integrations.nuvemshop : props.platform.title} ?`}
                infor={getInfor(props.platform.title == "Custom" ? "Integração Manual" : props.platform.title)}
            />
            <ModalInfor
                platform={props.platform}
                visible={props?.platform?.title == "Rastreio" ? false : visibleModalInfor}
                setVisibleModal={setVisibleModalInfor}
                item={props?.item}
            />
            {props.img == "custom" ?
                <BsTools size={25} color={"#FE852B"} /> :
                <img width={40} height={40} src={props.img} />}
            <label>{props.tooltip}</label>
        </Container>
    )
}

export default CardIntegration