import { Container, BoxLoading, BoxLine, NavInfor, SectionLeft, TitleSteps, DescriptionSteps, ContainerSteps, BoxOptions, ItemOption, StepCircle, BoxContentStep, SectionCardsIntegration, SectionContents, Title, Description, BoxCupomContent, TextHelp, Btn, TextSupport, } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import Card from "../../components/Card";
import { AiFillStar, AiFillCheckCircle, AiFillPlayCircle } from "react-icons/ai";
import { RiQuestionnaireFill, RiAddCircleFill } from "react-icons/ri";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineLoading } from "react-icons/ai";
import Script from "../../../../components/Buttons-Options";
import { isVisibleScript } from "../../../../utils/displayScript";
import { useHistory } from "react-router-dom";
import TableInfor from "../../components/TableInfor";
import TableTopInfor from "../../components/TableTopInfor";
import AlertIntegration from "../../../../components/Alert";
import Animation from "../../components/Animarion";
import Help from "../../components/Help";
import Options from "../../components/Options";
import TableTopReviews from "../../components/TableTopReviews";
import TableTopQuestions from "../../components/TableTopQuestions";
import TotalReviews from "../../components/TotalReviews";
import TotalQuestions from "../../components/TotalQuestions";

import ModalReact from "react-modal";
import AvaliacaoPopUp from "../../../../components/AvaliacaoPopUp";
import { AvaliacaoPopUpProvider } from "../../../../hooks/UseAvaliacaoPopUp";
import AnimationLoading from "../../components/Animarion-loading";
import HelpLoading from "../../components/Help-loading";
import OptionsLoading from "../../components/Options-loading";
import TotalLoading from "../../components/Total-loading";
import TableTopLoading from "../../components/TableTop-loading";
import TableEmailsSend from "../../components/TableEmailsSend";
import TableEmailsPending from "../../components/TableEmailPending";

import AlertCloseBoss from "../../../../components/alert-close-boss";
import { useState } from "react";
import CardIntegration from "../../../steps/components/card-integration";
import { platforms } from "../../../integrations/pages/home/dataCards";

import nuvemshopImg from "../../../../assets/imgs/icons-integrations/nuvemshop.png";
import dropiImg from "../../../../assets/imgs/icons-integrations/dropi.png";
import shopifyImg from "../../../../assets/imgs/icons-integrations/shopify.png";
import siteImg from "../../../../assets/imgs/site.webp";
import hotmartImg from "../../../../assets/imgs/icons-integrations/hotmart.png";
import montinkImg from "../../../../assets/imgs/icons-integrations/montink.png";
import rastreioImg from "../../../../assets/imgs/logo-rastreio.svg";
import landPageImg from "../../../../assets/imgs/icons-integrations/landpage.png";
import lojaIntegradaImg from "../../../../assets/imgs/icons-integrations/loja-integrada.png";
import cartpandaImg from "../../../../assets/imgs/icons-integrations/cartpanda.png";
import wixImg from "../../../../assets/imgs/icons-integrations/wix.png";
import logoShopeeImg from "../../../../assets/imgs/shopee-logo.png";

import CardCupom from "../../../cupons/components/card";

import ModalInforVideoIntegration from "../../../../components/modal-infor-video";
import ModalInforVideoCupom from "../../../../components/modal-infor-video";
import ModalInforVideoDropi from "../../../../components/modal-infor-video";
import ModalIntegration from "../../../integrations/components/Modal";
import ModalAddCupom from "../../../cupons/components/modal-add";
import { parse } from "date-fns";

import { getTranslation } from "../../../../translations";
import LoadingLogo from "../../../../components/Loading-logo";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const Home = () => {

    const statusDeleteCupom = useSelector(state => state.reducerDeleteCupom)
    const statusUpdateCupom = useSelector(state => state.reducerUpdateCupom)
    const statusAddCupom = useSelector(state => state.reducerAddCupom)


    const [visibleModalInforVideoIntgration, setVisibleModalInforVideoIntgration] = useState(false)
    const [visibleModalInforVideoCupom, setVisibleModalInforVideoCupom] = useState(false)
    const [visibleModalInforVideoDropi, setVisibleModalInforVideoDropi] = useState(false)
    const [visibleModalAddCupom, setVisibleModalAddCupom] = useState(false)

    const [statusNuvemshop, setStatusNuvemshop] = useState(false)
    const [statusShopify, setStatusShopify] = useState(false)
    const [statusDropi, setStatusDropi] = useState(false)
    const [statusCustom, setStatusCustom] = useState(false)
    const [statusHotmart, setStatusHotmart] = useState(false)
    const [statusMontink, setStatusMontink] = useState(false)
    const [statusRastreio, setStatusRastreio] = useState(false)
    const [statusLandPage, setStatusLandPage] = useState(false)
    const [statusLojaIntegrada, setStatusLojaIntegrada] = useState(false)
    const [statusCartpanda, setStatusCartpanda] = useState(false)
    const [statusWix, setStatusWix] = useState(false)

    const statusPlatforms = useSelector(state => state.reducerGetPlatforms)
    const cupons = useSelector(state => state.reducerCupons)

    const [controlVisibleSteps, setControlVisibleSteps] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        //console.log("teste -> ", statusPlatforms)
        if (statusPlatforms.data.length > 0) {
            for (let item of statusPlatforms.data) {
                switch (item.platform) {
                    case "nuvemshop": setStatusNuvemshop(true)
                        break;
                    case "shopify": setStatusShopify(true)
                        break;
                    case "dropi": setStatusDropi(true)
                        break;
                    case "custom": setStatusCustom(true)
                        break;
                    case "hotmart": setStatusHotmart(true)
                        break;
                    case "montink": setStatusMontink(true)
                        break;
                    case "rastreio": setStatusRastreio(true)
                        break;
                    case "landpage": setStatusLandPage(true)
                        break;
                    case "loja_integrada": setStatusLojaIntegrada(true)
                        break;
                    case "cartpanda": setStatusCartpanda(true)
                        break;
                    case "wix": setStatusWix(true)
                        break;
                    default: break;
                }
            }
        } else {
            setStatusNuvemshop(false)
            setStatusShopify(false)
            setStatusDropi(false)
            setStatusCustom(false)
            setStatusHotmart(false)
            setStatusMontink(false)
            setStatusRastreio(false)
            setStatusLandPage(false)
            setStatusLojaIntegrada(false)
            setStatusCartpanda(false)
            setStatusWix(false)
        }
    }, [statusPlatforms])

    useEffect(() => { dispatch({ type: "SAGA_GET_PLATFORMS_INTEGRATION" }) }, [])

    useEffect(() => { dispatch({ type: "SAGA_LIST_CUPONS", payload: { search: "", perPage: 20, page: 1 } }) }, [])

    useEffect(() => {
        if (!(statusDeleteCupom.loading == false && !statusDeleteCupom.error == false && statusDeleteCupom.data.length == 0)) {
            if (statusDeleteCupom.loading) {
            } else {
                if (statusDeleteCupom.error) { }
                else {
                    dispatch({ type: "SAGA_LIST_CUPONS", payload: { search: "", perPage: 20, page: 1 } })
                }
            }
        }
    }, [statusDeleteCupom])

    useEffect(() => {
        if (!(statusAddCupom.loading == false && !statusAddCupom.error == false && statusAddCupom.data.length == 0)) {
            if (statusAddCupom.loading) {
            } else {
                if (statusAddCupom.error) { }
                else {
                    dispatch({ type: "SAGA_LIST_CUPONS", payload: { search: "", perPage: 20, page: 1 } })
                }
            }
        }
    }, [statusAddCupom])

    useEffect(() => {
        if (!(statusUpdateCupom.loading == false && !statusUpdateCupom.error == false && statusUpdateCupom.data.length == 0)) {
            const id = "statusAddCupom"
            if (statusUpdateCupom.loading) {

            } else {
                if (statusUpdateCupom.error) { }
                else {
                    dispatch({ type: "SAGA_LIST_CUPONS", payload: { search: "", perPage: 20, page: 1 } })
                }
            }
        }
    }, [statusUpdateCupom])

    const dashboard = useSelector(state => state.reducerDashboard)
    const dispatch = useDispatch()
    let history = useHistory()

    const [menuActive, setmenuActive] = useState("integration")

    useEffect(() => {
        //console.log("daSH", dashboard)
    }, [dashboard])

    useEffect(() => {


        //integração wix login
        // if (localStorage.getItem("depoimentos@wix") != null) {
        //     history.push("/wix")
        // }

        if (localStorage.getItem("depoimentos@login")) {
            dispatch({ type: "SAGA_INFOR_DASHBOARD" })
        } else {
            history.push("/login")
        }

        //console.log("Teste: => ", `${process.env.REACT_APP_EPLUS_PAGINA_APPS}` + localStorage.getItem("depoimentos@affiliate") != null ? '&ref=123221' : "")

        // localStorage.getItem("depoimentos@affiliate") != null ?
        //     console.log("TESTANDO : =>", `${process.env.REACT_APP_EPLUS_PAGINA_APPS}&ref=123221`) :
        //     console.log("TESTANDO : =>", `${process.env.REACT_APP_EPLUS_PAGINA_APPS}`)
    }, [])


    const countTop = (total, data) => {
        //console.log(data?.length)
        if (total > data?.length) {
            return data?.length
        } else {
            return total
        }
    }


    const getAlert = () => {

        if (dashboard.data?.integrations?.length == 0 || dashboard.data?.cupoms?.length == 0) {
            return (
                <AlertIntegration margin={"20px 30px 0px 280px"} >
                    <ul style={{ height: "100%", margin: "0px", alignItems: "center", }}>
                        {dashboard.data?.integrations?.length == 0 && <li><label style={{ alignItems: "center", margin: "0px" }} >{translation.dashboard.naoIntegracoes}<NavInfor onClick={() => history.push("/integracoes")} >{translation.dashboard.cliqueAqui}</NavInfor>{translation.dashboard.integrarLoja}</label></li>}
                        {dashboard.data?.cupoms?.length == 0 && <li><label style={{ alignItems: "center", margin: "0px" }}>{translation.dashboard.naoCupons}<NavInfor onClick={() => history.push("/cupons")} >{translation.dashboard.cliqueAqui}</NavInfor>{translation.dashboard.criarCupom}</label></li>}
                    </ul>
                </AlertIntegration>
            )
        }
    }

    const getAlertBoss = () => {

        if (dashboard.data?.integrations?.length == 0 && dashboard.data?.cupoms?.length == 0) {
            return (
                <>
                    <AlertCloseBoss close={true} margin={"0px 0px 20px 0px"} visible={true} >
                        <label style={{ alignItems: "center", margin: "0px" }} >
                            {translation.dashboard.naoIntegracoes}<NavInfor onClick={() => history.push("/integracoes")} >{translation.dashboard.cliqueAqui}</NavInfor> {translation.dashboard.integrarLoja}
                        </label>
                        <span>
                            {/* <label style={{  display: "flex", justifyContent: "center", minWidth: "160px",cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                                Ver como funciona
                            </label> */}
                        </span>
                    </AlertCloseBoss>
                    {/* <AlertCloseBoss close={true} margin={"0px 0px 20px 0px"} visible={true} >
                        <label style={{ alignItems: "center", margin: "0px" }}>
                            {translation.dashboard.naoCupons} <NavInfor onClick={() => history.push("/cupons")} >{translation.dashboard.cliqueAqui}</NavInfor> {translation.dashboard.criarCupom}
                        </label>
                        <span>
                        </span>
                    </AlertCloseBoss > */}
                </>

            )
        } else if (dashboard.data?.integrations?.length == 0) {
            return (
                <AlertCloseBoss close={true} margin={"0px 0px 20px 0px"} visible={true} >
                    <label style={{ alignItems: "center", margin: "0px" }} >
                        {translation.dashboard.naoIntegracoes}<NavInfor onClick={() => history.push("/integracoes")} >{translation.dashboard.cliqueAqui}</NavInfor> {translation.dashboard.integrarLoja}
                    </label>
                    <span>
                        {/* <label style={{  display: "flex", justifyContent: "center", minWidth: "160px",cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                            Ver como funciona
                        </label> */}
                    </span>
                </AlertCloseBoss>
            )
        }

    }

    useEffect(() => {
        let dataCreate = new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at)
        //let dataCreate = new Date("10-15-2022")
        dataCreate.setDate(dataCreate.getDate() + 7)
        //("teste add create date", new Date(JSON.parse(localStorage.getItem("depoimentos@login")).user.created_at))
        //console.log("teste add date", dataCreate)
        //console.log("teste add hj date", new Date())
        //console.log("teste add date if", dataCreate >= new Date())
        if (new Date() > dataCreate) {
            setControlVisibleSteps(false)
        } else {
            setControlVisibleSteps(true)
        }
        //console.log("json teste ", JSON.parse(localStorage.getItem("visibleStepsDash")))
    }, [])


    useEffect(() => {

        if (localStorage.getItem("wix@instanceId") && localStorage.getItem("wix@instanceId") != null) {
            //window.location = `https://app.lily.com.br/integrations/wix?code=${localStorage.getItem("wix@code")}`
            history.push({
                pathname: '/wix',
                search: `?instance=${localStorage.getItem("wix@instanceId")}`
            })
        }

        if (localStorage.getItem("wix@code") && localStorage.getItem("wix@code") != null) {
            //window.location = `https://app.lily.com.br/integrations/wix?code=${localStorage.getItem("wix@code")}`
            history.push({
                pathname: '/wix',
                search: `?token=${localStorage.getItem("wix@code")}`
            })
        }

        if (localStorage.getItem("wix@integration") && localStorage.getItem("wix@integration") != null) {
            //window.location = `https://app.lily.com.br/integrations/wix?code=${localStorage.getItem("wix@code")}`
            history.push({
                pathname: '/integracoes/wix',
                search: `?code=${JSON.parse(localStorage.getItem("wix@integration")).code}&instanceId=${JSON.parse(localStorage.getItem("wix@integration")).instanceId}`
            })
        }

        if (localStorage.getItem("nuvemshop@code") && localStorage.getItem("nuvemshop@code") != null) {
            //window.location = `https://app.lily.com.br/integrations/wix?code=${localStorage.getItem("wix@code")}`
            //window.location = `https://www.nuvemshop.com.br/apps/${process.env.REACT_APP_NUVEMSHOP_CLIENT_ID}/authorize`
            history.push({
                pathname: '/integracoes/nuvemshop',
                //search: `?code=${parse.JSON(localStorage.getItem("nuvemshop@code").code)}&user_token=${parse.JSON(localStorage.getItem("nuvemshop@code").code}`
            })
        }
    }, [])

    return (
        <Body>
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1" }} >                <Header>
                {
                    //getAlert()
                }
            </Header>
                <AvaliacaoPopUpProvider>
                    <Contents>
                        <Container>
                            {
                                getAlertBoss()
                            }
                            {controlVisibleSteps && JSON.parse(localStorage.getItem("visibleStepsDash") != "false") ?
                                <div>
                                    <ModalIntegration />
                                    <ModalInforVideoIntegration visible={visibleModalInforVideoIntgration} setVisibleModal={setVisibleModalInforVideoIntgration} video={"https://www.youtube.com/embed/2XA-aqmmXvQ"} />
                                    <ModalInforVideoCupom visible={visibleModalInforVideoCupom} setVisibleModal={setVisibleModalInforVideoCupom} video={"https://www.youtube.com/embed/2XA-aqmmXvQ"} />
                                    <ModalInforVideoDropi visible={visibleModalInforVideoDropi} setVisibleModal={setVisibleModalInforVideoDropi} video={"https://www.youtube.com/embed/2XA-aqmmXvQ"} />
                                    <ModalAddCupom visible={visibleModalAddCupom} setVisibleModal={setVisibleModalAddCupom} />

                                    <TitleSteps>{translation.dashboard.bemVindo}</TitleSteps>
                                    <DescriptionSteps>{translation.dashboard.passoApasso}</DescriptionSteps>
                                    <ContainerSteps>
                                        <BoxOptions>
                                            <ItemOption active={menuActive == "integration" ? true : false} onClick={() => { setmenuActive("integration") }}>
                                                {statusNuvemshop || statusShopify || statusCustom || statusHotmart || statusMontink || statusRastreio || statusLandPage || statusLojaIntegrada || statusCartpanda || statusWix ?
                                                    <AiFillCheckCircle size={32} color={"#54D18C"} /> :
                                                    <StepCircle>
                                                        <label>1</label>
                                                    </StepCircle>}
                                                <label onClick={() => { setmenuActive("integration") }} style={{ marginLeft: "10px" }}>{translation.dashboard.integracoes}</label>
                                            </ItemOption>

                                            <ItemOption active={menuActive == "cupom" ? true : false} onClick={() => { setmenuActive("cupom") }}>
                                                {cupons.data.data?.length > 0 ?
                                                    <AiFillCheckCircle size={32} color={"#54D18C"} /> :
                                                    <StepCircle>
                                                        <label>2</label>
                                                    </StepCircle>}
                                                <label onClick={() => { setmenuActive("cupom") }} style={{ marginLeft: "10px" }}>{translation.dashboard.cupom}</label>
                                            </ItemOption>

                                            <ItemOption active={menuActive == "dropi" ? true : false} onClick={() => { setmenuActive("dropi") }}>
                                                {statusDropi ?
                                                    <AiFillCheckCircle size={32} color={"#54D18C"} /> :
                                                    <StepCircle>
                                                        <label>3</label>
                                                    </StepCircle>}
                                                <label onClick={() => { setmenuActive("dropi") }} style={{ marginLeft: "10px" }}>{translation.dashboard.dropi}</label>
                                            </ItemOption>

                                            <ItemOption active={menuActive == "end" ? true : false} onClick={() => { setmenuActive("end") }}>
                                                <StepCircle>
                                                    <label>4</label>
                                                </StepCircle>
                                                <label onClick={() => { setmenuActive("end") }} style={{ marginLeft: "10px" }}>{translation.dashboard.finalizar}</label>
                                            </ItemOption>

                                        </BoxOptions>
                                        <BoxContentStep>
                                            <SectionContents>
                                                {menuActive == "integration" &&
                                                    <>
                                                        <Title>{translation.dashboard.conectarLoja}</Title>
                                                        <Description>{translation.dashboard.plataformaPreferida}</Description>
                                                        <SectionCardsIntegration>
                                                            <CardIntegration install={statusShopify} platform={platforms.shopify} img={shopifyImg} tooltip={"Shopify"} />
                                                            <CardIntegration install={statusNuvemshop} platform={platforms.nuvemshop} img={nuvemshopImg} tooltip={translation.integrations.nuvemshop} />
                                                            <CardIntegration install={statusLojaIntegrada} platform={platforms.loja_integrada} img={lojaIntegradaImg} tooltip={"Loja Integrada"} />
                                                            <CardIntegration install={statusMontink} platform={platforms.montink} img={montinkImg} tooltip={"Montink"} />
                                                            <CardIntegration install={statusCartpanda} platform={platforms.cartpanda} img={cartpandaImg} tooltip={"Cartpanda"} />

                                                            <CardIntegration install={statusLandPage} platform={platforms.landpage} img={landPageImg} tooltip={"Landpage"} />
                                                            <CardIntegration install={statusHotmart} platform={platforms.hotmart} img={hotmartImg} tooltip={"Hotmart"} />
                                                            <CardIntegration install={statusCustom} platform={platforms.custom} img={"custom"} tooltip={translation.integrations.integracaoManual} />
                                                        </SectionCardsIntegration>
                                                    </>
                                                }

                                                {menuActive == "cupom" &&
                                                    <>
                                                        <Title>
                                                            {translation.dashboard.cadastrarCupom}
                                                            <span style={{ marginLeft: "10px" }}>
                                                                <RiAddCircleFill style={{ cursor: "pointer" }} onClick={() => { setVisibleModalAddCupom(true) }} color={"#54D18C"} size={25} />
                                                                <label>{translation.dashboard.desejaCadastrar}</label>
                                                            </span>
                                                        </Title>
                                                        <Description>{translation.dashboard.cadastreSelecione}</Description>
                                                        <SectionCardsIntegration>
                                                            {
                                                                cupons.data.data?.length == 0 || typeof cupons.data?.data == "undefined" ?
                                                                    <BoxCupomContent>
                                                                        <label onClick={() => { setVisibleModalAddCupom(true) }}>{translation.dashboard.cadastrarCupom}</label>
                                                                    </BoxCupomContent> :
                                                                    cupons.data.data?.map((item) => { return <CardCupom background={"#FBF9F6"} key={item.id} item={item} /> })
                                                            }

                                                        </SectionCardsIntegration>
                                                    </>
                                                }

                                                {menuActive == "dropi" &&
                                                    <>
                                                        <Title>{translation.dashboard.dicaIntegracao}</Title>
                                                        <Description>{translation.dashboard.integracaoDropi}</Description>
                                                        <SectionCardsIntegration>
                                                            <CardIntegration install={statusDropi} platform={platforms.dropi} img={dropiImg} tooltip={"Dropi"} />
                                                        </SectionCardsIntegration>
                                                    </>
                                                }

                                                {menuActive == "end" &&
                                                    <>
                                                        <Title>{translation.dashboard.finalizarPasso}</Title>
                                                        <Description>{translation.dashboard.estatisticas}</Description>
                                                        <SectionCardsIntegration>
                                                            <BoxCupomContent>
                                                                <label onClick={() => { localStorage.setItem("visibleStepsDash", false); dispatch({ type: "SAGA_INFOR_DASHBOARD" }) }}>Finalizar</label>
                                                            </BoxCupomContent>
                                                        </SectionCardsIntegration>
                                                    </>
                                                }

                                            </SectionContents>

                                            <TextHelp>{translation.dashboard.precisaAjuda}</TextHelp>
                                            <div style={{ display: "flex", gap: "20px" }}>
                                                <Btn
                                                    onClick={() => {
                                                        if (menuActive == "integration") {
                                                            setVisibleModalInforVideoIntgration(true)
                                                        } else if (menuActive == "cupom") {
                                                            setVisibleModalInforVideoCupom(true)
                                                        } else if (menuActive == "dropi") {
                                                            setVisibleModalInforVideoDropi(true)
                                                        }
                                                    }}>
                                                    <AiFillPlayCircle color={"#54D18C"} size={25} />
                                                    {translation.dashboard.assistirVideo}
                                                </Btn>
                                                <Btn onClick={() => { history.push("/ajuda") }}>
                                                    <img width={23} height={23} src="https://empreender.nyc3.cdn.digitaloceanspaces.com/landingpage/6303faca6efd6.svg%2Bxml" />
                                                    {translation.dashboard.faq}
                                                </Btn>
                                            </div>
                                            <TextSupport>
                                                {translation.dashboard.precisaAjuda}
                                                <strong onClick={() => { window.open("https://api.whatsapp.com/send?phone=558171033488") }}>
                                                    {translation.dashboard.falarSuporte}
                                                </strong>
                                            </TextSupport>
                                        </BoxContentStep>
                                    </ContainerSteps>
                                </div> :
                                dashboard.loading ?
                                    <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                        <LoadingLogo />
                                    </div> :
                                    <>
                                        <BoxLine>
                                            <Animation />
                                            <SectionLeft>
                                                <Help />
                                                <Options />
                                            </SectionLeft>
                                        </BoxLine>
                                        <BoxLine>
                                            <TotalReviews />
                                            <TotalQuestions />
                                        </BoxLine>
                                        <BoxLine>
                                            <TableEmailsSend />
                                            <TableEmailsPending />
                                        </BoxLine>
                                    </>
                                // <>
                                //     <BoxLine>
                                //         {dashboard.loading ? <AnimationLoading /> : <Animation />}

                                //         <SectionLeft>
                                //             {
                                //                 dashboard.loading ?
                                //                     <>
                                //                         <HelpLoading />
                                //                         <OptionsLoading />
                                //                     </> :
                                //                     <>
                                //                         <Help />
                                //                         <Options />
                                //                     </>

                                //             }

                                //         </SectionLeft>
                                //     </BoxLine>
                                //     <BoxLine>
                                //         {dashboard.loading ?
                                //             <>
                                //                 <TotalLoading />
                                //                 <TotalLoading />
                                //             </> :
                                //             <>
                                //                 <TotalReviews />
                                //                 <TotalQuestions />
                                //             </>}

                                //     </BoxLine>
                                //     <BoxLine>
                                //         {dashboard.loading ?
                                //             <>
                                //                 <TableTopLoading />
                                //                 <TableTopLoading />
                                //             </> :
                                //             <>
                                //                 <TableEmailsSend />
                                //                 <TableEmailsPending />
                                //             </>}
                                //     </BoxLine>
                                // </>

                            }


                        </Container>
                    </Contents>
                </AvaliacaoPopUpProvider>
            </div>
            {isVisibleScript(false)}
        </Body >
    )
}

export default Home
