import { useSelector } from "react-redux";
import { BoxInforInitial, BoxInforInitialLeft, BoxInforInitialRight, Container, InforPlus, InforPlusBtn, InforPlusText } from "./styled";
import { usePagamento } from "../../hooks/usePagamento";
import Button from "../../../../components/Button/index";
import { getTranslation } from "../../../../translations";

const Avisos = ({ avisoPlus }) => {
    const pagamento = usePagamento();
    const payments = useSelector(state => state.reducerPayments)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    if (!payments?.data?.user) {
        return (<></>);
    }
    const { user } = payments.data;

    async function regularizarAgora() {
        if (!pagamento.isLoading)
            await pagamento.regularizar();
    }

    function AdicionarCartaoRegularizar() {
        if (!pagamento.isLoading) {
            pagamento.setCaptura(false);
            pagamento.handleToggleIugu(true);
        }
    }



    if (avisoPlus) {
        return (
            <InforPlus>
                <InforPlusText>
                    <video width={300} muted autoplay={"autoplay"} loop={true}  >
                        <source src={"https://tools.empreender.com.br/empreender-plus/logo-animation.webm"} type="video/webm" />
                    </video>
                    <label>
                        Assine o <strong style={{ color: "#47fffe" }}> EMPREENDER PLUS </strong> para ter descontos nesse e em outros aplicativos!
                    </label>
                </InforPlusText>
                <InforPlusBtn>
                    <label onClick={() => { window.open(process.env.REACT_APP_EPLUS_DOMAIN + `/planos`) }}>
                        Acesse já
                    </label>
                </InforPlusBtn>
            </InforPlus >
            // <BoxInforInitial>
            //     eeaea
            //     <BoxInforInitialLeft>
            //         <label>{translation.assinatura.avisos.assinatura}</label>
            //         <label style={{ marginRight: "5px" }}>
            //             {translation.assinatura.avisos.pagamentosFeitos}
            //         </label>
            //     </BoxInforInitialLeft>
            //     <BoxInforInitialRight>
            //         <label onClick={() => { window.open(process.env.REACT_APP_EPLUS_PAGINA_ASSINATURA) }}>{translation.assinatura.avisos.irPara}</label>
            //     </BoxInforInitialRight>
            // </BoxInforInitial>


            // <Layout>
            //     <h1>Pagamentos são feitos através do Empreender Plus</h1>
            //     <p>Empreender Plus é uma central de cadastros e pagamentos da Empreender, onde você receberá descontos por utilizar nossos aplicativos.</p>
            //     <div style={{display: 'flex', flexWrap: "wrap", gap: '1rem', marginTop: '16px'}}>
            //         <p>
            //             <Button background={"#20d489"} color={"#fff"} width={"150px"}>
            //                 <a rel="noreferrer" href={process.env.REACT_APP_EPLUS_PAGINA_ASSINATURA} target="_blank" style={{color:"#fff",background:"transparent"}}>
            //                     Ir para Plus
            //                 </a>
            //             </Button>
            //         </p>
            //     </div>
            // </Layout>
        );
    }

    const dateNow = ~~(Date.now() / 1000);

    if (user['cred_expire'] < (dateNow - 3600 * 8) && !pagamento?.verificarSeEstaLiberadoParaPagar()) {
        return (
            <Layout>
                <h1>{translation.assinatura.avisos.pagamentoProcessado}</h1>
                <p>{translation.assinatura.avisos.porFavor}</p>
            </Layout>
        );
    }

    if (user['inter_pay'] == '' && user['cred_expire'] < (dateNow - 3600 * 8)) {
        return (
            <Layout>
                <h1>{translation.assinatura.avisos.cadastreCartao}</h1>
                <p></p>
                <div style={{ display: 'flex', flexWrap: "wrap", gap: '1rem', marginTop: '16px' }}>
                    <p>
                        <Button handleClick={AdicionarCartaoRegularizar} background={"#20d489"} color={"#fff"} width={"150px"}>{translation.assinatura.avisos.adicionarCartao}</Button>
                    </p>
                </div>
            </Layout>
        );
    }

    if (user['inter_pay'] !== '' && user['cred_expire'] < (dateNow - 3600 * 8)) {
        return (
            <Layout>
                <h1>{translation.assinatura.avisos.cobrancaMensalidade}</h1>
                <p>{translation.assinatura.avisos.ops}<br /> {translation.assinatura.avisos.casoProblema}</p>
                <div style={{ display: 'flex', flexWrap: "wrap", gap: "1rem", marginTop: "16px" }}>
                    <p>
                        <Button handleClick={regularizarAgora} background={"#20d489"} color={"#fff"} width={"150px"}>{translation.assinatura.avisos.regularizarAgora}</Button>
                    </p>
                    <p>
                        <Button handleClick={AdicionarCartaoRegularizar} background="#FBF9F6" color="gray" border="#d3d3d3" width={"150px"}>{translation.assinatura.avisos.trocarCartao}</Button>
                    </p>
                </div>
            </Layout>
        );
    }

    return (<></>);
}

const Layout = (props) => {
    return (
        <Container>
            <div>
                {props.children}
            </div>
        </Container>
    );
}

export default Avisos