import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Header from "../../../../components/Header";
import Contents from "../../../../components/Contents";
import { BoxContentStep, BoxCupomContent, BoxOptions, Btn, Container, Description, DescriptionSteps, ItemOption, SectionCardsIntegration, SectionContents, StepCircle, TextHelp, TextSupport, Title, TitleSteps } from './styled'
import { isVisibleScript } from "../../../../utils/displayScript";

import { AiFillCheckCircle, AiFillPlayCircle } from "react-icons/ai";
import { RiAddCircleFill } from "react-icons/ri";
import { BiEdit } from "react-icons/bi";
import { useEffect, useState } from "react";
import CardIntegration from "../../components/card-integration";
import CardCupom from "../../../cupons/components/card";

import nuvemshopImg from "../../../../assets/imgs/icons-integrations/nuvemshop.png";
import dropiImg from "../../../../assets/imgs/icons-integrations/dropi.png";
import shopifyImg from "../../../../assets/imgs/icons-integrations/shopify.png";
import siteImg from "../../../../assets/imgs/site.webp";
import hotmartImg from "../../../../assets/imgs/icons-integrations/hotmart.png";
import montinkImg from "../../../../assets/imgs/icons-integrations/montink.png";
import rastreioImg from "../../../../assets/imgs/logo-rastreio.svg";
import landPageImg from "../../../../assets/imgs/icons-integrations/landpage.png";
import lojaIntegradaImg from "../../../../assets/imgs/icons-integrations/loja-integrada.png";
import cartpandaImg from "../../../../assets/imgs/icons-integrations/cartpanda.png";
import wixImg from "../../../../assets/imgs/icons-integrations/wix.png";
import logoShopeeImg from "../../../../assets/imgs/shopee-logo.png";
import { platforms } from "../../../integrations/pages/home/dataCards";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import ModalInforVideoIntegration from "../../../../components/modal-infor-video";
import ModalInforVideoCupom from "../../../../components/modal-infor-video";
import ModalInforVideoDropi from "../../../../components/modal-infor-video";
import { useHistory } from "react-router-dom";
import ModalIntegration from "../../../integrations/components/Modal";
import ModalAddCupom from "../../../cupons/components/modal-add";
import { getTranslation } from "../../../../translations";

const Home = () => {

    const statusDeleteCupom = useSelector(state => state.reducerDeleteCupom)
    const statusUpdateCupom = useSelector(state => state.reducerUpdateCupom)
    const statusAddCupom = useSelector(state => state.reducerAddCupom)

    const [menuActive, setmenuActive] = useState("integration")

    const [visibleModalInforVideoIntgration, setVisibleModalInforVideoIntgration] = useState(false)
    const [visibleModalInforVideoCupom, setVisibleModalInforVideoCupom] = useState(false)
    const [visibleModalInforVideoDropi, setVisibleModalInforVideoDropi] = useState(false)
    const [visibleModalAddCupom, setVisibleModalAddCupom] = useState(false)

    const [statusNuvemshop, setStatusNuvemshop] = useState(false)
    const [statusShopify, setStatusShopify] = useState(false)
    const [statusDropi, setStatusDropi] = useState(false)
    const [statusCustom, setStatusCustom] = useState(false)
    const [statusHotmart, setStatusHotmart] = useState(false)
    const [statusMontink, setStatusMontink] = useState(false)
    const [statusRastreio, setStatusRastreio] = useState(false)
    const [statusLandPage, setStatusLandPage] = useState(false)
    const [statusLojaIntegrada, setStatusLojaIntegrada] = useState(false)
    const [statusCartpanda, setStatusCartpanda] = useState(false)
    const [statusWix, setStatusWix] = useState(false)

    const statusPlatforms = useSelector(state => state.reducerGetPlatforms)
    const cupons = useSelector(state => state.reducerCupons)
    const dispatch = useDispatch()
    const history = useHistory()

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        //console.log("teste -> ", statusPlatforms)
        if (statusPlatforms.data.length > 0) {
            for (let item of statusPlatforms.data) {
                switch (item.platform) {
                    case "nuvemshop": setStatusNuvemshop(true)
                        break;
                    case "shopify": setStatusShopify(true)
                        break;
                    case "dropi": setStatusDropi(true)
                        break;
                    case "custom": setStatusCustom(true)
                        break;
                    case "hotmart": setStatusHotmart(true)
                        break;
                    case "montink": setStatusMontink(true)
                        break;
                    case "rastreio": setStatusRastreio(true)
                        break;
                    case "landpage": setStatusLandPage(true)
                        break;
                    case "loja_integrada": setStatusLojaIntegrada(true)
                        break;
                    case "cartpanda": setStatusCartpanda(true)
                        break;
                    case "wix": setStatusWix(true)
                        break;
                    default: break;
                }
            }
        } else {
            setStatusNuvemshop(false)
            setStatusShopify(false)
            setStatusDropi(false)
            setStatusCustom(false)
            setStatusHotmart(false)
            setStatusMontink(false)
            setStatusRastreio(false)
            setStatusLandPage(false)
            setStatusLojaIntegrada(false)
            setStatusCartpanda(false)
            setStatusWix(false)
        }
    }, [statusPlatforms])

    useEffect(() => { dispatch({ type: "SAGA_GET_PLATFORMS_INTEGRATION" }) }, [])

    useEffect(() => { dispatch({ type: "SAGA_LIST_CUPONS", payload: { search: "", perPage: 20, page: 1 } }) }, [])

    useEffect(() => {
        if (!(statusDeleteCupom.loading == false && !statusDeleteCupom.error == false && statusDeleteCupom.data.length == 0)) {
            if (statusDeleteCupom.loading) {
            } else {
                if (statusDeleteCupom.error) { }
                else {
                    dispatch({ type: "SAGA_LIST_CUPONS", payload: { search: "", perPage: 20, page: 1 } })
                }
            }
        }
    }, [statusDeleteCupom])

    useEffect(() => {
        if (!(statusAddCupom.loading == false && !statusAddCupom.error == false && statusAddCupom.data.length == 0)) {
            if (statusAddCupom.loading) {
            } else {
                if (statusAddCupom.error) { }
                else {
                    dispatch({ type: "SAGA_LIST_CUPONS", payload: { search: "", perPage: 20, page: 1 } })
                }
            }
        }
    }, [statusAddCupom])

    useEffect(() => {
        if (!(statusUpdateCupom.loading == false && !statusUpdateCupom.error == false && statusUpdateCupom.data.length == 0)) {
            const id = "statusAddCupom"
            if (statusUpdateCupom.loading) {

            } else {
                if (statusUpdateCupom.error) { }
                else {
                    dispatch({ type: "SAGA_LIST_CUPONS", payload: { search: "", perPage: 20, page: 1 } })
                }
            }
        }
    }, [statusUpdateCupom])

    //teste

    const updateMenuAction = () => {
        if (
            !statusNuvemshop &&
            !statusShopify &&
            !statusDropi &&
            !statusCustom &&
            !statusHotmart &&
            !statusMontink &&
            !statusRastreio &&
            !statusLandPage &&
            !statusLojaIntegrada &&
            !statusCartpanda &&
            !statusWix
        ) {
            setmenuActive("integration")
        } else if (cupons.data.data?.length == 0) {
            setmenuActive("cupom")
        } else if (!statusDropi) {
            setmenuActive("dropi")
        } else {
            setmenuActive("integration")
        }
    }

    return (
        <Body>
            <ModalIntegration />
            <ModalInforVideoIntegration visible={visibleModalInforVideoIntgration} setVisibleModal={setVisibleModalInforVideoIntgration} video={"https://www.youtube.com/embed/4j6SIBG9ckM"} />
            <ModalInforVideoCupom visible={visibleModalInforVideoCupom} setVisibleModal={setVisibleModalInforVideoCupom} video={"https://www.youtube.com/embed/FOabXIwtNRM"} />
            <ModalInforVideoDropi visible={visibleModalInforVideoDropi} setVisibleModal={setVisibleModalInforVideoDropi} video={"https://www.youtube.com/embed/J81i6BfJcyA"} />
            <ModalAddCupom visible={visibleModalAddCupom} setVisibleModal={setVisibleModalAddCupom} />
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1", minWidth: '0%' }} >
                <Header />
                <Contents>
                    <TitleSteps>{translation.steps.bemVindo}</TitleSteps>
                    <DescriptionSteps>{translation.steps.passoApasso}</DescriptionSteps>
                    <Container>
                        <BoxOptions>
                            <ItemOption active={menuActive == "integration" ? true : false} onClick={() => { setmenuActive("integration") }}>
                                {statusNuvemshop || statusShopify || statusCustom || statusHotmart || statusMontink || statusRastreio || statusLandPage || statusLojaIntegrada || statusCartpanda || statusWix ?
                                    <AiFillCheckCircle size={32} color={"#54D18C"} /> :
                                    <StepCircle>
                                        <label>1</label>
                                    </StepCircle>}
                                <label onClick={() => { setmenuActive("integration") }} style={{ marginLeft: "10px" }}>{translation.steps.integracoes}</label>
                            </ItemOption>

                            <ItemOption active={menuActive == "cupom" ? true : false} onClick={() => { setmenuActive("cupom") }}>
                                {cupons.data.data?.length > 0 ?
                                    <AiFillCheckCircle size={32} color={"#54D18C"} /> :
                                    <StepCircle>
                                        <label>2</label>
                                    </StepCircle>}
                                <label onClick={() => { setmenuActive("cupom") }} style={{ marginLeft: "10px" }}>{translation.steps.cupom}</label>
                            </ItemOption>

                            <ItemOption active={menuActive == "dropi" ? true : false} onClick={() => { setmenuActive("dropi") }}>
                                {statusDropi ?
                                    <AiFillCheckCircle size={32} color={"#54D18C"} /> :
                                    <StepCircle>
                                        <label>3</label>
                                    </StepCircle>}
                                <label onClick={() => { setmenuActive("dropi") }} style={{ marginLeft: "10px" }}>Dropi</label>
                            </ItemOption>

                        </BoxOptions>
                        <BoxContentStep>

                            <SectionContents>
                                {menuActive == "integration" &&
                                    <>
                                        <Title>{translation.steps.conecteLoja}</Title>
                                        <Description>{translation.steps.escolhaPlataforma}</Description>
                                        <SectionCardsIntegration>
                                            <CardIntegration install={statusShopify} platform={platforms.shopify} img={shopifyImg} tooltip={"Shopify"} />
                                            <CardIntegration install={statusNuvemshop} platform={platforms.nuvemshop} img={nuvemshopImg} tooltip={translation.integrations.nuvemshop} />
                                            <CardIntegration install={statusLojaIntegrada} platform={platforms.loja_integrada} img={lojaIntegradaImg} tooltip={"Loja Integrada"} />
                                            <CardIntegration install={statusMontink} platform={platforms.montink} img={montinkImg} tooltip={"Montink"} />
                                            <CardIntegration install={statusCartpanda} platform={platforms.cartpanda} img={cartpandaImg} tooltip={"Cartpanda"} />
                                            {/* <CardIntegration install={statusWix} platform={platforms.wix} img={wixImg} tooltip={"Wix"} /> */}
                                            <CardIntegration install={statusLandPage} platform={platforms.landpage} img={landPageImg} tooltip={"Landpage"} />
                                            <CardIntegration install={statusHotmart} platform={platforms.hotmart} img={hotmartImg} tooltip={"Hotmart"} />
                                            <CardIntegration install={statusCustom} platform={platforms.custom} img={"custom"} tooltip={translation.integrations.integracaoManual} />
                                        </SectionCardsIntegration>
                                    </>
                                }

                                {menuActive == "cupom" &&
                                    <>
                                        <Title>
                                            {translation.steps.cadastrarCupom}
                                            <span style={{ marginLeft: "10px" }}>
                                                <RiAddCircleFill style={{ cursor: "pointer" }} onClick={() => { setVisibleModalAddCupom(true) }} color={"#54D18C"} size={25} />
                                                <label>{translation.steps.desejaCadastrar}</label>
                                            </span>
                                        </Title>
                                        <Description>{translation.steps.cupomDesconto}</Description>
                                        <SectionCardsIntegration>
                                            {/* <CardCupom key={item.id} item={item} /> */}
                                            {
                                                cupons.data.data?.length == 0 || typeof cupons.data?.data == "undefined" ?
                                                    <BoxCupomContent>
                                                        <label onClick={() => { setVisibleModalAddCupom(true) }}>{translation.steps.cadastrarCupom}</label>
                                                    </BoxCupomContent> :
                                                    cupons.data.data?.map((item) => { return <CardCupom background={"#FBF9F6"} key={item.id} item={item} /> })
                                            }

                                        </SectionCardsIntegration>
                                    </>
                                }

                                {menuActive == "dropi" &&
                                    <>
                                        <Title>{translation.steps.dicaIntegracao}</Title>
                                        <Description>{translation.steps.integracaoDropi}</Description>
                                        <SectionCardsIntegration>
                                            <CardIntegration install={statusDropi} platform={platforms.dropi} img={dropiImg} tooltip={"Dropi"} />
                                        </SectionCardsIntegration>
                                    </>
                                }

                            </SectionContents>

                            <TextHelp>{translation.steps.precisaAjuda}</TextHelp>
                            <div style={{ display: "flex", gap: "20px" }}>
                                <Btn
                                    onClick={() => {
                                        if (menuActive == "integration") {
                                            setVisibleModalInforVideoIntgration(true)
                                        } else if (menuActive == "cupom") {
                                            setVisibleModalInforVideoCupom(true)
                                        } else if (menuActive == "dropi") {
                                            setVisibleModalInforVideoDropi(true)
                                        }
                                    }}>
                                    <AiFillPlayCircle color={"#54D18C"} size={25} />
                                    {translation.steps.assistirVideo}
                                </Btn>
                                <Btn onClick={() => { history.push("/ajuda") }}>
                                    {/* <BiEdit color={"green"} size={20} /> */}
                                    <img width={23} height={23} src="https://empreender.nyc3.cdn.digitaloceanspaces.com/landingpage/6303faca6efd6.svg%2Bxml" />
                                    {translation.steps.faq}
                                </Btn>
                            </div>
                            <TextSupport>
                                {translation.steps.precisaAjuda}
                                <strong onClick={() => { window.open("https://api.whatsapp.com/send?phone=558171033488") }}>
                                    {translation.steps.falarSuporte}
                                </strong>
                            </TextSupport>
                        </BoxContentStep>

                    </Container>
                </Contents >
            </div >
            {isVisibleScript(false)}
        </Body >
    );
}

export default Home