import { ButtonCustom, ActionsSelectButton, ActionsSelect, Container, Filters, PaginateInfor, PaginateNav, Search, Infor, ListCard, BoxLoading, Paginate, FiltersOptionsClick, OptionFilter, BoxInforInitial, BoxInforInitialLeft, BoxInforInitialRight, ContainerInfor, BoxMessage, ActionsDropdown } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import { AiOutlineSearch, AiOutlineLoading, AiFillCheckSquare, AiOutlineCheckSquare } from "react-icons/ai";
import { useState } from "react";
import { useEffect } from "react";
import Card from "../../components/card";
import { useDispatch, useSelector } from "react-redux";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { HiOutlineBadgeCheck, HiBadgeCheck } from "react-icons/hi";

import Script from "../../../../components/Buttons-Options";
import { displayScript, isVisibleScript } from "../../../../utils/displayScript";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AiFillHeart, AiOutlineHeart, AiOutlineCheck, AiOutlineCheckCircle, AiFillEye, AiFillEyeInvisible, AiOutlineDelete } from "react-icons/ai";
import CardLoading from "../../components/card-loading";

import LoadingLogo from "../../../../components/Loading-logo";

import ModalInforVideo from "../../../../components/modal-infor-video";
import ModalInforVideoIntegration from "../../../../components/modal-infor-video";
import ModalInforVideoClean from "../../../../components/modal-infor-video";
import AlertCloseBoss from "../../../../components/alert-close-boss";

import { IoIosArrowDown, IoIosArrowForward, IoIosArrowUp, IoMdTrash } from "react-icons/io";
import { getTranslation } from "../../../../translations";
//import { IoMdTrash } from "react-icons/io5";

const Home = () => {

    const history = useHistory();
    const [visibleMenu, setVisibleMenu] = useState(false)
    const dispatch = useDispatch()
    const reviews = useSelector(state => state.reducerReviews)
    const ProductSearchReviews = useSelector(state => state.reducerSearchReviewProduct)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)

    const statusUpdateReview = useSelector(state => state.reducerUpdateReview)
    const statusDeleteReview = useSelector(state => state.reducerDeleteReview)
    const statusDeleteSelectReviews = useSelector(state => state.reducerDeleteSelectReviews)
    const statusUpdateSelectReviews = useSelector(state => state.reducerUpdateSelectReviews)
    const reviewsSelect = useSelector(state => state.reducerControlSelectReviews)

    const [visibleModalInforReviews, setVisibleModalInforReviews] = useState(false)
    const [visibleModalInforReviewsIntegration, setVisibleModalInforReviewsIntegration] = useState(false)
    const [visibleModalInforReviewsClean, setVisibleModalInforReviewsClean] = useState(false)

    const [statusTiktok, setStatusTiktok] = useState(false)
    const [statusVideos, setStatusVideos] = useState(false)
    const [statusImages, setStatusImages] = useState(false)
    const [statusFavorite, setStatusFavorite] = useState(false)
    const [filterMedias, setFilterMedias] = useState("")

    useEffect(() => {
        //console.log("reviewsSelect ==> ", reviewsSelect)
    }, [reviewsSelect])


    const [search, setSearch] = useState("")
    const [rating, setRating] = useState("")
    const [statusReview, setStatusReview] = useState("all")
    const [selectReviews, setSelectReviews] = useState([])
    const [checkedAll, setCheckedAll] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const closeMenu = (event) => {
        setVisibleMenu(false)
    };

    useEffect(() => {
        if (!statusUpdateReview.loading && !statusUpdateReview.error && statusUpdateReview.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_REVIEWS", payload: {
                    rating: rating,
                    status: statusReview,
                    search: search,
                    perPage: perPage,
                    page: page,

                }
            })
        }
    }, [statusUpdateReview])

    useEffect(() => {
        if (!statusDeleteReview.loading && !statusDeleteReview.error && statusDeleteReview.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_REVIEWS", payload: {
                    rating: rating,
                    status: statusReview,
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [statusDeleteReview])

    useEffect(() => {
        if (visibleMenu) {
            window.addEventListener('click', closeMenu);

            // cleanup this component
            return () => {
                window.removeEventListener('click', closeMenu);
            };
        }
    }, [visibleMenu])

    useEffect(() => {
        if (typeof search !== "undefined" || typeof rating !== "undefined" || typeof statusReview !== "undefined") {
            dispatch({
                type: "SAGA_LIST_REVIEWS", payload: {
                    rating: rating,
                    status: statusReview,
                    search: search,
                    perPage: perPage,
                    page: page,
                    log: "[]"
                }
            })
        }

    }, [statusReview, rating, page])

    // useEffect(() => {
    //     if (!reviews.loading && !reviews.error && reviews.data.length !== 0) {
    //     } else {
    //         dispatch({
    //             type: "SAGA_LIST_REVIEWS", payload: {
    //                 rating: rating,
    //                 status: statusReview,
    //                 search: search,
    //                 perPage: perPage,
    //                 page: page,
    //                 log: "[statusReview]"
    //             }
    //         })
    //     }
    // }, [statusReview])

    // useEffect(() => {
    //     // if (typeof search !== "undefined" || typeof rating !== "undefined" || typeof statusReview !== "undefined") {
    //     //     dispatch({
    //     //         type: "SAGA_LIST_REVIEWS", payload: {
    //     //             rating: rating,
    //     //             status: statusReview,
    //     //             search: search,
    //     //             perPage: perPage,
    //     //             page: page
    //     //         }
    //     //     })
    //     // }

    //     if (!reviews.loading && !reviews.error && reviews.data.length !== 0) {
    //     } else {
    //         dispatch({
    //             type: "SAGA_LIST_REVIEWS", payload: {
    //                 rating: rating,
    //                 status: statusReview,
    //                 search: search,
    //                 perPage: perPage,
    //                 page: page,
    //                 log: "[rating]"
    //             }
    //         })
    //     }
    // }, [rating])

    const goSearch = () => {
        dispatch({
            type: "SAGA_LIST_REVIEWS", payload: {
                rating: rating,
                status: statusReview,
                search: search,
                perPage: perPage,
                page: page,
                log: "[goSearch]",
                medias: filterMedias,
                favorite: statusFavorite
            }
        })
    }

    useEffect(() => {
        if (ProductSearchReviews[0] === "$") {
            setSearch(ProductSearchReviews.split("$")[1],)
            dispatch({
                type: "SAGA_LIST_REVIEWS", payload: {
                    rating: rating,
                    status: statusReview,
                    search: ProductSearchReviews.split("$")[1],
                    perPage: perPage,
                    page: page,
                }
            })
            dispatch({ type: "PRODUCT_ADD_SEARCH_REVIEWS", payload: "" })
        }

    }, [ProductSearchReviews])

    // useEffect(() => {
    //     if (!reviews.loading && !reviews.error && reviews.data.length == 0) {
    //     } else {
    //         goSearch()
    //     }
    // }, [page])

    const countItens = () => {
        //console.log("teste", reviews)
        let result = 0
        if (page == reviews?.data?.meta?.last_page) result = reviews?.data?.meta?.total
        else result = page * perPage
        return result
    }

    const messageClear = () => {
        const integration = JSON.parse(localStorage.getItem("depoimentos@login"))
        //console.log("teste: ", integration.integration)
        if (integration.integration) {
            if (integration.integration == null) {
                return <label>{translation.reviews.nenhumaAvaliacao}, <strong onClick={() => { history.push("/integracoes") }} >{translation.reviews.cliqueAqui}</strong>, {translation.reviews.integrarConta}</label>
            } else {
                return (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "ceter", justifyContent: "center" }}>
                        <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                        <label style={{ marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                            {translation.reviews.naoPossui}<strong style={{ marginLeft: "5px" }} onClick={() => { alert("Video modal") }} >{translation.reviews.cliqueAqui}</strong>,  {translation.reviews.comoFunciona}
                        </label>

                    </div>

                )
            }
        } else {
            return <label>{translation.reviews.nenhumaAvaliacao}, <strong onClick={() => { history.push("/integracoes") }} >{translation.reviews.cliqueAqui} </strong>,  {translation.reviews.integrarConta}</label>
        }

    }

    // const updateDataReviews = (checked, id) => {

    //     console.log("selectReviews inicio => ", selectReviews)

    //     if (checked == true) {
    //         console.log("updateDataReviews true", checked, id)
    //         setSelectReviews([...selectReviews, id])
    //         console.log("selectReviews true => ", selectReviews)
    //     } else if (checked == false) {
    //         console.log("updateDataReviews false", checked, id)
    //         let filter = selectReviews.filter((value) => {
    //             if (value != id) return value
    //         })
    //         setSelectReviews(filter)
    //         console.log("selectReviews false => ", selectReviews)
    //     }
    //     //console.log("selectReviews => ", selectReviews)
    // }

    const deleteAll = () => {
        //if (selectReviews.length > 0) dispatch({ type: "SAGA_DELETE_SELECT_REVIEWS", payload: { itens: selectReviews } })
        dispatch({
            type: "SAGA_DELETE_SELECT_REVIEWS",
            payload: {
                itens: reviewsSelect
            }
        })
        //dispatch({ type: "REVIEWS_CONTROL_SELECT_RESET" })
    }

    useEffect(() => {
        if (!statusUpdateSelectReviews.loading && !statusUpdateSelectReviews.error && statusUpdateSelectReviews.data.length == 0) {
        } else {
            const id = "statusUpdateSelectReviews"
            if (statusUpdateSelectReviews.loading) {
                toast.loading(translation.reviews.aguarde, { toastId: id })
            } else {
                if (statusUpdateSelectReviews.error) {
                    toast.update(id, { render: translation.reviews.erroOperacao, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.reviews.operacaoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //setVisibleModalConfirmation(false)
                    handleChecked(false)
                    goSearch()
                    setVisibleMenuActions(false)
                }
            }
        }
    }, [statusUpdateSelectReviews])

    useEffect(() => {
        if (!statusDeleteSelectReviews.loading && !statusDeleteSelectReviews.error && statusDeleteSelectReviews.data.length == 0) {
        } else {
            const id = "statusDeleteSelectReviews"
            if (statusDeleteSelectReviews.loading) {
                toast.loading(translation.reviews.aguarde, { toastId: id })
            } else {
                if (statusDeleteSelectReviews.error) {
                    toast.update(id, { render: translation.reviews.erroExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.reviews.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //setVisibleModalConfirmation(false)
                    handleChecked(false)
                    goSearch()
                    setVisibleMenuActions(false)
                }
            }
        }
    }, [statusDeleteSelectReviews])

    const favorite = (status) => {

        let arrayModify = []


        for (let iterator of reviewsSelect) {
            arrayModify.push({ id: iterator, favorite: status })
        }

        dispatch({
            type: "SAGA_UPDATE_SELECT_REVIEWS",
            payload: {
                itens: arrayModify,
                //action: action
            }
        })
    }


    const verified = (status) => {

        let arrayModify = []


        for (let iterator of reviewsSelect) {
            arrayModify.push({ id: iterator, verified: status })
        }

        dispatch({
            type: "SAGA_UPDATE_SELECT_REVIEWS",
            payload: {
                itens: arrayModify,
                //action: action
            }
        })
    }

    const updateAll = (action) => {

        let arrayModify = []

        if (action == "hidden") {
            for (let iterator of reviewsSelect) {
                //console.log("iterator: ", iterator)
                //iterator.status = "hidden"
                //iterator = {id: iterator,  status: "hidden"}
                arrayModify.push({ id: iterator, status: "hidden" })
            }
        } else {
            for (let iterator of reviewsSelect) {
                //console.log("iterator: ", iterator)
                //iterator.status = "hidden"
                //iterator = {id: iterator,  status: "approved"}
                arrayModify.push({ id: iterator, status: "approved" })
            }
        }


        dispatch({
            type: "SAGA_UPDATE_SELECT_REVIEWS",
            payload: {
                itens: arrayModify,
                //action: action
            }
        })
    }

    const handleChecked = (checked) => {
        setCheckedAll(checked)
    }

    useEffect(() => {
        //console.log("reviewsSelect: ", reviewsSelect)
    }, [reviewsSelect])

    useEffect(() => {

        goSearch()
    }, [filterMedias])

    useEffect(() => {
        //console.log("change statusImages",statusImages)
        if (statusImages == true) {
            //alert("testando")
            //console.log("change statusImages",statusImages)
            setFilterMedias(filterMedias + "image,")
            //console.log("filterMedias", filterMedias)
        } else {
            setFilterMedias(filterMedias.replace("image,", ""))
        }
    }, [statusImages])

    useEffect(() => {
        if (statusVideos == true) {
            setFilterMedias(filterMedias + "video,")
        } else {
            setFilterMedias(filterMedias.replace("video,", ""))
        }
    }, [statusVideos])

    useEffect(() => {
        if (statusTiktok == true) {
            setFilterMedias(filterMedias + "tiktok,")
        } else {
            setFilterMedias(filterMedias.replace("tiktok,", ""))
        }
    }, [statusTiktok])

    useEffect(() => {
        goSearch()
    }, [statusFavorite])

    const [visibleMenuActions, setVisibleMenuActions] = useState(false)

    useEffect(() => {

        if (visibleMenuActions) {
            window.addEventListener('mouseup', closeMenuActions);
            // cleanup this component
            return () => {
                window.removeEventListener('mouseup', closeMenuActions);
            };
        }
    }, [visibleMenuActions])

    const closeMenuActions = (event) => {
        if (visibleMenuActions) setVisibleMenuActions(false)
    };

    const teste = () => {
        //alert("teste")
        //window.addEventListener('mousedown', closeMenuActions);
        // cleanup this component
        return () => {
            window.removeEventListener('mouseup', closeMenuActions);
        };
    }

    return (
        <Body>
            <ModalInforVideoIntegration visible={visibleModalInforReviewsIntegration} setVisibleModal={setVisibleModalInforReviewsIntegration} video={"https://www.youtube.com/embed/Ve4aHQ3y1-s"} />
            <ModalInforVideo visible={visibleModalInforReviews} setVisibleModal={setVisibleModalInforReviews} video={"https://www.youtube.com/embed/AAitCE51W40"} />
            <ModalInforVideo visible={visibleModalInforReviewsClean} setVisibleModal={setVisibleModalInforReviewsClean} video={"https://www.youtube.com/embed/AAitCE51W40"} />
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1" }} >
                <Header />
                <Contents>
                    {console.log("testando", JSON.parse(localStorage.getItem("depoimentos@login"))?.integration)}
                    <Container>
                        {reviews.loading ?
                            <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                {/* {Array(6).fill(<CardLoading />)} */}
                                <LoadingLogo />
                            </div> :
                            typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == "undefined" || JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
                                <ContainerInfor ContainerInfor >
                                    <BoxMessage>
                                        <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                        <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                            {translation.reviews.nenhumaIntegracao}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { history.push("/integracoes") }} >{translation.reviews.cliqueAqui} </strong>.
                                            {/* {translation.reviews.comoFunciona} */}
                                        </label>
                                        {/* <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "10px", fontWeight: "500", fontSize: "12px" }}>
                                            Não encontrou sua integração?
                                            <strong style={{ marginLeft: "5px", cursor: "pointer" }}>Clique aqui</strong>
                                            , fale com nosso suporte.
                                        </label> */}
                                    </BoxMessage>
                                </ContainerInfor> :
                                reviews.data?.default == true && reviews.data?.data.length == 0 ?
                                    <BoxMessage>
                                        <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                        <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                            {translation.reviews.naoAvaliacao}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { setVisibleModalInforReviewsClean(true) }} >{translation.reviews.cliqueAqui}</strong>.
                                            {/* {translation.reviews.comoFunciona} */}
                                        </label>
                                    </BoxMessage> :
                                    <>
                                        <AlertCloseBoss close={true} margin={"0px 0px 20px 0px"} visible={true} >
                                            <label style={{ alignItems: "center", margin: "0px" }} >
                                                {translation.reviews.avaliacoesRetratam}
                                            </label>
                                            <span style={{}}>
                                                <label onClick={() => { setVisibleModalInforReviews(true) }} style={{ display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                                                    {translation.reviews.verComo}
                                                </label>
                                            </span>
                                        </AlertCloseBoss>
                                        <Filters>
                                            <Search >
                                                <input
                                                    type={"text"}
                                                    placeholder={translation.reviews.pesquisar}
                                                    value={search}
                                                    onChange={(e) => { setSearch(e.target.value) }} />
                                                <span onClick={() => { setPage(1); goSearch() }}>
                                                    <AiOutlineSearch size={25} color={"gray"} />
                                                </span>
                                            </Search>

                                            <select value={statusReview} onChange={(e) => { setStatusReview(e.target.value) }} >
                                                <option value="all" >{translation.reviews.todos}</option>
                                                <option value="" >{translation.reviews.aprovadosPendentes}</option>
                                                <option value="approved" >{translation.reviews.aprovados}</option>
                                                <option value="pending" >{translation.reviews.pendentes}</option>
                                                <option value="hidden" >{translation.reviews.escondidos}</option>
                                            </select>

                                            <select value={rating} onChange={(e) => { setRating(e.target.value) }}>
                                                <option value="" >{translation.reviews.todasAvaliacoes}</option>
                                                <option value="5" >5 {translation.reviews.estrelas}</option>
                                                <option value="4" >4 {translation.reviews.estrelas}</option>
                                                <option value="3" >3 {translation.reviews.estrelas}</option>
                                                <option value="2" >2 {translation.reviews.estrelas}</option>
                                                <option value="1" >1 {translation.reviews.estrelas}</option>
                                            </select>
                                        </Filters>
                                        <FiltersOptionsClick>
                                            <OptionFilter active={statusTiktok} onClick={() => { setStatusTiktok(!statusTiktok) }} >{translation.reviews.tiktok}</OptionFilter>
                                            <OptionFilter active={statusImages} onClick={() => { setStatusImages(!statusImages) }} >{translation.reviews.imagens}</OptionFilter>
                                            <OptionFilter active={statusVideos} onClick={() => { setStatusVideos(!statusVideos) }} >{translation.reviews.videos}</OptionFilter>
                                            <OptionFilter active={statusFavorite} onClick={() => { setStatusFavorite(!statusFavorite) }} >favoritos</OptionFilter>
                                        </FiltersOptionsClick>
                                        {
                                            reviews?.data?.data?.length != 0 ?
                                                <>
                                                    <ActionsSelect >
                                                        <span>
                                                            <input type="checkbox" checked={checkedAll} onChange={(e) => { handleChecked(e.target.checked) }} />
                                                        </span>
                                                        <label style={{ fontSize: "14px" }}>{translation.reviews.selecionarTodos}</label>
                                                        <ActionsDropdown visible={visibleMenuActions} onClick={() => { setVisibleMenuActions(true) }}>
                                                            <label>{translation.reviews.acoes}</label>
                                                            <IoIosArrowDown size={15} color={"gray"} />
                                                            <div>
                                                                <span onClick={() => { reviewsSelect.length > 0 ? updateAll("back") : toast.warning(translation.reviews.nenhumItem) }}>
                                                                    <AiFillCheckSquare size={15} color={"#5cb85c"} />
                                                                    <label>{translation.reviews.aprovar}</label>
                                                                </span>
                                                                <span onClick={() => { reviewsSelect.length > 0 ? favorite(true) : toast.warning(translation.reviews.nenhumItem) }}>
                                                                    <AiFillHeart size={15} color={"#F13F6C"} />
                                                                    <label>{translation.reviews.favoritar}</label>
                                                                </span>
                                                                <span onClick={() => { reviewsSelect.length > 0 ? favorite(false) : toast.warning(translation.reviews.nenhumItem) }} >
                                                                    <AiOutlineHeart size={15} color={"gray"} />
                                                                    <label>{translation.reviews.removerFavoritos}</label>
                                                                </span>
                                                                <span onClick={() => { reviewsSelect.length > 0 ? verified(true) : toast.warning(translation.reviews.nenhumItem) }}>
                                                                    <HiBadgeCheck size={15} color={"#5cb85c"} />
                                                                    <label>{translation.reviews.verificarCompra}</label>
                                                                </span>
                                                                <span onClick={() => { reviewsSelect.length > 0 ? verified(false) : toast.warning(translation.reviews.nenhumItem) }}>
                                                                    <HiOutlineBadgeCheck size={15} color={"gray"} />
                                                                    <label>{translation.reviews.removerCompraVerificada}</label>
                                                                </span>
                                                                <span onClick={() => { reviewsSelect.length > 0 ? updateAll("back") : toast.warning(translation.reviews.nenhumItem) }}>
                                                                    <AiFillEye size={15} color={"#0275d8"} />
                                                                    <label>{translation.reviews.tornarVisivel}</label>
                                                                </span>
                                                                <span onClick={() => { reviewsSelect.length > 0 ? updateAll("hidden") : toast.warning(translation.reviews.nenhumItem) }}>
                                                                    <AiFillEyeInvisible size={15} color={"#0275d8"} />
                                                                    <label>{translation.reviews.tornarInvisivel}</label>
                                                                </span>
                                                                <span onClick={() => { reviewsSelect.length > 0 ? deleteAll() : toast.warning(translation.reviews.nenhumItem) }}>
                                                                    <IoMdTrash size={15} color={"#F13F6C"} />
                                                                    <label>{translation.reviews.excluir}</label>
                                                                </span>


                                                            </div>
                                                        </ActionsDropdown>
                                                        {/* <ActionsSelectButton>
                                                            <span >
                                                                <AiOutlineHeart onClick={() => { reviewsSelect.length > 0 ? favorite(false) : toast.warning("Nenhum item foi selecionado.") }} size={20} color={"gray"} />
                                                                <label>Deseja remover das favoritas todas as avaliações selecionadas?</label>
                                                            </span>
                                                            <span>
                                                                <AiFillHeart onClick={() => { reviewsSelect.length > 0 ? favorite(true) : toast.warning("Nenhum item foi selecionado.") }} size={20} color={"#F13F6C"} />
                                                                <label>Deseja favoritar todas as avaliações selecionadas?</label>
                                                            </span>

                                                            <span>
                                                                <HiOutlineBadgeCheck onClick={() => { reviewsSelect.length > 0 ? verified(false) : toast.warning("Nenhum item foi selecionado.") }} size={20} color={"gray"} />
                                                                <label>Deseja remover compra verificada de todas as avaliações selecionadas?</label>
                                                            </span>
                                                            <span>
                                                                <HiBadgeCheck onClick={() => { reviewsSelect.length > 0 ? verified(true) : toast.warning("Nenhum item foi selecionado.") }} size={20} color={"#5cb85c"} />
                                                                <label>Deseja ativar compra verificada para todas as avaliações selecionadas?</label>
                                                            </span>
                                                            <span>
                                                                <AiFillEyeInvisible onClick={() => { reviewsSelect.length > 0 ? updateAll("hidden") : toast.warning("Nenhum item foi selecionado.") }} size={20} color={"gray"} />
                                                                <label>Deseja tornar invisível todas as avaliações selecionadas?</label>
                                                            </span>
                                                            <span>
                                                                <AiFillEye onClick={() => { reviewsSelect.length > 0 ? updateAll("back") : toast.warning("Nenhum item foi selecionado.") }} size={20} color={"gray"} />
                                                                <label>Deseja tornar visível todas as avaliações selecionadas?</label>
                                                            </span>
                                                            <span>
                                                                
                                                                <AiOutlineCheckSquare onClick={() => { reviewsSelect.length > 0 ? updateAll("back") : toast.warning("Nenhum item foi selecionado.") }} size={20} color={"gray"} />
                                                                <label>Deseja aprovar todas as avaliações selecionadas?</label>
                                                            </span>
                                                            <span>
                                                                <AiOutlineDelete onClick={() => { reviewsSelect.length > 0 ? deleteAll() : toast.warning("Nenhum item foi selecionado.") }} size={20} color={"gray"} />
                                                                <label>Deseja excluir todas as avaliações selecionadas?</label>
                                                            </span>
                                                        </ActionsSelectButton> */}
                                                    </ActionsSelect>
                                                    <ListCard>
                                                        {reviews.data.data?.map((item) => { return (<Card key={item.id} checkedAll={checkedAll} item={item} />) })}
                                                        <Paginate>
                                                            <PaginateNav>
                                                                <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                                                    <MdKeyboardArrowLeft size={35} color={reviews.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                                                </span>
                                                                <label>{reviews.data?.meta?.current_page}</label>
                                                                <span onClick={() => { if (page < reviews.data?.meta?.last_page) setPage(page + 1) }}>
                                                                    <MdKeyboardArrowRight size={35} color={reviews.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                                                </span>
                                                            </PaginateNav>
                                                            <PaginateInfor>
                                                                <label>{translation.reviews.total} {countItens()} - {reviews.data?.meta?.total}</label>
                                                            </PaginateInfor>
                                                        </Paginate>
                                                    </ListCard>

                                                </> :
                                                <label style={{ marginTop: "50px", display: "flex", flex: "1", justifyContent: "center" }}>{translation.reviews.nenhumaAvaliacao}</label>
                                        }

                                    </>


                        }

                        {/* //invertido */}
                        {/* {reviews.data?.data?.length < 0 &&
                            <Filters>
                                <Search >
                                    <input
                                        type={"text"}
                                        placeholder={"Pesquisar"}
                                        value={search}
                                        onChange={(e) => { setSearch(e.target.value) }} />
                                    <span onClick={() => { setPage(1); goSearch() }}>
                                        <AiOutlineSearch size={25} color={"gray"} />
                                    </span>
                                </Search>

                                <select value={statusReview} onChange={(e) => { setStatusReview(e.target.value) }} >
                                    <option value="all" >Todos</option>
                                    <option value="" >Aprovados e Pendentes</option>
                                    <option value="approved" >Aprovados</option>
                                    <option value="pending" >Pendentes</option>
                                    <option value="hidden" >Escondidos</option>
                                </select>

                                <select value={rating} onChange={(e) => { setRating(e.target.value) }}>
                                    <option value="" >Todas as avaliações</option>
                                    <option value="5" >5 estrelas</option>
                                    <option value="4" >4 estrelas</option>
                                    <option value="3" >3 estrelas</option>
                                    <option value="2" >2 estrelas</option>
                                    <option value="1" >1 estrelas</option>
                                </select>
                                 <button onClick={() => { setPage(1); goSearch() }}>Filtrar</button> */}
                        {/* </Filters> */}




                        {/* //invertido */}
                        {/* {reviews.data?.data?.length < 0 &&
                            <FiltersOptionsClick>
                                <OptionFilter active={statusTiktok} onClick={() => { setStatusTiktok(!statusTiktok) }} >Tiktok</OptionFilter>
                                <OptionFilter active={statusImages} onClick={() => { setStatusImages(!statusImages) }} >Imagens</OptionFilter>
                                <OptionFilter active={statusVideos} onClick={() => { setStatusVideos(!statusVideos) }} >Vídeos</OptionFilter>
                            </FiltersOptionsClick>
                        } */}



                        {/* {
                            reviews.loading ?
                                <BoxLoading >
                                    <span>
                                        <AiOutlineLoading size={30} color={"#20d489"} />
                                    </span>
                                </BoxLoading> :
                                <> */}

                        {/* //invertido */}
                        {/* {reviews.data?.data?.length < 0 &&
                            <ActionsSelect >
                                <span>
                                    <input type="checkbox" checked={checkedAll} onChange={(e) => { handleChecked(e.target.checked) }} />
                                </span>
                                <label style={{ fontSize: "14px" }}>Selecionar todos</label>
                                <ActionsSelectButton>
                                    <span>
                                        <AiOutlineCheckCircle onClick={() => { reviewsSelect.length > 0 ? updateAll("back") : toast.warning("Nenhum item foi selecionado.") }} size={20} color={"gray"} />
                                        <label>Deseja aprovar todas as avaliações selecionadas?</label>
                                    </span>
                                    <span>
                                        <AiFillEyeInvisible onClick={() => { reviewsSelect.length > 0 ? updateAll("hidden") : toast.warning("Nenhum item foi selecionado.") }} size={20} color={"gray"} />
                                        <label>Deseja tornar invisível todas as avaliações selecionadas?</label>
                                    </span>
                                    <span>
                                        <AiFillEye onClick={() => { reviewsSelect.length > 0 ? updateAll("back") : toast.warning("Nenhum item foi selecionado.") }} size={20} color={"gray"} />
                                        <label>Deseja tornar visível todas as avaliações selecionadas?</label>
                                    </span>
                                    <span>
                                        <AiOutlineDelete onClick={() => { reviewsSelect.length > 0 ? deleteAll() : toast.warning("Nenhum item foi selecionado.") }} size={20} color={"gray"} />
                                        <label>Deseja excluir todas as avaliações selecionadas?</label>
                                    </span> */}




                        {/* <ButtonCustom background={"#19B674"} color={"white"} onClick={() => { reviewsSelect.length > 0 ? updateAll("approved") : toast.warning("Nenhum item foi selecionado.") }} type="button" >
                                                    Aprovar
                                                    <AiOutlineCheck size={16} color={"white"} />
                                                </ButtonCustom>

                                                <ButtonCustom background={"#0098DA"} color={"white"} onClick={() => { reviewsSelect.length > 0 ? updateAll("hidden") : toast.warning("Nenhum item foi selecionado.") }} type="button" >
                                                    Esconder
                                                    <AiFillEyeInvisible size={16} color={"white"} />
                                                </ButtonCustom>

                                                <ButtonCustom background={"#F13F6C"} color={"white"} onClick={() => { reviewsSelect.length > 0 ? deleteAll() : toast.warning("Nenhum item foi selecionado.") }} type="button" >
                                                    Excluir
                                                    <AiOutlineDelete size={16} color={"white"} />
                                                </ButtonCustom> */}

                        {/* </ActionsSelectButton>
                            </ActionsSelect>} */}

                        {/* <Infor style={{ display: reviews.data.data?.length >= 0 ? "flex" : "none" }}>
                            {reviews.data.data?.length >= 0 && messageClear()}
                        </Infor> */}
                        {/* <ListCard>
                            {
                                reviews.loading ?
                                    <>
                                        <div style={{ marginBottom: "20px" }} />
                                        {Array(6).fill(<CardLoading />)}
                                    </>
                                    :
                                    reviews.data.data?.map((item) => {
                                        return (<>
                                            <Card key={item.id} checkedAll={checkedAll} item={item} />
                                            
                                        </>)
                                    })}

                            {reviews.loading == false && reviews.data.data?.length != 0 &&
                                <Paginate>
                                    <PaginateNav>
                                        <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                            <MdKeyboardArrowLeft size={35} color={reviews.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                        </span>
                                        <label>{reviews.data?.meta?.current_page}</label>
                                        <span onClick={() => { if (page < reviews.data?.meta?.last_page) setPage(page + 1) }}>
                                            <MdKeyboardArrowRight size={35} color={reviews.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                        </span>
                                    </PaginateNav>
                                    <PaginateInfor>
                                        <label>Total {countItens()} - {reviews.data?.meta?.total}</label>
                                    </PaginateInfor>
                                </Paginate>}
                        </ListCard> */}
                        {/* </>
                        } */}
                    </Container>
                </Contents>
            </div>
            {isVisibleScript(false)}
        </Body >
    )
}

export default Home
