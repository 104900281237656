import FormaPagamentoInfo from '../FormaPagamentoInfo';
import { Container } from './styled'
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from '../../../../components/Button/index';
import { usePagamento } from '../../hooks/usePagamento';
import { getTranslation } from '../../../../translations';

const FormaPagamento = () => {
    const pagamento = usePagamento();
    const { data: { dataCard } } = useSelector(state => state.reducerPayments)
    const [image, setImage] = useState(null)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        if (dataCard?.bandeira) {
            const fetchImage = async () => {
                try {
                    const response = await import(`../../../../assets/imgs/bandeiras/${dataCard?.bandeira.toLowerCase()}.png`)
                    setImage(response.default)
                } catch (err) {
                    //console.log('err image', err);
                }
            }

            fetchImage()
        }
    }, [dataCard?.bandeira])

    return (
        <>
            <Container>
                <div>
                    <h3>{translation.assinatura.formaPagamento.formaPagamento}</h3>
                    <p style={{ margin: '16px 0' }}>{translation.assinatura.formaPagamento.seuCartao}</p>
                    {dataCard?.numero ?

                        <p style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={image} alt={dataCard.bandeira} />
                            {dataCard.numero.replace(/[\-X]/g, '*')}
                        </p>

                        :
                        <p style={{ fontWeight: "500", fontSize: "1rem" }}>{translation.assinatura.formaPagamento.nenhumaForma} </p>
                    }

                </div>

                <Button handleClick={() => {
                    if (!pagamento.isLoading) {
                        pagamento.setCaptura(true);
                        pagamento.handleToggleIugu(true);
                    }
                }} width="143px" background="#FBF9F6" color="gray" border="#d3d3d3">{translation.assinatura.formaPagamento.alterarCartao}</Button>
            </Container>
            <FormaPagamentoInfo />
        </>
    );
}

export default FormaPagamento