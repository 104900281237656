import { Box, BoxCheck, Tooltip, BoxImgVideo, Container, BoxContents, BoxContact, BoxOptions, BoxContentsLeft, SubTitle, Status, Img, ButtonApproved, ButtonHidden, ButtonDelete, BoxActions, BoxMedias, ButtonCustom, Badget, Badget2, BoxBadget } from "./styled"
import { AiOutlineInfoCircle, AiFillHeart, AiOutlineHeart, AiFillPlayCircle, AiOutlineQuestionCircle, AiOutlineMail, AiOutlineCheckCircle, AiFillCloseCircle, AiFillEdit, AiFillEye, AiFillStar, AiFillClockCircle, AiOutlineCheck, AiFillEyeInvisible, AiOutlineQuestion, AiOutlineDelete } from "react-icons/ai";
import { interval } from "../../../../utils/date";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ModalDelete from "../modal-delete";
import { useState } from "react";
import { FaInfo } from "react-icons/fa";
import { IoLogoTiktok, } from "react-icons/io5";
import { IoMdTrash } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineNewReleases } from "react-icons/md";

import Confirmation from "../../../../components/Modal-Confirmation";
import ModalReviews from "../../components/modal-reviews";
import { ZiggeoPlayer } from 'react-ziggeo'
import { BiPlay } from "react-icons/bi";
import { FiCheckCircle } from "react-icons/fi";
import { HiOutlineBadgeCheck, HiBadgeCheck } from "react-icons/hi";
import ImgDropi from "../../../../assets/imgs/icons-integrations/icon-dropi.png";
import { getTranslation } from "../../../../translations";

const Card = (props) => {

    //console.log("Card props", props)

    const dispatch = useDispatch()
    const statusUpdateReview = useSelector(state => state.reducerUpdateReview)
    const statusDeleteReview = useSelector(state => state.reducerDeleteReview)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)
    const [selectCard, setSelectCard] = useState(false)

    const [visibleModalReviews, setVisibleModalReviews] = useState(false)

    const [imgVideo, setImgVideo] = useState("")
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const colorBorder = (status) => {
        switch (status) {
            case "pending":
                return "#f0ad4e"
            case "approved":
                return "#20D489" //#5cb85c 
            case "hidden":
                return "#0275d8"
            case "new":
                return "#F13F6C"
            default:
                return "gray"
        }
    }

    const getIconStatus = (status) => {
        switch (status) {
            case "pending":
                return <AiOutlineQuestion size={15} color={"white"} />
            case "approved":
                return <AiOutlineCheck size={15} color={"white"} />
            case "hidden":
                return <AiFillEyeInvisible size={15} color={"white"} />
            case "new":
                return <FaInfo size={15} color={"white"} />
            default:
                return ""
        }
    }

    const getTextStatus = (status) => {
        switch (status) {
            case "pending":
                return translation.reviews.card.avaliacaoPendente
            case "approved":
                return translation.reviews.card.avaliacaoAprovada
            case "hidden":
                return translation.reviews.card.avaliacaoEscondida
            case "new":
                return translation.reviews.card.avaliacaoNova
            default:
                return ""
        }
    }

    useEffect(() => {
        if (!(statusUpdateReview.loading == false && !statusUpdateReview.error == false && statusUpdateReview.data.length == 0)) {
            const id = "statusUpdateReview"
            if (statusUpdateReview.loading) {
                toast.loading(translation.reviews.card.aguarde, { toastId: id })
            } else {
                if (statusUpdateReview.error) toast.update(id, { render: translation.reviews.card.erroEnviar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else toast.update(id, { render: translation.reviews.card.enviadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                //dispatch({ type: "PRODUCTS_CONTROL_UPDATE_LIST_REVIEWS", payload: { status: true } })
            }
        }
    }, [statusUpdateReview])

    useEffect(() => {
        if (!(statusDeleteReview.loading == false && !statusDeleteReview.error == false && statusDeleteReview.data.length == 0)) {
            const id = "statusDeleteReview"
            if (statusDeleteReview.loading) {
                toast.loading(translation.reviews.card.aguarde, { toastId: id })
            } else {
                if (statusDeleteReview.error) toast.update(id, { render: translation.reviews.card.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else toast.update(id, { render: translation.reviews.card.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
            }
        }
    }, [statusDeleteReview])


    const updateReview = (status, id) => {

        dispatch({
            type: "SAGA_UPDATE_REVIEWS",
            payload: {
                status: status,
                id: id
            }
        })
    }

    const favoriteReview = (status, id) => {

        dispatch({
            type: "SAGA_UPDATE_REVIEWS",
            payload: {
                favorite: status,
                id: id
            }
        })
    }

    const verifiedReview = (status, id) => {

        dispatch({
            type: "SAGA_UPDATE_REVIEWS",
            payload: {
                verified: status,
                id: id
            }
        })
    }

    // const deleteReview = (id) => {
    //     dispatch({
    //         type: "SAGA_DELETE_REVIEWS",
    //         payload: {
    //              id: id
    //         }
    //     })
    // }

    const whatsappCustom = (text) => {
        //(99) 9.9999-9999 e (99) 9999-9999
        let result = text.length == 11 ? `(${text.slice(0, 2)}) ${text.slice(2, 3)}.${text.slice(3, 7)}-${text.slice(7, 11)}` : `(${text.slice(0, 2)}) ${text.slice(2, 6)}-${text.slice(6, 10)}`
        return result
    }


    const deleteReview = () => {
        //console.log("Delete")
        dispatch({
            type: "SAGA_DELETE_REVIEWS",
            payload: {
                id: props.item.id
            }
        })
    }

    const getData = (data) => {

        if (data.type == "video") {
            return (
                <BoxImgVideo>
                    <Img src={imgVideo} />
                    {/* <AiFillPlayCircle size={50} /> */}
                    <div style={{ display: "none" }}>
                        <ZiggeoPlayer
                            apiKey={process.env.REACT_APP_ZIGGEO_APP_TOKEN}
                            video={data.url}
                            theme={'modern'}
                            themecolor={'yellow'}
                            skipinitial={false}
                            onPlaying={handlePlaying}
                            onPaused={handlePaused}
                            onLoaded={handleLoaded}
                            onRef={ref => (setPlayer(ref))}
                            width={200}
                            height={200}
                        />
                    </div>
                </BoxImgVideo>


            )
        } else if (data.type == "image") {
            return <Img src={data.url} />
        }
    }

    const [player, setPlayer] = useState(null);
    useEffect(() => {
        if (player) {
            // DO stuff here
            player.on("attached", function (embedding) {
            }, player);
        }
    }, [player]);

    const handlePlaying = (embedding) => {

    };

    const handlePaused = (embedding) => {

    };

    const handleLoaded = (embedding) => {
        setImgVideo(embedding.thumbnailurl)
    };

    const handleStatusCard = (checked) => {
        if (checked) {
            dispatch({
                type: "REVIEWS_CONTROL_SELECT_SET",
                payload: { id: props.item.id }
            })
        } else {
            dispatch({
                type: "REVIEWS_CONTROL_SELECT_REMOVE",
                payload: { id: props.item.id }
            })
        }
        setSelectCard(checked)
    }

    useEffect(() => { handleStatusCard(props.checkedAll) }, [props.checkedAll])


    const getMedias = () => {

        return (
            <BoxMedias style={{ marginTop: props.item?.medias?.length > 0 ? "15px" : "0px" }}>
                {props.item.medias.map((item) => {
                    return (
                        item.type == "video" ?
                            <div onClick={() => { setVisibleModalReviews(true) }} src={item.url} style={{ width: "50px", height: "50px", display: "flex", position: "relative" }}>
                                <Img src={item.thumbnail_url} />
                                <BiPlay size={20} color={"gray"} style={{position: "absolute"}} />
                            </div> :
                            item.type == "image" ?
                                <Img onClick={() => { setVisibleModalReviews(true) }} src={item.url} /> :
                                <IoLogoTiktok onClick={() => { setVisibleModalReviews(true) }} />
                    )
                })}
            </BoxMedias>
        )

        // return (
        //     <BoxMedias style={{ marginTop: props.item?.medias?.length > 0 ? "15px" : "0px" }}>
        //         {props.item.medias.map((item) => (
        //             item.type == "video" ?
        //                 <BoxImgVideo onClick={() => { setVisibleModalReviews(true) }}>
        //                     {imgVideo == "" ?
        //                         <div style={{ width: "50px", height: "50px" }} /> :
        //                         <Img src={imgVideo} />}

        //                     <div style={{ display: "none" }}>
        //                         <ZiggeoPlayer
        //                             apiKey={process.env.REACT_APP_ZIGGEO_APP_TOKEN}
        //                             video={item.url}
        //                             theme={'modern'}
        //                             themecolor={'yellow'}
        //                             skipinitial={false}
        //                             onPlaying={handlePlaying}
        //                             onPaused={handlePaused}
        //                             onLoaded={handleLoaded}
        //                             onRef={ref => (setPlayer(ref))}
        //                             width={200}
        //                             height={200}
        //                         />
        //                     </div>
        //                     <span>
        //                         <BiPlay size={20} color={"gray"} />
        //                     </span>
        //                 </BoxImgVideo> :
        //                 item.type == "image" ?
        //                     <Img onClick={() => { setVisibleModalReviews(true) }} src={item.url} /> :
        //                     <IoLogoTiktok onClick={() => { setVisibleModalReviews(true) }} />
        //         ))}
        //     </BoxMedias>

    }

    const copyMail = () => {
        navigator.clipboard.writeText(props.item.email)
        toast.success(translation.reviews.card.emailCopiado)
    }

    const getBadget = () => {
        switch (props.item.status) {
            case "approved":
                return (
                    <BoxBadget>
                        {props?.item?.dropi_id != undefined &&
                            <Badget color={{ background: "#E4FFF4", color: "#21D487" }}>
                                <img width={14} height={14} src={ImgDropi} />
                                <label style={{ color: "#0BACFF" }}>Dropi</label>
                            </Badget>}

                        <Badget color={{ background: "#E4FFF4", color: "#21D487" }}>
                            <FiCheckCircle size={15} color={"#21D487"} />
                            <label>{translation.reviews.card.aprovado}</label>
                        </Badget>
                    </BoxBadget>

                )
            case "pending":
                return (
                    <BoxBadget>
                        {props?.item?.dropi_id != undefined &&
                            <Badget color={{ background: "#E4FFF4", color: "#21D487" }}>
                                <img width={14} height={14} src={ImgDropi} />
                                <label style={{ color: "#0BACFF" }}>Dropi</label>
                            </Badget>}

                        <Badget color={{ background: "#f6f17b55", color: "#f0ad4e" }}>
                            <AiOutlineQuestionCircle size={15} color={"#f0ad4e"} />
                            <label>{translation.reviews.card.pendente}</label>
                        </Badget>
                    </BoxBadget>

                )
            case "hidden":
                return (
                    <BoxBadget>
                        {props?.item?.dropi_id != undefined &&
                            <Badget color={{ background: "#E4FFF4", color: "#21D487" }}>
                                <img width={14} height={14} src={ImgDropi} />
                                <label style={{ color: "#0BACFF" }}>Dropi</label>
                            </Badget>}

                        <Badget color={{ background: "#DDF1FA", color: "#0275d8" }}>
                            <AiFillEyeInvisible size={15} color={"#0275d8"} />
                            <label> {translation.reviews.card.escondido} </label>
                        </Badget>
                    </BoxBadget>
                )
            case "new":
                return (
                    <BoxBadget>
                        {props?.item?.dropi_id != undefined &&
                            <Badget color={{ background: "#E4FFF4", color: "#21D487" }}>
                                <img width={14} height={14} src={ImgDropi} />
                                <label style={{ color: "#0BACFF" }}>Dropi</label>
                            </Badget>}

                        <Badget color={{ background: "#f2b8a055", color: "#F13F6C" }}>
                            <AiOutlineInfoCircle size={15} color={"#F13F6C"} />
                            <label>{translation.reviews.card.novo}</label>
                        </Badget>
                    </BoxBadget>
                )
            default:
                return null;
        }
    }

    return (
        <Container>
            {/* <ModalDelete visible={visibleModalDelete} setVisibleModal={setVisibleModalDelete} item={props.item} /> */}
            <ModalReviews data={props.item.medias} visibleModal={visibleModalReviews} setVisibleModal={setVisibleModalReviews} />

            <Confirmation
                confirmation={deleteReview}
                visible={visibleModalConfirmation}
                setVisibleModal={setVisibleModalConfirmation}
                title={translation.reviews.card.desejaExcluir}
                infor={translation.reviews.card.obs} />

            {/* <Status color={colorBorder(props.item.status)}>
                {getIconStatus(props.item.status)}
                <Tooltip>
                    <label>{getTextStatus(props.item.status)}</label>
                </Tooltip>
            </Status> */}
            {/* <Badget color={getColorBadget()}>
                <FiCheckCircle size={15} color={getColorBadget().color} />
                <label>Aprovado</label>
            </Badget> */}
            {getBadget()}

            {
                props?.noVisibleCheck ?
                    <></> :
                    <BoxCheck >
                        <input type="checkbox" checked={selectCard} onChange={(e) => { handleStatusCard(e.target.checked) }} />
                    </BoxCheck>
            }

            <Box>
                <BoxContents color={colorBorder(props.item.status)}>
                    <BoxContentsLeft>
                        <SubTitle >
                            <span>
                                <AiFillStar size={15} color={"orange"} />
                                <AiFillStar size={15} color={props.item.rating > 1 ? "orange" : "gray"} />
                                <AiFillStar size={15} color={props.item.rating > 2 ? "orange" : "gray"} />
                                <AiFillStar size={15} color={props.item.rating > 3 ? "orange" : "gray"} />
                                <AiFillStar size={15} color={props.item.rating > 4 ? "orange" : "gray"} />
                            </span>
                            <AiFillClockCircle size={15} color={"gray"} />
                            <label>
                                {interval(props.item.created_at)} {translation.reviews.card.atras}
                                {/* <Badget2>
                                    <FiCheckCircle size={15} color={"#21D487"} />
                                    <label>Aprovado</label>
                                </Badget2> */}
                            </label>

                        </SubTitle>

                        <p>{props.item.name} {translation.reviews.card.sobre} <a target="_blank" href={props.item.canonical_url}>{props.item.product_name}</a></p>

                        <label>
                            {props.item.message}
                            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam sed metus velit. Pellentesque eu facilisis lectus, sed congue velit. Etiam lobortis gravida enim, in condimentum turpis fringilla et. Curabitur commodo felis sodales risus suscipit gravida. Nullam malesuada dapibus ex vel dapibus. Nullam vehicula egestas lorem nec facilisis. */}
                        </label>
                        {/* <BoxContact>
                            <label>E-mail: {props.item.email} </label>
                            <label>Whatsapp: <a target="_blank" href={`https://wa.me/+55${props.item.whatsapp}`}>{whatsappCustom(props.item.whatsapp)}</a></label>
                        </BoxContact> */}
                        {getMedias()}
                        {props.item.status == "pending" || props.item.status == "new" ?
                            <ButtonCustom onClick={() => { updateReview("approved", props.item.id) }} >
                                <label>{translation.reviews.card.aprovar}</label>
                            </ButtonCustom> : null}
                    </BoxContentsLeft>
                    <BoxActions>
                        <span>
                            {
                                props.item.favorite == 1 ?
                                    <AiFillHeart size={20} color={"#F13F6C"} onClick={() => { favoriteReview(false, props.item.id) }} /> :
                                    <AiOutlineHeart size={20} color={"gray"} onClick={() => { favoriteReview(true, props.item.id) }} />
                            }
                            {
                                props.item.favorite == 1 ?
                                    <label>{translation.reviews.card.desejaRemoverFavorita}</label> :
                                    <label>{translation.reviews.card.desejaFavoritar}</label>
                            }

                        </span>

                        <span>
                            {props.item.verified == 1 ?
                                <HiBadgeCheck size={20} color={"#5cb85c"} onClick={() => { verifiedReview(false, props.item.id) }} /> :
                                <HiOutlineBadgeCheck size={20} color={"gray"} onClick={() => { verifiedReview(true, props.item.id) }} />}
                            {props.item.verified == 1 ?
                                <label>{translation.reviews.card.desejaRemoverVerificada}</label> :
                                <label>{translation.reviews.card.desejaAtivar}</label>}

                        </span>

                        <span onClick={() => { props?.item?.dropi_id == undefined ? copyMail() : console.log("teste") }}>
                            <AiOutlineMail size={20} color={props?.item?.dropi_id == undefined ? "#f0ad4e" : "gray"} />
                            {props.item.dropi_id == undefined ?
                                <label>{`${translation.reviews.card.desejaCopiar} ${props?.item?.email}`}</label> :
                                <label>{translation.reviews.card.avaliacoesDropi}</label>}
                        </span>

                        <span>
                            {props.item.status == "hidden" || props.item.status == "pending" ?
                                <AiFillEyeInvisible onClick={() => { updateReview("approved", props.item.id) }} size={20} color={"#0275d8"} /> :
                                <AiFillEye onClick={() => { updateReview("hidden", props.item.id) }} size={20} color={"#0275d8"} />}
                            <label>
                                {props.item.status == "hidden" || props.item.status == "pending" ?
                                    translation.reviews.card.tornarVisivel :
                                    translation.reviews.card.tornarInvisivel}
                            </label>
                        </span>
                        <span>
                            <FaWhatsapp size={20} color={props.item?.whatsapp?.length > 0 && props?.item?.dropi_id == undefined ? "#5cb85c" : "gray"} onClick={() => { props.item.whatsapp.length > 0 && props?.item?.dropi_id == undefined && window.open(`https://wa.me/+${props.item.whatsapp}`) }} />
                            {props.item.whatsapp.length > 0 && props.item.dropi_id == undefined ?
                                <label>{`${translation.reviews.card.whatsappCliente} +${props.item.whatsapp}`}</label> :
                                <label>{translation.reviews.card.avaliacoesImportadas}</label>}

                        </span>
                        <span>
                            <IoMdTrash size={20} color={props?.item?.dropi_id == undefined ? "#F13F6C" : "gray"} onClick={() => { props?.item?.dropi_id == undefined && setVisibleModalConfirmation(true) }} />
                            {props.item.dropi_id == undefined ?
                                <label>{translation.reviews.card.desejaExcluir}</label> :
                                <label>{translation.reviews.card.exclusaoDropi}</label>}

                        </span>
                    </BoxActions>
                    {/* <div onClick={() => { setVisibleModalReviews(true) }}>
                        {
                            props.item.medias?.length > 0 && getData(props.item.medias[0])
                        }

                    </div> */}
                </BoxContents>
                {/* <BoxOptions>
                    <ButtonApproved onClick={() => { updateReview("approved", props.item.id) }}
                        active={props.item.status == "approved" ? false : true}
                        disabled={props.item.status == "approved" ? true : false}
                    >
                        {props.item.status == "approved" ? "Aprovado" : "Aprovar"}
                        <span>
                            <AiOutlineCheck size={15} color={props.item.status == "approved" ? "#19B674" : "white"} />
                        </span>
                    </ButtonApproved>
                    <ButtonHidden onClick={() => { updateReview("hidden", props.item.id) }}
                        active={props.item.status == "hidden" ? false : true}
                        disabled={props.item.status == "hidden" ? true : false}
                    >
                        {props.item.status == "hidden" ? "Escondido" : "Esconder"}
                        <span>
                            <AiFillEyeInvisible size={15} color={props.item.status == "hidden" ? "#0098DA" : "white"} />
                        </span>
                    </ButtonHidden>
                    <ButtonDelete onClick={() => {
                        //setVisibleModalDelete(true)
                        setVisibleModalConfirmation(true)
                    }}
                    >
                        Excluir
                        <span>
                            <AiOutlineDelete size={15} color={"white"} />
                        </span>
                </BoxOptions> */}
            </Box>


        </Container >
    )
}

export default Card
