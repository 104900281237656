import React, { useEffect, useState } from "react";
import { BadgeCheck, BoxInfor, BoxMedia, BoxText, CardInfo, CardPlay, CardStar, Container, TitleName, } from "./styled";
import { AiFillStar } from "react-icons/ai";
import { useSelector } from "react-redux";
//import { singleReview } from "utils/actionsPostMessage";
//import { configTeste } from "../../../../utils/data";
import { getVideoTiktok } from "./functionsTiktok";
import { FaPlay } from "react-icons/fa";
import { FiCameraOff } from "react-icons/fi";
import ImgCard from "../../../../assets/imgs/icon-bolsa.svg";
import { HiBadgeCheck } from "react-icons/hi";
import { getTranslation } from "../../../../translations";

const CardTiktok = (props) => {

  // const configTeste = {
  //   layout: "lily",
  //   primary_color: "#000000", //black
  //   font_color_primary: "#000000", //black
  //   font_color_secondary: "#FFFFFF", //white
  //   background_color_primary: "#FFFFFF", //white
  //   background_color_secondary: "#F1F1F1", //"#f1f1f1"
  //   star_color_primary: "DAA520", //orange #FFD700
  //   star_color_secondary: "#D3D3D3", //gray
  //   font: "Poppins" //Poppins
  // }

  // const config = useSelector((state) => state.SliceConfig);

  const [icon, setIcon] = useState(
    {
      size: 20,
      colorActive: "orange",
      colorInative: "gray",
    }
  )
  const config = useSelector(state => state.reducerControlConfig)

  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

  useEffect(() => {
    //console.log("TEste tema:", config)
    setIcon({ ...icon, colorActive: config.starColorPrimary, colorInative: config.starColorSecondary })
  }, [config])

  // const icon = {
  //   size: 20,
  //   colorActive: "orange",
  //   colorInative: "gray",
  // };
  const [isClicked, setIsClicked] = useState(false);
  const [ziggeoApi, setZiggeoApi] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  // useEffect(() => {
  //   if (ziggeoApp) {
  //     setZiggeoApi(ziggeoApp);
  //     console.log('app', ziggeoApi);
  //   }
  // })
  const getThumbnail = (token) => {
    let response = ziggeoApi.videos.get(token);

    response.success(function (data) {
      //console.log('thumb_imagem', data.original_stream.embed_image_url)
      setThumbnail(data.original_stream.embed_image_url);
    })
  }
  const getMedia = (media, type) => {
    if (media?.type == "tiktok") {
      //console.log("TIKTOK : ", media.url)
    }

    switch (type) {
      case "image":
        return (
          <CardPlay id='abrir'>
            <img src={ImgCard} id='abrir' />
          </CardPlay>
        )
      case "video":
        return (
          ziggeoApi && getThumbnail(media?.url),
          <CardPlay id='abrir'>
            {thumbnail ?
              <img src={thumbnail} id='abrir' />
              :
              <FaPlay color="gray" size={35} id='abrir' />
            }
          </CardPlay>
        )
      case "tiktok":
        return (
          <CardPlay>
            {/* <FaPlay color="gray" size={35} /> */}
            {/* {getVideoTiktok(7114045612609260806)} */}
            <iframe
              src={`https://tiktok.com/embed/7114045612609260806`}
              style={{ width: '-webkit-fill-available', height: '-webkit-fill-available', margin: '0px auto', borderRadius: '0px', }}
              allowfullscreen
              scrolling="no"
              allow="encrypted-media;"
            />
          </CardPlay>
        )
      default:
        return (
          <CardPlay id='abrir'>
            <FiCameraOff size={50} color={"gray"} id='abrir' />
          </CardPlay>
        )
    }
  }


  return (
    <Container
      key={props.review?.id}
      //onClick={(e) => e.target.id && props.review?.media[0]?.type != 'tiktok' && singleReview(props.review?.id)}
      mediaType={props.review?.media[0]?.type}
    >
      <BoxMedia>
        <CardStar backgroundColor={config.background_color_secondary} onClick={() => { setIsClicked(!isClicked) }} isClicked={isClicked}>
          <AiFillStar size={icon.size} color={props.item.qtdStars > 0 ? icon.colorActive : icon.colorInative} />
          <AiFillStar size={icon.size} color={props.item.qtdStars > 1 ? icon.colorActive : icon.colorInative} />
          <AiFillStar size={icon.size} color={props.item.qtdStars > 2 ? icon.colorActive : icon.colorInative} />
          <AiFillStar size={icon.size} color={props.item.qtdStars > 3 ? icon.colorActive : icon.colorInative} />
          <AiFillStar size={icon.size} color={props.item.qtdStars > 4 ? icon.colorActive : icon.colorInative} />
          {/* <div className="info-container">

            </div> */}
          <CardInfo backgroundColor={config.background_color_secondary} isClicked={isClicked}>
            <div className="client-name-lily">
              <span style={{ display: "flex", justifyContent: "center", color: `${config.fontColorPrimary}` }}>
                {props.item.name}
                <BadgeCheck>
                  {props.item?.verified == 1 && <HiBadgeCheck style={{ marginLeft: "5px" }} size={20} color={"#5cb85c"} />}
                  <label>{translation.thema.cardReviewTiktokPreview.compraVerificada}</label>
                </BadgeCheck>

              </span>
              <label style={{ color: `${config.fontColorPrimary}`, textAlign: "center", fontSize: "12px", fontWeight: "initial" }}>
                {config.visible_date_review == "1" && new Date("2022-01-05").toLocaleDateString('pt-BR')}
              </label>
            </div>
            <BoxText>
              <label style={{ color: `${config.fontColorPrimary}` }}>{props.item.text}</label>
            </BoxText>
          </CardInfo>
        </CardStar>
        {/* <CardPlay>
          <iframe
            src={`https://tiktok.com/embed/7114045612609260806`}
            style={{ width: '-webkit-fill-available', height: '-webkit-fill-available', margin: '0px auto', borderRadius: '15px', }}
            allowfullscreen
            scrolling="no"
            allow="encrypted-media;"
          />
        </CardPlay> */}
        {getMedia(props.item.medias[0], props.item.type)}
      </BoxMedia>
      {/* <TitleName>
        <h4>{props.review.client.name[0] + "***" + props.review.client.name[props.review.client.name.length - 1]}</h4>
      </TitleName>
      <BoxInfor>
        <span>
          <AiFillStar size={icon.size} color={props.review?.rating > 0 ? icon.colorActive : icon.colorInative} />
          <AiFillStar size={icon.size} color={props.review?.rating > 1 ? icon.colorActive : icon.colorInative} />
          <AiFillStar size={icon.size} color={props.review?.rating > 2 ? icon.colorActive : icon.colorInative} />
          <AiFillStar size={icon.size} color={props.review?.rating > 3 ? icon.colorActive : icon.colorInative} />
          <AiFillStar size={icon.size} color={props.review?.rating > 4 ? icon.colorActive : icon.colorInative} />
        </span>
        <label> {new Date(props.review.updated_at).toLocaleDateString('pt-BR')} </label>
      </BoxInfor>
      <BoxText>
        <label>{props.review?.message}</label>
      </BoxText> */}
    </Container>
  );
};

export default CardTiktok;
