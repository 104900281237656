import styled from "styled-components";


export const Container = styled.form`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    //padding: 25px;
    border-radius: 5px;
    overflow: auto;
    //height: calc(500%) !important;
    margin-left: 0px ;
    flex:1 ;
    align-items: center ;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${props => props.img});
    background-size: 60%;
    justify-content: center;

    @media screen and (max-width: 450px) {
        width: 300px;
    }
`;

export const Body = styled.div`
    display: flex;
    background-color: ${props => props.backgroundColor};
    flex-direction: column;
    //margin-top: 20px;
    padding: 0px 0px;
    margin: 40px;
    border-radius: 5px;
    justify-content: center;
    width: calc(100% - 80px);
`

export const BoxAlerts = styled.div`
    display: flex;
    background-color: ${props => props.backgroundColor};
    flex-direction: column;
    margin-top: -50px;

    span{
        display: flex;
        //background-color: yellow;
        flex-direction: column;
        padding: 0px 50px;
        gap: 0px;
    }
    
`

export const ContainerPreview = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    overflow: hidden;
    height: ${props => props.animation ? "100vh" : "initial"};   
    //height: ${props => props.animation ? "100vh" : "initial"};  
    padding: 0px 0px;
    position: relative;
    background-color: ${props => props.backgroundColor};
    //background-color: red ;
    gap: 25px;
    //margin-top: 20px;
    margin: 0px 30px 30px 0px;
    width: 100%;
`
export const ContainerColumn = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    opacity: 1;
    padding: 0px 0px;    
    animation: ${props => props.animation ? `marquee ${props.velocity}s linear infinite` : "initial"} ;
    //background-color: yellow ;
    //width: 30% ;
    align-items: center ;
    gap: 25px;
    //width: 100%;
    //background-color: red;

    @media(min-width: 768px) {
        //width: 48% ;      
    }

    @media(min-width: 1100px) {
        //width: 30% ;      
    }

    @media(max-width: 768px) {
        //width: 90% ;      
    }

    @keyframes marquee {
        to {
            transform: translateY(-50%)
        }
    }
`
export const ContainerTitle = styled.div`
    display: flex;
    //background-color: yellow;
    justify-content: ${props => props.align};
    align-items: center;
    margin: 20px 20px;
    height: 40px;
    //padding: 0px 20px;

    label{
        //margin: 0px 20px;
        color: ${props => props.color};
        font-size: ${props => props.fontSize};
        font-family: ${props => props.font} !important;
        font-weight: 600;
    }
`