import React from 'react'
import { Container, ContainerInfor, InforResume, Number } from './styled'
import { AiFillStar } from 'react-icons/ai';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const ResumoReviewsModel04 = (props) => {

    const configControl = useSelector(state => state.reducerControlConfig)

    useEffect(() => {
        //console.log("resume", configControl)
    }, [configControl])


    return (
        <Container>
            <Number font={configControl.font} background={configControl.primary_color} color={configControl.font_color_secondary}>
                <span>4.7</span>
            </Number>
            <InforResume>
                <ContainerInfor porcent={80} color={configControl.primary_color}>
                    <p>5</p> <AiFillStar size={20} color={configControl.star_color_primary} />
                    <span>
                        <div />
                    </span>
                    <label>{80}</label>
                </ContainerInfor>
                <ContainerInfor porcent={10} color={configControl.primary_color}>
                    <p>4</p> <AiFillStar size={20} color={configControl.star_color_primary} />
                    <span>
                        <div />
                    </span>
                    <label>{10}</label>
                </ContainerInfor>
                <ContainerInfor porcent={5} color={configControl.primary_color}>
                    <p>3</p> <AiFillStar size={20} color={configControl.star_color_primary} />
                    <span>
                        <div />
                    </span>
                    <label>{5}</label>
                </ContainerInfor>
                <ContainerInfor porcent={4} color={configControl.primary_color}>
                    <p>2</p> <AiFillStar size={20} color={configControl.star_color_primary} />
                    <span>
                        <div />
                    </span>
                    <label>{4}</label>
                </ContainerInfor>
                <ContainerInfor porcent={1} color={configControl.primary_color}>
                    <p>1</p> <AiFillStar size={20} color={configControl.star_color_primary} />
                    <span>
                        <div />
                    </span>
                    <label>{1}</label>
                </ContainerInfor>
            </InforResume>
        </Container>
    )
}

export default ResumoReviewsModel04