import { useSelector } from "react-redux";
import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE, DELETE_CUSTOM } from "../../../../services/api";

export function* listReviews(action) {
   
    //console.log("listReviews - action", action)
    yield put({ type: "REVIEWS_REQUEST", })
    const result = yield call(GET, { endpoint: `/reviews?favorites=${action.payload.favorite}&rating=${action.payload.rating}&status=${action.payload.status}&search=${action.payload.search}&perPage=${action.payload.perPage}&page=${action.payload.page}&medias=${typeof action.payload.medias == "undefined" ? "" : action.payload.medias}&productId=${typeof action.payload.productId == "undefined" ? "" : action.payload.productId}` })
    //console.log("listReviews - result", result)
    //yield delay(5000)
    if (result === false) {
        yield put({ type: "REVIEWS_ERROR" })
    } else {
        yield put({ type: "REVIEWS_SUCCESS", payload: result.data.data })
    }
    yield put({ type: "SAGA_LIST_NOTIFY" })
}

export function* updateReview(action) {

    yield put({ type: "REVIEWS_UPDATE_REQUEST", })
    //yield delay(200);
    const result = yield call(PUT, { endpoint: `/reviews/${action.payload.id}`, data: action.payload })
    if (result === false) {
        yield put({ type: "REVIEWS_UPDATE_ERROR" })
    } else {
        yield put({ type: "REVIEWS_UPDATE_SUCCESS", payload: result.data })
    }
}

export function* updateSelectReviews(action) {

    yield put({ type: "REVIEWS_UPDATE_SELECT_REQUEST", })
    //yield delay(200);
    const result = yield call(PUT, { endpoint: `/reviews/all`, data: action.payload })
    if (result === false) {
        yield put({ type: "REVIEWS_UPDATE_SELECT_ERROR" })
    } else {
        yield put({ type: "REVIEWS_UPDATE_SELECT_SUCCESS", payload: result.data })
    }
}

export function* deleteReview(action) {

    yield put({ type: "REVIEWS_DELETE_REQUEST", })
    //yield delay(200);
    const result = yield call(DELETE, { endpoint: `/reviews/${action.payload.id}` })
    if (result === false) {
        yield put({ type: "REVIEWS_DELETE_ERROR" })
    } else {
        yield put({ type: "REVIEWS_DELETE_SUCCESS", payload: result.data })

    }
}

export function* deleteSelectReviews(action) {

    //console.log("deleteSelectReviews payload: ", action.payload)
    yield put({ type: "REVIEWS_DELETE_SELECT_REQUEST", })
    //yield delay(200);
    const result = yield call(DELETE_CUSTOM, { endpoint: `/reviews/all`, data: action.payload })
    if (result === false) {
        yield put({ type: "REVIEWS_DELETE_SELECT_ERROR" })
    } else {
        yield put({ type: "REVIEWS_DELETE_SELECT_SUCCESS", payload: result.data })
        yield put({ type: "REVIEWS_CONTROL_SELECT_RESET" })
    }
}

