
import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE } from "../../../../services/api";

export function* listCupons(action) {
    yield put({ type: "CUPONS_REQUEST", })
    //yield delay(5000);
    const result = yield call(GET, { endpoint: `/cupoms?search=${action.payload.search}&perPage=${action.payload.perPage}&page=${action.payload.page}` })
    if (result === false) {
        yield put({ type: "CUPONS_ERROR" })
    } else {
        yield put({ type: "CUPONS_SUCCESS", payload: result.data.data })
    }
}

export function* updateCupom(action) {

    yield put({ type: "CUPOM_UPDATE_REQUEST", })
    //yield delay(200);
    const result = yield call(PUT, { endpoint: `/cupoms/${action.payload.id}`, data: action.payload })
    if (result === false) {
        yield put({ type: "CUPOM_UPDATE_ERROR" })
    } else {
        yield put({ type: "CUPOM_UPDATE_SUCCESS", payload: result.data })
    }
}

export function* deleteCupom(action) {

    //console.log("deleteCupom ", action)
  
    yield put({ type: "CUPOM_DELETE_REQUEST", })
    //yield delay(200);
    const result = yield call(DELETE, { endpoint: `/cupoms/${action.payload.id}` })
    if (result === false) {
        yield put({ type: "CUPOM_DELETE_ERROR" })
    } else {
        yield put({ type: "CUPOM_DELETE_SUCCESS", payload: result.data })
    }
}


export function* addCupom(action) {
    yield put({ type: "CUPOM_ADD_REQUEST", })
    //yield delay(200);
    const result = yield call(POST, { endpoint: `/cupoms`, data: action.payload })
    if (result === false) {
        yield put({ type: "CUPOM_ADD_ERROR" })
    } else {
        yield put({ type: "CUPOM_ADD_SUCCESS", payload: result.data })
    }
}