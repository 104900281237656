import { Tooltip, Container, BoxContents, BoxContact, BoxOptions, BoxContentsLeft, SubTitle, Status, Img, ButtonAnswered, ButtonHidden, ButtonDelete, Box, BoxCheck, BoxActions, ButtonCustom, LabelReply, Badget } from "./styled"
import { AiFillStar, AiOutlineQuestionCircle, AiOutlineInfoCircle, AiFillEdit, AiOutlineMail, AiOutlineCheckCircle, AiFillClockCircle, AiFillCloseCircle, AiOutlineCheck, AiFillEye, AiFillEyeInvisible, AiOutlineQuestion, AiOutlineDelete } from "react-icons/ai";
import { interval } from "../../../../utils/date";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import Modal from "../modal";
import ModalDelete from "../modal-delete";
import { useState } from "react";
import { FaEdit, FaInfo, FaWhatsapp } from "react-icons/fa";
import { FiCheckCircle } from "react-icons/fi";
import Confirmation from "../../../../components/Modal-Confirmation";
import { RiQuestionnaireFill } from "react-icons/ri";
import { MdHeadsetMic } from "react-icons/md";
import { IoMdTrash } from "react-icons/io";
import { getTranslation } from "../../../../translations";
const Card = (props) => {

    const dispatch = useDispatch()
    const statusUpdateQuestion = useSelector(state => state.reducerUpdateQuestion)
    const [visibleModal, setVisibleModal] = useState(false)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [selectCard, setSelectCard] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const colorBorder = (status) => {
        switch (status) {
            case "pending":
                return "#f0ad4e"
            case "answered":
                return "#20D489" //#5cb85c
            case "hidden":
                return "#0275d8"
            case "new":
                return "#F13F6C"
            default:
                return "#f0ad4e"
        }
    }

    const getTextStatus = (status) => {
        switch (status) {
            case "pending":
                return translation.questions.card.perguntaPendente
            case "answered":
                return translation.questions.card.perguntaRespondida
            case "hidden":
                return translation.questions.card.perguntaEscondida
            case "new":
                return translation.questions.card.perguntaNova
            default:
                return ""
        }
    }

    const getIconStatus = (status) => {
        switch (status) {
            case "pending":
                return <AiOutlineQuestion size={15} color={"white"} />
            case "answered":
                return <AiOutlineCheck size={15} color={"white"} />
            case "hidden":
                return <AiFillEyeInvisible size={15} color={"white"} />
            case "new":
                return <FaInfo size={15} color={"white"} />
            default:
                return <AiOutlineQuestion size={15} color={"white"} />
        }
    }

    useEffect(() => {
        if (!(statusUpdateQuestion.loading == false && !statusUpdateQuestion.error == false && statusUpdateQuestion.data.length == 0)) {
            const id = 0
            if (statusUpdateQuestion.loading) {
                toast.loading(translation.questions.card.aguarde, { toastId: id })
            } else {
                if (statusUpdateQuestion.error) toast.update(id, { render: translation.questions.card.erroEnviar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else toast.update(id, { render: translation.questions.card.enviadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
            }
        }
    }, [statusUpdateQuestion])



    const updateQuestion = (status, id) => {
        dispatch({
            type: "SAGA_UPDATE_QUESTIONS",
            payload: {
                status: status,
                id: id
            }
        })
    }

    const statusDeleteQuestion = useSelector(state => state.reducerDeleteQuestion)
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)

    useEffect(() => {
        if (!(statusDeleteQuestion.loading == false && !statusDeleteQuestion.error == false && statusDeleteQuestion.data.length == 0)) {
            const id = "statusDeleteQuestion"
            if (statusDeleteQuestion.loading) {
                toast.loading(translation.questions.card.aguarde, { toastId: id })
            } else {
                if (statusDeleteQuestion.error) toast.update(id, { render: translation.questions.card.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.questions.card.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    setVisibleModalConfirmation(false)
                }
            }
        }
    }, [statusDeleteQuestion])

    const whatsappCustom = (text) => {
        //(99) 9.9999-9999 e (99) 9999-9999
        let result = text.length == 11 ? `(${text.slice(0, 2)}) ${text.slice(2, 3)}.${text.slice(3, 7)}-${text.slice(7, 11)}` : `(${text.slice(0, 2)}) ${text.slice(2, 6)}-${text.slice(6, 10)}`
        return result
    }

    const deleteQuestion = () => {

        dispatch({
            type: "SAGA_DELETE_QUESTIONS",
            payload: {
                id: props.item.id
            }
        })
    }

    const handleStatusCard = (checked) => {
        if (checked) {
            dispatch({
                type: "QUESTION_CONTROL_SELECT_SET",
                payload: { id: props.item.id, status: props.item.reply_message == null || props.item.reply_message == "" ? "pending" : "answered" }
            })
        } else {
            dispatch({
                type: "QUESTION_CONTROL_SELECT_REMOVE",
                payload: { id: props.item.id, status: props.item.reply_message == null || props.item.reply_message == "" ? "pending" : "answered" }
            })
        }
        setSelectCard(checked)
    }

    useEffect(() => { handleStatusCard(props.checkedAll) }, [props.checkedAll])

    //console.log("card question: ", props.item)

    const copyMail = () => {
        navigator.clipboard.writeText(props.item.email)
        toast.success(translation.questions.card.emailCopiado)
    }

    const getBadget = () => {
        switch (props.item.status) {
            case "answered":
                return (<Badget color={{ background: "#E4FFF4", color: "#21D487" }}>
                    <FiCheckCircle size={15} color={"#21D487"} />
                    <label>{translation.questions.card.respondido}</label>
                </Badget>)
            case "pending":
                return (<Badget color={{ background: "#f6f17b55", color: "#f0ad4e" }}>
                    <AiOutlineQuestionCircle size={15} color={"#f0ad4e"} />
                    <label>{translation.questions.card.pendente}</label>
                </Badget>)
            case "hidden":
                return (<Badget color={{ background: "#DDF1FA", color: "#0275d8" }}>
                    <AiFillEyeInvisible size={15} color={"#0275d8"} />
                    <label>{translation.questions.card.escondido}</label>
                </Badget>)
            case "new":
                return (<Badget color={{ background: "#f2b8a055", color: "#F13F6C" }}>
                    <AiOutlineInfoCircle size={15} color={"#F13F6C"} />
                    <label>{translation.questions.card.novo}</label>
                </Badget>)
            default:
                return null;
        }
    }

    return (
        <Container>
            <Modal visible={visibleModal} setVisibleModal={setVisibleModal} item={props.item} />
            {/* <ModalDelete visible={visibleModalDelete} setVisibleModal={setVisibleModalDelete} item={props.item} /> */}

            <Confirmation
                confirmation={deleteQuestion}
                visible={visibleModalConfirmation}
                setVisibleModal={setVisibleModalConfirmation}
                title={translation.questions.card.desejaExcluir}
                infor={translation.questions.card.obs}
            />

            {/* <Status color={colorBorder(props.item.status)}>
                {getIconStatus(props.item.status)}
                <Tooltip>
                    <label>{getTextStatus(props.item.status)}</label>
                </Tooltip>
            </Status> */}
            {getBadget()}

            {
                props?.noVisibleCheck ?
                    <></> :
                    <BoxCheck>
                        <input type="checkbox" checked={selectCard} onChange={(e) => { handleStatusCard(e.target.checked) }} />
                    </BoxCheck>
            }

            <Box>
                <BoxContents color={colorBorder(props.item.status)}>
                    <BoxContentsLeft>
                        <p style={{ marginBottom: "15px" }}>
                            {props.item.name} {translation.questions.card.sobre} <a target="_blank" href={props.item.canonical_url}>{props.item.product_name}</a> <span><AiFillClockCircle size={15} color={"gray"} /> <label > {interval(props.item.created_at)}{translation.questions.card.atras}</label></span>
                        </p>
                        <div style={{ marginBottom: "10px" }}>
                            <RiQuestionnaireFill size={25} color={"gray"} />
                            <p>
                                {props.item.message}
                            </p>
                        </div>
                        <div>
                            <MdHeadsetMic size={25} color={"gray"} />
                            {
                                props.item?.reply_message?.length > 1 ?
                                    <LabelReply>{props.item.reply_message}
                                        <span>
                                            <AiFillEdit onClick={() => { setVisibleModal(true) }} style={{ cursor: "pointer", marginTop: "1px", marginLeft: "5px", width: "18px", height: "18px" }} color={"#21D487"} size={25} />
                                            <label>{translation.questions.card.desejaEditar}</label>
                                        </span>
                                    </LabelReply> :
                                    <ButtonCustom onClick={() => { setVisibleModal(true) }} >
                                        <label>{translation.questions.card.responder}</label>
                                    </ButtonCustom>
                            }
                        </div>


                        {/* <BoxContact>
                            <label>E-mail: {props.item.email} </label>
                            <label>Whatsapp: <a target="_blank" href={`https://wa.me/+55${props.item.whatsapp}`}>{whatsappCustom(props.item.whatsapp)}</a></label>
                        </BoxContact> */}
                    </BoxContentsLeft>
                    <BoxActions>
                        {/* {props.item?.reply_message?.length > 0 ?
                            <AiOutlineCheckCircle onClick={() => { setVisibleModal(true) }} size={20} color={"gray"} /> :
                            <AiFillCloseCircle onClick={() => { setVisibleModal(true) }} size={20} color={"gray"} />} */}
                        <span onClick={() => { copyMail() }}>
                            <AiOutlineMail size={20} color={"#f0ad4e"} />
                            <label>{translation.questions.card.desejaCopiar} {props.item.email}</label>
                        </span>

                        <span>
                            {props.item.status == "hidden" ?
                                <AiFillEyeInvisible onClick={() => { updateQuestion(props.item?.reply_message?.length > 0 ? "answered" : "pending", props.item.id) }} size={20} color={"#0275d8"} /> :
                                <AiFillEye onClick={() => { updateQuestion("hidden", props.item.id) }} size={20} color={"#0275d8"} />}
                            <label>
                                {props.item.status == "hidden" ?
                                    translation.questions.card.tornarVisivel :
                                    translation.questions.card.tornarInvisivel}
                            </label>
                        </span>
                        <span>
                            <FaWhatsapp size={20} color={"#5cb85c"} onClick={() => { window.open(`https://wa.me/+${props.item.whatsapp}`) }} />
                            <label>{`${translation.questions.card.whatsappCliente} +${props.item.whatsapp}`}</label>
                        </span>
                        <span>
                            <IoMdTrash size={20} color={"#F13F6C"} onClick={() => { setVisibleModalConfirmation(true) }} />
                            <label>{translation.questions.card.desejaExcluir}</label>
                        </span>
                    </BoxActions>
                </BoxContents>
                {/* <BoxOptions>
                    <ButtonAnswered onClick={() => { setVisibleModal(true) }}
                        active={props.item.status == "answered" ? false : true}
                    //disabled={props.item.status == "answered" ? true : false}
                    >
                        {props.item.status == "answered" ? "Respondido" : "Responder"}
                        <span>
                            <AiOutlineCheck size={15} color={props.item.status == "answered" ? "#19B674" : "white"} />
                        </span>
                    </ButtonAnswered>
                    <ButtonHidden onClick={() => {
                        if (props.item.status == "hidden" && props.item.reply_message != null) {
                            updateQuestion("answered", props.item.id)
                        } else if (props.item.status == "hidden" && props.item.reply_message == null) {
                            updateQuestion("pending", props.item.id)
                        } else if (props.item.status != "hidden") {
                            updateQuestion("hidden", props.item.id)
                        }
                    }}
                        active={true}
                    //disabled={props.item.status == "hidden" ? true : false}
                    >
                        {props.item.status != "hidden" ? "Esconder" : "Mostrar"}
                        <span>
                            {props.item.status != "hidden" ? <AiFillEyeInvisible size={15} color={"white"} /> : <AiFillEye size={15} color={"white"} />}
                        </span>
                    </ButtonHidden> 
                    <ButtonDelete onClick={() => {
                        //setVisibleModalDelete(true)
                        setVisibleModalConfirmation(true)
                    }} >
                        Excluir
                        <span>
                            <AiOutlineDelete size={15} color={"white"} />
                        </span>
                    </ButtonDelete>
                </BoxOptions> */}
            </Box>
        </Container >
    )
}

export default Card
