
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Pagination, Navigation, Scrollbar, A11y } from "swiper";
import Modal from "react-modal";
import "./styles.css";
import { ZiggeoPlayer } from 'react-ziggeo'
import { useEffect } from "react";
import { useState } from "react";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        with: "500px"

    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "2"
    }
};


const ModalReviews = (props) => {

    //console.log("Teste id tik tok", props.data[0]?.url   )

    const [player, setPlayer] = useState(null);
    useEffect(() => {
        if (player) {
            // DO stuff here
            player.on("attached", function (embedding) { }, player);
        }
    }, [player]);

    const handlePlaying = (embedding) => {
        //console.log('it\'s playing, your action here');
    };

    const handlePaused = (embedding) => {
        //console.log('it\'s paused, your action when pause');
    };

    const iframe_container = {
        left: 0,
        width: "250px",
        height: "500px",
        position: "relative"
    }

    const iframe = {
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        position: "absolute",
        border: 0
    }

    return (
        <Modal
            isOpen={props.visibleModal}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => props.setVisibleModal(false)}
        >
            {
                props.data[0]?.type == "tiktok" ?
    
                        <iframe
                            src= {`https://tiktok.com/embed/${props.data[0]?.url}`}
                            style={{width: "241px", height: "500px"}}
                            allowfullscreen
                            scrolling="no"
                            allow="encrypted-media;"
                        ></iframe>
                    
:
                    <div style={{ width: "600px", height: "400px" }}>
                        <Swiper
                            pagination={{
                                type: "fraction",
                            }}
                            navigation={true}
                            modules={[Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {
                                props.data.map((item) => (
                                    //console.log(item)
                                    <SwiperSlide key={item.id} >
                                        {
                                            item.type == "video" ?
                                                <ZiggeoPlayer
                                                    apiKey={process.env.REACT_APP_ZIGGEO_APP_TOKEN}
                                                    video={item.url}
                                                    theme={'modern'}
                                                    themecolor={'yellow'}
                                                    skipinitial={false}
                                                    onPlaying={handlePlaying}
                                                    onPaused={handlePaused}
                                                    onRef={ref => (setPlayer(ref))}
                                                    popup={true}
                                                    popup-height={400}
                                                /> :
                                                // item.type == "image" ?
                                                <img src={item.url} />
                                            // <iframe
                                            //     src="https://tiktok.com/embed/7095921094833605893"
                                            //     style={{ width: '-webkit-fill-available', height: '-webkit-fill-available', margin: '0px auto', borderRadius: '15px' }}
                                            // />
                                        }
                                    </SwiperSlide>
                                ))
                            }
                            {/* <SwiperSlide><img src={props.data} /></SwiperSlide>
                    <SwiperSlide>Slide 2</SwiperSlide>
                    <SwiperSlide><img src="https://img.ibxk.com.br/2017/07/13/13160112901226.jpg" /></SwiperSlide>
                    <SwiperSlide>Slide 4</SwiperSlide>
                    <SwiperSlide><img src="https://img.ibxk.com.br/2017/07/13/13160112901226.jpg" /></SwiperSlide>
                    <SwiperSlide>
                        <ZiggeoPlayer
                            apiKey={'abc4f8a95502bbeaa3a196ea369d3153'}
                            video={'3085e73685ac8500f2ca9f08c7fe62e7'}
                            theme={'modern'}
                            themecolor={'yellow'}
                            skipinitial={false}
                            onPlaying={handlePlaying}
                            onPaused={handlePaused}
                            onRef={ref => (setPlayer(ref))}
                            popup={true}
                            popup-height={400}
                        />
                    </SwiperSlide>
                    <SwiperSlide><img src="https://img.ibxk.com.br/2017/07/13/13160112901226.jpg" /></SwiperSlide>
                    <SwiperSlide>Slide 8</SwiperSlide>
                    <SwiperSlide>
                        <ZiggeoPlayer
                            apiKey={'abc4f8a95502bbeaa3a196ea369d3153'}
                            video={'68b18dfe5e356388d32ce073d3bce254'}
                            theme={'modern'}
                            themecolor={'yellow'}
                            skipinitial={false}
                            onPlaying={handlePlaying}
                            onPaused={handlePaused}
                            onRef={ref => (setPlayer(ref))}
                            popup={true}
                            popup-height={400}
                        />
                    </SwiperSlide> */}
                        </Swiper>
                    </div>

            }

        </Modal>
    );
};

export default ModalReviews;
