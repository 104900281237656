import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ActionsDropdown, BoxIcon, BoxLoading, ButtonCustom, TooltipAccount } from "./styled";
import ConfirmationDelete from "../../../../components/Modal-Confirmation";
import { useState } from "react";
import { toast } from "react-toastify";
import { FaLayerGroup } from "react-icons/fa";
import IconShopify from "../../../../assets/imgs/icons-integrations/shopify.png";
import IconNuvemshop from "../../../../assets/imgs/icons-integrations/nuvemshop.png";
import IconDropi from "../../../../assets/imgs/icons-integrations/dropi.png";
import IconSite from "../../../../assets/imgs/site.webp";
import IconHotmart from "../../../../assets/imgs/icons-integrations/hotmart.png";
import IconCartpanda from "../../../../assets/imgs/icons-integrations/cartpanda.png";
import IconLandpage from "../../../../assets/imgs/icons-integrations/landpage.png";
import IconLojaIntegrada from "../../../../assets/imgs/icons-integrations/loja-integrada.png";
import IconMontink from "../../../../assets/imgs/icons-integrations/montink.png";
import { AiOutlineLoading } from "react-icons/ai";
import { FiCalendar } from "react-icons/fi";
import ModalAddTime from "../modal-add-time";
import { FiMoreVertical } from "react-icons/fi";
import ModalXpatch from "../modal-xpatch";
import { date } from "yup";
import { MdOutlineContentCopy } from "react-icons/md";

const Card = (props) => {

    const statusDebug = useSelector(state => state.reducerAdminDebug)
    const statusDropi = useSelector(state => state.reducerAdminDropi)
    const statusAddTime = useSelector(state => state.reducerAdminAddTime)
    const statusXpatch = useSelector(state => state.reducerAdminXpatch)

    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)
    const [visibleModalAddTime, setVisibleModalAddTime] = useState(false)
    const [visibleModalXpatch, setVisibleModalXpatch] = useState(false)
    const dispatch = useDispatch()

    //console.log("CArd line", props)

    const [visibleMenu, setVisibleMenu] = useState(false)

    const closeMenu = (event) => {
        setVisibleMenu(false)
    };

    useEffect(() => {
        //console.log("teste click visibleMenu", visibleMenu)
        if (visibleMenu) {
            window.addEventListener('click', closeMenu);
            // cleanup this component
            return () => {
                window.removeEventListener('click', closeMenu);
            };
        }
        //console.log("visibleMenu", visibleMenu)
    }, [visibleMenu])


    const testeClick = () => {
        document.getElementById("myCheck").click();
    }


    // useEffect(() => {
    //     //console.log("statusDebug", statusDebug)
    //     if (!statusDebug.loading && !statusDebug.error && statusDebug.data.length == 0) {
    //     } else {
    //         const id = "statusDebug"
    //         if (statusDebug.loading) {
    //             //toast.loading("Aguarde...", { toastId: id })
    //         } else {
    //             if (statusDebug.error) {
    //                 //toast.update(id, { render: "Erro ao tentar autenticar.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
    //             } else {
    //                 //toast.update(id, { render: "Autenticação feita com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
    //                 setVisibleModalConfirmation(false)
    //                 window.open('https://app.lily.com.br/', '_blank');
    //             }
    //         }
    //     }
    // }, [statusDebug])

    useEffect(() => {
        if (!statusDebug.loading && !statusDebug.error && statusDebug.data.length == 0) {
        } else {
            if (!statusDebug.loading && !statusDebug.error) {
                setVisibleModalConfirmation(false)
                window.open(process.env.REACT_APP_URL, '_blank');
            }
        }
    }, [statusDebug])

    const statusDeleteCard = useSelector(state => state.reducerAdminDeleteCard)
    useEffect(() => {
        if (!(statusDeleteCard.loading == false && !statusDeleteCard.error == false && statusDeleteCard.data.length == 0)) {
            const id = "statusDeleteCard"
            if (statusDeleteCard.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusDeleteCard.error) toast.update(id, { render: "Erro ao tentar excluir.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: "Excluído com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    setVisibleModalConfirmation(false)
                }
            }
        }
    }, [statusDeleteCard])

    useEffect(() => {
        if (!(statusAddTime.loading == false && !statusAddTime.error == false && statusAddTime.data.length == 0)) {
            const id = "statusAddTime"
            if (statusAddTime.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusAddTime.error) toast.update(id, { render: "Erro ao tentar modificar tempo de acesso.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: "Modificado com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    setVisibleModalAddTime(false)
                    //document.location.reload(true)
                }
            }
        }
    }, [statusAddTime])

    useEffect(() => {
        if (!(statusXpatch.loading == false && !statusXpatch.error == false && statusXpatch.data.length == 0)) {
            const id = "statusXpatch"
            if (statusXpatch.loading) {
                toast.loading("Aguarde...", { toastId: id })
            } else {
                if (statusXpatch.error) toast.update(id, { render: "Erro ao tentar modificar tempo de acesso.", type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: "Modificado com sucesso.", type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    setVisibleModalXpatch(false)
                    //document.location.reload(true)
                }
            }
        }
    }, [statusXpatch])

    const goDebug = (item) => {
        //console.log("ID: ", item.id)
        dispatch({
            type: "SAGA_DEBUG_ADMIN", payload: {
                id: item.id
            }
        })
    }

    const addTimeUser = (qtd, type) => {

        dispatch({
            type: "SAGA_ADD_TIME_ADMIN", payload: {
                id: props.item.id,
                days: type == "days" ? parseInt(qtd) : 0,
                months: type == "months" ? parseInt(qtd) : 0
            }
        })
    }

    const saveXpatch = (review, reviewsMobile, stars, starsMobile) => {
        //console.log("ID: ", props.item.id)
        dispatch({
            type: "SAGA_XPATCH_ADMIN", payload: {
                id_integration: props.item.platform_id,
                xpath_reviews: review,
                xpath_stars: stars,
                xpath_reviews_mobile: reviewsMobile,
                xpath_stars_mobile: starsMobile
            }
        })
    }

    const whatsappCustom = (text) => {
        //(99) 9.9999-9999 e +55 (99) 99999999
        if (text) {
            let result = text?.length == 13 ?
                `+${text.slice(0, 2)} (${text.slice(2, 4)}) ${text.slice(4, 5)}.${text.slice(5, 9)}-${text.slice(9, 13)}` :
                text?.length == 10 ? `+55 (${text.slice(0, 2)}) ${text.slice(2, 6)}-${text.slice(6, 10)}` :
                    text?.length == 11 ? `+55 (${text.slice(0, 2)}) ${text.slice(2, 3)}.${text.slice(3, 7)}-${text.slice(7, 11)}` :
                        `+${text.slice(0, 2)} (${text.slice(2, 4)}) ${text.slice(4, 8)}-${text.slice(8, 12)}`
            return result
        }

        return '';
    }

    const deleteCard = () => {
        //alert("Em desenvolvimento!")
        dispatch({
            type: "SAGA_DELETE_CARD_ADMIN",
            payload: { id: props.item.id }
        })
    }

    const getPlatform = (platform) => {

        switch (platform) {

            case "montink":
                return <img src={IconMontink} onClick={() => { window.open(`https://${props.item.url}`) }} />
            case "loja_integrada":
                return <img src={IconLojaIntegrada} onClick={() => { window.open(`https://${props.item.url}`) }} />
            case "cartpanda":
                return <img src={IconCartpanda} onClick={() => { window.open(`https://${props.item.url}`) }} />
            case "landpage":
                return <img src={IconLandpage} onClick={() => { window.open(`https://${props.item.url}`) }} />
            case "shopify":
                return <img src={IconShopify} onClick={() => { window.open(`https://${props.item.url}`) }} />
            case "nuvemshop":
                return <img src={IconNuvemshop} onClick={() => { window.open(`https://${props.item.url}`) }} />
            case "hotmart":
                return <img src={IconHotmart} onClick={() => { window.open(`https://${props.item.url}`) }} />
            case "custom":
                return <img src={"https://celulaweb.com.br/wp-content/uploads/2021/02/icon-site.png"} onClick={() => { window.open(`https://${props.item.url}`) }} />
            default:
                return null
        }
    }

    const scriptDropi = () => {
        dispatch({ type: "SAGA_DROPI_ADMIN", payload: { id: props.item?.id, token: props.item?.access_token, platform_id: props.item?.platform_id } })
    }

    const getStatusAccount = (value) => {

        //console.log("teste date credit", new Date(value), new Date(), new Date(value) > new Date())

        if (value == 0) {
            return "gray"
        } else if (new Date(value) > new Date()) {
            return "green"
        } else {
            return "red"
        }

    }

    useEffect(() => {
        //console.log("testy cred_expire", new Date(props.item.cred_expire * 1000).toLocaleString())
    }, [])

    const copy = () => {
        navigator.clipboard.writeText(props.item?.whatsapp)
        //setCopyKey(text)
        toast.success("Copiado")
    }

    return (
        <tr key={props.item.id} id="myCheck">
            <th>
                <div style={{ display: "flex", justifyContent: "center", }}>
                    {props.item.id}
                </div>
            </th>
            <th style={{ display: "flex", alignItems: "center", textAlign: "left", paddingLeft: "10px" }}>
                {props.item.name}
                [{props.item.chave_empreender}]
                <BoxIcon style={{ display: "flex", height: "40px" }}>
                    {getPlatform(props.item.platform)}
                    {props.item?.dropi == "dropi" && <img onClick={() => { window.open(`https://${props.item.url}`) }} src={IconDropi} />}
                </BoxIcon>
            </th>
            <th >
                <a style={{ color: "#5e6278", marginRight: "10px" }} target="_blank" href={`https://wa.me/+${props.item.whatsapp?.length == 10 ? "55" + props.item?.whatsapp : props.item.whatsapp?.length == 11 ? "55" + props.item?.whatsapp : props.item?.whatsapp}`}>
                    {whatsappCustom(props.item?.whatsapp)}
                </a>
                {props.item?.whatsapp?.length > 0 && <MdOutlineContentCopy onClick={() => { copy() }} style={{ cursor: "pointer" }} size={24} color="gray" />}
            </th>
            <th>{new Date(props.item.created_at).toLocaleString()}</th>
            <th style={{ color: getStatusAccount(props.item.cred_expire * 1000) }}>{props.item.cred_expire == 0 ? new Date(new Date(props.item.created_at).setDate(new Date(props.item.created_at).getDate() + (props.item.affiliate == null ? 3 : 10))).toLocaleString() + " (Trial)" : new Date(props.item.cred_expire * 1000).toLocaleString()}</th>
            <th>{props.item.email}</th>
            <th>
                <div style={{ display: "flex", justifyContent: "center", }}>
                    {props.item.inter_pay?.length == 0 ?
                        <ButtonCustom onClick={() => { if (props.item.inter_pay.length != 0) setVisibleModalConfirmation(true) }} background={"#F1416C50"} color={"gray"} >Remover </ButtonCustom> :
                        <ButtonCustom onClick={() => { setVisibleModalConfirmation(true) }} active={true} background={"#F1416C"} color={"white"} >Remover </ButtonCustom>}
                </div>
            </th>
            <th style={{ display: "flex", justifyContent: "center" }}>

                <ActionsDropdown onClick={() => { setVisibleMenu(true) }} visible={visibleMenu}>
                    <label>Ações</label>
                    <div>
                        <label onClick={() => { goDebug(props.item) }} >Debug</label>
                        <label onClick={() => { setVisibleModalAddTime(true) }} >Conta</label>
                        {/* <label>Script</label> */}
                        {props.item?.dropi == "dropi" && getPlatform(props.item.platform) !== null ? <label onClick={() => { scriptDropi() }} >Dropi</label> : null}
                        <label onClick={() => { setVisibleModalXpatch(true); setVisibleMenu(false); setVisibleMenu(false) }} >Xpath</label>
                    </div>
                </ActionsDropdown>

                {/* <ButtonCustom onClick={() => { goDebug(props.item) }} background={"#19B674"} color={"white"} active={true}                                   >
                    Debug
                </ButtonCustom>
                <ButtonCustom onClick={() => { setVisibleModalAddTime(true) }} background={"#282876"} color={"white"} active={true}                                   >
                    Conta
                </ButtonCustom>
                <ButtonCustom type="button" disabled onClick={() => { alert("Teste") }} background={"#FFC16350"} color={"#29292950"} active={false}                                   >
                    Script
                </ButtonCustom>

                {props.item?.dropi == "dropi" && getPlatform(props.item.platform) !== null ?
                    <ButtonCustom type="button" active={true} onClick={() => { scriptDropi() }} background={"#182390"} color={"white"} >
                        {statusDropi.loading && statusDropi.data == props.item.id ?
                            <AiOutlineLoading size={15} color={"white"} /> :
                            "Dropi"}
                    </ButtonCustom> :
                    <ButtonCustom type="button" disabled active={false} background={"#18239050"} color={"white"} >Dropi </ButtonCustom>} */}


                {/* <ButtonCustom type="button" onClick={() => { scriptDropi(props.item.platform_id) }} background={"#182390"} color={"white"} disabled={props.item?.dropi == "dropi" ? true : false} active={props.item?.dropi == "dropi" ? true : false}                                   >
                    {statusDropi.loading && statusDropi.data == props.item.id ?
                        <AiOutlineLoading size={15} color={"white"} /> :
                        "dropi"}
                </ButtonCustom> */}

            </th>
            <ConfirmationDelete
                confirmation={deleteCard}
                visible={visibleModalConfirmation}
                setVisibleModal={setVisibleModalConfirmation}
                title={"Deseja excluir esse cartão?"}
            />
            <ModalAddTime
                visible={visibleModalAddTime}
                setVisibleModal={setVisibleModalAddTime}
                addTimeUser={addTimeUser}
                item={props.item}
            />
            <ModalXpatch
                visible={visibleModalXpatch}
                setVisibleModal={setVisibleModalXpatch}
                saveXpatch={saveXpatch}
                item={props.item}
            />
        </tr>
    )
}

export default Card
