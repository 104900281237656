import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Header from "../../../../components/Header";
import Alert from "../../../../components/Alert/index";
import Contents from "../../../../components/Contents";
import CardPlano from '../../components/CardPlano/index'
import ModalCupom from '../../components/ModalCupom/index'
import FormaPagamento from '../../components/FormaPagamento/index'
import TablePagamentos from '../../components/TablePagamentos/index'
import DadosNotaFiscal from '../../components/DadosNotaFiscal/index'
import Avisos from "../../components/Avisos";
import { BoxInforInitial, BoxInforInitialLeft, BoxInforInitialRight, Container, Cupom } from './styled'
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { PagamentoProvider } from "../../hooks/usePagamento";
import { BoxLoading } from "../../../questions/pages/home/styled";
import { AiOutlineLoading } from "react-icons/ai";
import { displayScript, isVisibleScript } from "../../../../utils/displayScript";
import CardPlanoLoading from "../../components/CardPlano-loading";
import AvisosLoading from "../../components/Avisos-loading";
import LoadingLogo from "../../../../components/Loading-logo";

const Home = () => {
    const payments = useSelector(state => state.reducerPayments)
    const dispatch = useDispatch()

    useEffect(() => {

        dispatch({
            type: 'SAGA_LIST_PAYMENTS'
        })
    }, [])

    let pagamentoSimplificado = payments?.data?.pagamentoSimplificado;

    return (
        <Body>
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1", minWidth: '0%' }} >
                <Header />
                <Contents>
                    {payments.loading ?
                        // <Container>
                        //     <AvisosLoading />
                        //     <div style={{ display: "flex", gap: "20px" }}>
                        //         <CardPlanoLoading />
                        //         <CardPlanoLoading />
                        //     </div>
                        // </Container>
                        <div style={{ display: "flex", height: "100%", flex: 1, justifyContent: "center", alignItems: "center" }}>
                            <LoadingLogo />
                        </div> :

                        <PagamentoProvider>
                            <Container>
                                {/* <BoxInforInitial>
                                   <BoxInforInitialLeft>
                                        <label>Assinatura</label>
                                        <label>
                                        Pagamentos são feitos através do Empreender Plus. Empreender Plus é uma central de cadastros e pagamentos da Empreender, onde você receberá descontos por utilizar nossos aplicativos.
                                        </label>
                                    </BoxInforInitialLeft>
                                    <BoxInforInitialRight>
                                        <label onClick={() => {  }}>Ir para o PLus</label>
                                    </BoxInforInitialRight>
                                </BoxInforInitial> */}


                                {/* //quebrando mobile */}
                                <Avisos avisoPlus={pagamentoSimplificado} />


                                {/* <Alert marginBottom="30px">
                            <p>Promoção válida até 31/12/2021 <small>*valores poderão sofrer alteração após essa data</small></p>
                        </Alert> */}
                                {payments?.data?.dadosPlanos?.planos && Object.values(payments?.data?.dadosPlanos?.planos).map((plan, index) => {
                                    if (!plan.legacy || plan.id == payments.data.currentPlan.id) {
                                        return (
                                            <div style={{ display: "flex", gap: "20px", marginTop: "30px" }}>
                                                <CardPlano key={index} currentPlan={payments.data.currentPlan} idPlan={plan.id} name={plan.name} value={plan.value} features={payments.data.dadosPlanos.funcionalidades} />
                                            </div>
                                        )
                                    } else {
                                        return <></>
                                    }
                                })}

                                {!pagamentoSimplificado &&
                                    <>
                                        <Cupom>
                                            <ModalCupom cupomInitialValue={payments?.data?.user?.coupon ?? ""} />
                                        </Cupom>
                                        <FormaPagamento />
                                    </>
                                }
                                <TablePagamentos faturasInitialValue={payments?.data?.transactions ?? []} />
                                {!pagamentoSimplificado &&
                                    <DadosNotaFiscal dadosFiscais={payments?.data?.taxUser ?? {}} listaUfs={payments?.data?.ufList ?? []} />
                                }
                            </Container>
                        </PagamentoProvider>
                    }
                </ Contents>
            </div>
            {isVisibleScript(false)}
        </Body >
    );
}

export default Home