import { Container, BoxLoading, FilterOption, Filters, BoxCards, TitleCard, ContentsCards, Line } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import Card from "../../components/Card";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../components/Modal";
import { isVisibleScript } from "../../../../utils/displayScript";
import { platforms } from "./dataCards";
import { AiOutlineLoading } from "react-icons/ai";
import { AvaliacaoPopUpProvider } from "../../../../hooks/UseAvaliacaoPopUp";
import CardLoading from "../../components/Card-loading";
import { NavInfor } from "../../../dashboard/pages/home/styled";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import ModalInforVideoShopify from "../../../../components/modal-infor-video";
import { getTranslation } from "../../../../translations";
import LoadingLogo from "../../../../components/Loading-logo";

const Home = () => {

    const [statusNuvemshop, setStatusNuvemshop] = useState(false)
    const [statusShopify, setStatusShopify] = useState(false)
    const [statusDropi, setStatusDropi] = useState(false)
    const [statusCustom, setStatusCustom] = useState(false)
    const [statusHotmart, setStatusHotmart] = useState(false)
    const [statusMontink, setStatusMontink] = useState(false)
    const [statusRastreio, setStatusRastreio] = useState(false)
    const [statusLandPage, setStatusLandPage] = useState(false)
    const [statusWoocommerce, setStatusWoocommerce] = useState(false)
    const [statusLojaIntegrada, setStatusLojaIntegrada] = useState(false)
    const [statusCartpanda, setStatusCartpanda] = useState(false)
    const [statusWix, setStatusWix] = useState(false)
    const [statusTray, setStatusTray] = useState(false)
    const [statusBagy, setStatusBagy] = useState(false)
    const [statusSak, setStatusSak] = useState(false)
    const statusPlatforms = useSelector(state => state.reducerGetPlatforms)
    const dispatch = useDispatch()
    const [filter, setFilter] = useState("")

    const [visibleModalInforVideoIntgration, setVisibleModalInforVideoIntgration] = useState(false)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        if (statusPlatforms.data.length > 0) {
            for (let item of statusPlatforms.data) {
                switch (item.platform) {
                    case "nuvemshop": setStatusNuvemshop(true)
                        break;
                    case "shopify": setStatusShopify(true)
                        break;
                    case "dropi": setStatusDropi(true)
                        break;
                    case "custom": setStatusCustom(true)
                        break;
                    case "hotmart": setStatusHotmart(true)
                        break;
                    case "montink": setStatusMontink(true)
                        break;
                    case "rastreio": setStatusRastreio(true)
                        break;
                    case "landpage": setStatusLandPage(true)
                        break;
                    case "loja_integrada": setStatusLojaIntegrada(true)
                        break;
                    case "cartpanda": setStatusCartpanda(true)
                        break;
                    case "wix": setStatusWix(true)
                        break;
                    case "tray": setStatusTray(true)
                        break;
                    case "bagy": setStatusBagy(true)
                        break;
                    case "woocommerce": setStatusWoocommerce(true)
                        break;
                    case "sak": setStatusSak(true)
                        break;
                    default: break;
                }
            }
        } else {
            setStatusNuvemshop(false)
            setStatusShopify(false)
            setStatusDropi(false)
            setStatusCustom(false)
            setStatusHotmart(false)
            setStatusMontink(false)
            setStatusRastreio(false)
            setStatusLandPage(false)
            setStatusLojaIntegrada(false)
            setStatusCartpanda(false)
            setStatusWix(false)
            setStatusTray(false)
            setStatusBagy(false)
            setStatusWoocommerce(false)
            setStatusSak(false)
        }
    }, [statusPlatforms])

    useEffect(() => { dispatch({ type: "SAGA_GET_PLATFORMS_INTEGRATION" }) }, [])



    const getCards = () => {
        switch (filter) {
            case "":
                return (
                    <>
                        <Line>
                            <TitleCard>{translation.integrations.empreender}</TitleCard>
                            <BoxCards>
                                {
                                    statusPlatforms.loading ?
                                        Array(3).fill(<CardLoading />) :
                                        <>
                                            <Card platform={platforms.dropi} install={statusDropi} />
                                            <Card platform={platforms.landpage} install={statusLandPage} />
                                            <Card platform={platforms.montink} install={statusMontink} />
                                            <Card platform={platforms.sak} install={statusSak} />
                                            {/* <Card platform={platforms.rastreio} install={statusRastreio} /> */}
                                        </>
                                }

                            </BoxCards>
                        </Line>
                        <Line>
                            <TitleCard>{translation.integrations.plataformas}</TitleCard>
                            <BoxCards>
                                {
                                    statusPlatforms.loading ?
                                        Array(3).fill(<CardLoading />) :
                                        <>
                                            <Card platform={platforms.nuvemshop} install={statusNuvemshop} />
                                            <Card platform={platforms.shopify} install={statusShopify} />
                                            <Card platform={platforms.hotmart} install={statusHotmart} />
                                            <Card platform={platforms.cartpanda} install={statusCartpanda} />
                                            <Card platform={platforms.loja_integrada} install={statusLojaIntegrada} />
                                            <Card platform={platforms.wix} install={statusWix} />
                                            {/* <Card platform={platforms.woocommerce} install={statusWoocommerce} /> */}
                                            <Card platform={platforms.tray} install={statusTray} />
                                            <Card platform={platforms.bagy} install={statusBagy} />
                                            <Card platform={platforms.shopee} install={true} />

                                        </>
                                }

                            </BoxCards >
                        </Line >
                        <Line>
                            <TitleCard>{translation.integrations.outros}</TitleCard>
                            <BoxCards>
                                {
                                    statusPlatforms.loading ?
                                        Array(1).fill(<CardLoading />) :
                                        <>
                                            <Card platform={platforms.custom} install={statusCustom} />
                                        </>
                                }

                            </BoxCards>
                        </Line>
                    </>
                )

            case "ecommerce":
                return (
                    <Line>
                        <TitleCard>{translation.integrations.plataformas}</TitleCard>
                        <BoxCards>
                            {
                                statusPlatforms.loading ?
                                    Array(3).fill(<CardLoading />) :
                                    <>
                                        <Card platform={platforms.nuvemshop} install={statusNuvemshop} />
                                        <Card platform={platforms.shopify} install={statusShopify} />
                                        <Card platform={platforms.hotmart} install={statusHotmart} />
                                        <Card platform={platforms.cartpanda} install={statusCartpanda} />
                                        <Card platform={platforms.loja_integrada} install={statusLojaIntegrada} />
                                        <Card platform={platforms.wix} install={statusWix} />
                                        {/* <Card platform={platforms.woocommerce} install={statusWoocommerce} /> */}
                                        <Card platform={platforms.tray} install={statusTray} />
                                        <Card platform={platforms.bagy} install={statusBagy} />
                                        <Card platform={platforms.shopee} install={true} />
                                    </>
                            }

                        </BoxCards >
                    </Line >
                )

            case "products":
                return (
                    <Line>
                        <TitleCard>{translation.integrations.empreender}</TitleCard>
                        <BoxCards>
                            {
                                statusPlatforms.loading ?
                                    Array(3).fill(<CardLoading />) :
                                    <>
                                        <Card platform={platforms.dropi} install={statusDropi} />
                                        {/* <Card platform={platforms.rastreio} install={statusRastreio} /> */}
                                        <Card platform={platforms.landpage} install={statusLandPage} />
                                        <Card platform={platforms.montink} install={statusMontink} />
                                        <Card platform={platforms.sak} install={statusSak} />
                                    </>
                            }

                        </BoxCards>
                    </Line>
                )

            case "others":
                return (
                    <Line>
                        <TitleCard>{translation.integrations.outros}</TitleCard>
                        <BoxCards>
                            {
                                statusPlatforms.loading ?
                                    Array(1).fill(<CardLoading />) :
                                    <>
                                        <Card platform={platforms.custom} install={statusCustom} />

                                    </>
                            }
                        </BoxCards>
                    </Line>
                )

            default:
                return (
                    <>
                        <Line>
                            <TitleCard>{translation.integrations.empreender}</TitleCard>
                            <BoxCards>
                                {statusPlatforms.loading ?
                                    Array(3).fill(<CardLoading />) :
                                    <>
                                        <Card platform={platforms.dropi} install={statusDropi} />
                                        {/* <Card platform={platforms.rastreio} install={statusRastreio} /> */}
                                        <Card platform={platforms.landpage} install={statusLandPage} />
                                        <Card platform={platforms.montink} install={statusMontink} />
                                        <Card platform={platforms.sak} install={statusSak} />
                                    </>}

                            </BoxCards>
                        </Line>
                        <Line>
                            <TitleCard>{translation.integrations.plataformas}</TitleCard>
                            <BoxCards>
                                {
                                    statusPlatforms.loading ?
                                        Array(3).fill(<CardLoading />) :
                                        <>
                                            <Card platform={platforms.nuvemshop} install={statusNuvemshop} />
                                            <Card platform={platforms.shopify} install={statusShopify} />
                                            <Card platform={platforms.hotmart} install={statusHotmart} />
                                            <Card platform={platforms.cartpanda} install={statusCartpanda} />
                                            <Card platform={platforms.loja_integrada} install={statusLojaIntegrada} />
                                            <Card platform={platforms.wix} install={statusWix} />
                                            {/* <Card platform={platforms.woocommerce} install={statusWoocommerce} /> */}
                                            <Card platform={platforms.tray} install={statusTray} />
                                            <Card platform={platforms.bagy} install={statusBagy} />
                                            <Card platform={platforms.shopee} install={true} />
                                        </>
                                }
                            </BoxCards >
                        </Line >
                        <Line>
                            <TitleCard>{translation.integrations.outros}</TitleCard>
                            <BoxCards>
                                {statusPlatforms.loading ?
                                    Array(1).fill(<CardLoading />) :
                                    <>
                                        <Card platform={platforms.custom} install={statusCustom} />
                                    </>}
                            </BoxCards>
                        </Line>
                    </>
                )
        }
    }

    return (
        <Body>
            <Modal />
            <ModalInforVideoShopify visible={visibleModalInforVideoIntgration} setVisibleModal={setVisibleModalInforVideoIntgration} video={"https://www.youtube.com/embed/Ve4aHQ3y1-s"} />
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1" }} >
                <Header />
                <AvaliacaoPopUpProvider>
                    <Contents>
                        {/* {
                            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "shopify" && JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.more_user_data == "" ?
                                <AlertCloseBoss close={true} margin={"0px 0px 20px 0px"} visible={true} >
                                    <label style={{ alignItems: "center", margin: "0px" }} >
                                        Devido algumas modificações na plataforma Shopify é necessario refazer sua integração. <NavInfor onClick={() => setVisibleModalInforVideoIntgration(true)}>Clique aqui</NavInfor> para ver como funciona.
                                    </label>
                                </AlertCloseBoss> : ""
                        } */}

                        {
                            statusPlatforms.loading ?
                                <div style={{ display: "flex", height: "100%", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                    {/* {Array(6).fill(<CardLoading />)} */}
                                    <LoadingLogo />
                                </div> :
                                <>
                                    <Container>
                                        <Filters>
                                            <FilterOption active={filter == "" ? true : false} onClick={() => { setFilter("") }}>{translation.integrations.todos}</FilterOption>
                                            <FilterOption active={filter == "products" ? true : false} onClick={() => { setFilter("products") }}>{translation.integrations.empreender}</FilterOption>
                                            <FilterOption active={filter == "ecommerce" ? true : false} onClick={() => { setFilter("ecommerce") }}>{translation.integrations.plataformas}</FilterOption>
                                            <FilterOption active={filter == "others" ? true : false} onClick={() => { setFilter("others") }}>{translation.integrations.outros}</FilterOption>
                                        </Filters>
                                        {getCards()}
                                    </Container>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <label
                                            style={{ borderRadius: "30px", padding: "15px 25px", border: "1px solid rgb(241, 241, 241)", fontSize: "14px", marginBottom: "20px" }}
                                        >{translation.integrations.naoEncontrou}
                                            <strong onClick={() => { window.open("https://api.whatsapp.com/send?phone=558171033488") }} style={{ marginLeft: "5px", cursor: "pointer" }}>{translation.integrations.cliqueAqui}, </strong>
                                            {translation.integrations.falarSuporte}
                                        </label>
                                    </div>
                                </>
                        }


                    </Contents>
                </AvaliacaoPopUpProvider>
            </div>
            {isVisibleScript(false)}
        </Body >
    )
}

export default Home
