import Modal from 'react-modal';
import { IoIosClose } from "react-icons/io";
import { useDispatch } from 'react-redux';
import { Container } from './styled';
import { useEffect } from 'react';
import { importExcel } from '../../../../utils/import-reviews';

const customStyles = {
    content: {
        //transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px",
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalImportExcel = (props) => {

    const dispatch = useDispatch()

    const closeModal = () => {
        props.setVisibleModal(false);
        dispatch({
            type: "SAGA_LIST_PRODUCTS", payload: {
                search: "",
                perPage: 20,
                page: 1
            }
        })
    }

    const changeInput = (e) => {
        importExcel(e)
    }

    // useEffect(() => {
    //     const script = document.createElement('script');

    //     script.src = "https://unpkg.com/xlsx@0.15.1/dist/xlsx.full.min.js";
    //     script.async = true;

    //     document.body.appendChild(script);

    //     var reader = new FileReader();
    //     reader.readAsArrayBuffer(event.target.files[0]);

    //     reader.onload = function (event) {

    //         var data = new Uint8Array(reader.result);

    //         console.log("data", data)

    //         var work_book = XLSX.read(data, { type: 'array' });

    //         console.log("work_book", work_book)

    //         var sheet_name = work_book.SheetNames;

    //         console.log("sheet_name", sheet_name)

    //         var sheet_data = XLSX.utils.sheet_to_json(work_book.Sheets[sheet_name[0]], { header: 1 });

    //         console.log("sheet_data", sheet_data)
    //     }

    //     excel_file.value = '';

    //     return () => {
    //         document.body.removeChild(script);
    //     }
    // }, []);

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
        //onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <Container>
                <iframe
                    src={`${process.env.REACT_APP_API_URL}/import/excel?productId=${props.item.id}&token=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}`}
                    width={"100%"}
                    height={"100%"}
                />
            </Container>
            <IoIosClose onClick={() => { closeModal() }} style={{ position: "absolute", right: "10", top: "10", cursor: "pointer" }} size={30} color={"gray"} />
        </Modal>
    )
}

export default ModalImportExcel