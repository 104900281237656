
import { useSelector } from 'react-redux';
import { BoxContent, BoxImage, Container, Body, Item, Actions, Close, Title } from "./styled";
import { GrFormClose } from "react-icons/gr";
import imgBackground from "../../../../assets/imgs/preview-background.png";

import { useEffect, useState } from 'react';
import BoxConfigEmail from '../box-config-email';
import IconCam from "../../../../assets/imgs/icon-camera.svg";
import AlertCloseBoss from '../../../../components/alert-close-boss';
import { getTranslation } from '../../../../translations';

const PreviewEmail = (props) => {

  const [optionSelected, setOptionSelected] = useState("reviews")
  const [icon, setIcon] = useState({ size: 20, color: "orange" })

  const configControl = useSelector(state => state.reducerControlConfig)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

  useEffect(() => {
    //console.log("configControl 123 => :", typeof configControl?.logo == "string" || typeof configControl?.logo == "undefined")
    //console.log("Teste props => :", props)
    setIcon({ ...icon, color: configControl.fontColor })
  }, [configControl])

  const isVazio = (data) => {
    if (typeof data == "string") {
      //console.log("if", typeof data == "string")
      if (data.trim() == "") {
        return false
      }
    }
    return true

  }

  return (
    <>
      <Container img={imgBackground}>
        <span style={{ margin: "40px 40px -20px 40px" }}>
          <AlertCloseBoss borderleft={true} background={"#ffffff"} close={true} margin={"0px 0px 20px 0px"} visible={true} >
            <label style={{ color: "#5C0707", alignItems: "center", margin: "0px" }} >
              {translation.thema.formEmail.quandoCliente}
            </label>
            <span style={{}}>
              <label onClick={() => {
                //setVisibleModalInforTema(true) 
              }} style={{ color: "#5C0707", display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                {translation.thema.formEmail.verComo}
              </label>
            </span>
          </AlertCloseBoss>
        </span>


        <Body color={configControl?.color_text} colorBorder={configControl?.color_border} background={configControl?.background_body} >
          <BoxImage active={isVazio(configControl?.request_email_logourl)}>
            <img src={typeof configControl?.request_email_logourl == "string" || typeof configControl?.request_email_logourl == "undefined" ? configControl?.request_email_logourl : URL.createObjectURL(configControl?.request_email_logourl)} />
            <label>LOGO</label>
          </BoxImage>
          <BoxContent font={configControl?.font_email} color={configControl?.color_text_button} background={configControl?.background_button}>
            <label><pre>{configControl?.request_email_content}</pre></label>
            <Item>
              <img width={150} style={{ borderRadius: "5px" }}
                src={IconCam}
              //src='https://imagensemoldes.com.br/wp-content/uploads/2020/07/Cartoon-Baby-Girafa-PNG.png'
              />
              <label style={{ marginTop: "10px" }}><strong>{translation.thema.formEmail.nomeProduto}</strong></label>
              {/* <a href={`https://depoimen.to/preview?star_color=${props.data.star_color}&font_color=${props.data.font_color}&primary_color=${props.data.primary_color}`} target="_blank"><button type='button'>{props.data.textButtom}</button></a> */}
              <button type='button'>{configControl?.request_email_buttontext}</button>
            </Item>
          </BoxContent>
        </Body>

        {/* <FormCustom>
                    
                </FormCustom>
                <ButtonCustom type="submit">Salvar</ButtonCustom> */}
      </Container>
      <BoxConfigEmail />
    </>

  )
}

export default PreviewEmail