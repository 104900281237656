import { BoxCheck, Box, ButtonAction, Tooltip, BoxImgVideo, Container, BoxContents, BoxContact, BoxOptions, BoxContentsLeft, SubTitle, Status, Img, ButtonApproved, ButtonHidden, ButtonDelete, Section, SectionLeft, SectionOptions, BoxActions, ImgTooltip, BoxSvg, NameMobile, ImportReviews, ImportReviewsMenu } from "./styled"
import { AiOutlineImport, AiFillEdit, AiOutlineReload, AiFillPlayCircle, AiFillStar, AiFillClockCircle, AiOutlineCheck, AiFillEyeInvisible, AiOutlineQuestion, AiOutlineDelete } from "react-icons/ai";
import { interval } from "../../../../utils/date";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import ModalDelete from "../modal-delete";
import { useState } from "react";
import { FaInfo } from "react-icons/fa";
import { HiCode } from "react-icons/hi";
import ModalUpdate from "../modal-edit";
import ModalCode from "../modal-code";
import ModalPreviewImg from "../modal-preview";
import Confirmation from "../../../../components/Modal-Confirmation";
import { RiQuestionnaireFill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import { platforms } from "../../../integrations/pages/home/dataCards";
import { FiCameraOff } from "react-icons/fi";
import ModalImportShopee from "../modal-import-shopee";
import ModalImportExcel from "../modal-import-excel";
import LogoShopee from "../../../../assets/imgs/icon-shopee.png";
import IconExcel from "../../../../assets/imgs/icon-excel.png";
import LogoShein from "../../../../assets/imgs/icon-shein.png";
import LogoAliexpress from "../../../../assets/imgs/icon-aliexpress.png";
import ModalListReviews from "../modal-list-reviews";
import ModalListQuestions from "../modal-list-questions";
import { getTranslation } from "../../../../translations";
import { VscFileCode } from "react-icons/vsc";
import ModalCodeHome from "../modal-code-home";

const Card = (props) => {

    //console.log("Card props", props)
    //console.log("platforms", platforms)

    const dispatch = useDispatch()
    const history = useHistory()
    const statusUpdateProduct = useSelector(state => state.reducerUpdateProduct)
    const statusDeleteProduct = useSelector(state => state.reducerDeleteProduct)
    const [visibleModalDelete, setVisibleModalDelete] = useState(false)
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)

    const [visibleModalReviews, setVisibleModalReviews] = useState(false)
    const [visibleModalListReviews, setVisibleModalListReviews] = useState(false)
    const [visibleModalListQuestions, setVisibleModalListQuestions] = useState(false)

    const [visibleModalUpdate, setVisibleModalUpdate] = useState(false)
    const [visibleModalCode, setVisibleModalCode] = useState(false)

    const [visibleModalPreviewImg, setVisibleModalPreviewImg] = useState(false)
    const [visibleModalImportShopee, setVisibleModalImportShopee] = useState(false)
    const [visibleModalImportExcel, setVisibleModalImportExcel] = useState(false)

    const [visibleSubmenu, setVisibleSubmenu] = useState(false)

    const [selectCard, setSelectCard] = useState(false)

    const statusUpdateDataProduct = useSelector(state => state.reducerUpdateDataProduct)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    const colorBorder = (status) => {
        switch (status) {
            case "pending":
                return "#f0ad4e"
            case "approved":
                return "#20D489" //#5cb85c 
            case "hidden":
                return "#0275d8"
            case "new":
                return "#F13F6C"
            default:
                return "gray"
        }
    }


    const getTextStatus = (status) => {
        switch (status) {
            case "pending":
                return translation.products.card.pending
            case "approved":
                return translation.products.card.approved
            case "hidden":
                return translation.products.card.hidden
            case "new":
                return translation.products.card.new
            default:
                return ""
        }
    }

    useEffect(() => {
        if (!(statusUpdateProduct.loading == false && !statusUpdateProduct.error == false && statusUpdateProduct.data.length == 0)) {
            const id = "statusUpdateProduct"
            if (statusUpdateProduct.loading) {
                toast.loading(translation.products.card.guarde, { toastId: id })
            } else {
                if (statusUpdateProduct.error) toast.update(id, { render: translation.products.card.errorAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else toast.update(id, { render: translation.products.card.atualizadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
            }
        }
    }, [statusUpdateProduct])

    useEffect(() => {
        if (!(statusDeleteProduct.loading == false && !statusDeleteProduct.error == false && statusDeleteProduct.data.length == 0)) {
            const id = "statusDeleteProduct"
            if (statusDeleteProduct.loading) {
                toast.loading(translation.products.card.aguarde, { toastId: id })
            } else {
                if (statusDeleteProduct.error) toast.update(id, { render: translation.products.card.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else toast.update(id, { render: translation.products.card.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
            }
        }
    }, [statusDeleteProduct])

    useEffect(() => {
        if (!statusUpdateDataProduct.loading && !statusUpdateDataProduct.error && statusUpdateDataProduct.data.length == 0) {
        } else {
            const id = "statusUpdateDataProduct"
            if (statusUpdateDataProduct.loading) {
                toast.loading(translation.products.card.aguarde, { toastId: id })
            } else {
                if (statusUpdateDataProduct.error) {
                    toast.update(id, { render: translation.products.card.errorSincronizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.products.card.sincronizadoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    // dispatch({
                    //     type: "SAGA_LIST_PRODUCTS", payload: {
                    //         search: "",
                    //         perPage: 10,
                    //         page: 1
                    //     }
                    // })
                }
            }
        }
    }, [statusUpdateDataProduct])


    const updateReview = (status, id) => {

        dispatch({
            type: "SAGA_UPDATE_REVIEWS",
            payload: {
                status: status,
                id: id
            }
        })
    }
    // const deleteReview = (id) => {
    //     dispatch({
    //         type: "SAGA_DELETE_REVIEWS",
    //         payload: {
    //              id: id
    //         }
    //     })
    // }


    const deleteProduct = () => {
        //console.log("Delete")
        dispatch({
            type: "SAGA_DELETE_PRODUCT",
            payload: {
                id: props.item.id
            }
        })
    }

    const handleStatusCard = (checked) => {
        if (checked) {
            dispatch({
                type: "PRODUCTS_CONTROL_SELECT_SET",
                payload: { id: props.item.id }
            })
        } else {
            dispatch({
                type: "PRODUCTS_CONTROL_SELECT_REMOVE",
                payload: { id: props.item.id }
            })
        }
        setSelectCard(checked)
    }

    useEffect(() => { handleStatusCard(props.checkedAll) }, [props.checkedAll])

    const viewQuestions = () => {
        history.push("/perguntas")
        dispatch({ type: "PRODUCT_ADD_SEARCH_QUESTIONS", payload: "$" + props.item.name })
    }

    const viewReviews = () => {
        history.push("/avaliacoes")
        dispatch({ type: "PRODUCT_ADD_SEARCH_REVIEWS", payload: "$" + props.item.name })
    }

    const updateDataProduct = () => {
        dispatch({ type: "SAGA_UPDATE_DATA_PRODUCT", payload: { id: props.item.id } })
    }

    const getActionsProducts = () => {
        if (
            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ||
            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage"
        ) {
            return (
                <>
                    <span>
                        <HiCode size={20} color={"gray"} onClick={() => { setVisibleModalCode(true) }} />
                        <label>{translation.products.card.embedProduto}</label>
                    </span>
                    <span>
                        <AiFillEdit size={20} color={"gray"} onClick={() => { setVisibleModalUpdate(true) }} />
                        <label>{translation.products.card.editarProduto}</label>
                    </span>
                    <span>
                        <AiOutlineDelete size={20} color={"gray"} onClick={() => { setVisibleModalConfirmation(true) }} />
                        <label>{translation.products.card.excluirProduto}</label>
                    </span>
                </>
            )
        }
        else {
            return (
                <>
                    <ImgTooltip>
                        <AiOutlineReload onClick={() => { updateDataProduct() }} style={{ marginRight: "10px", cursor: "pointer" }} size={20} color={"gray"} />
                        <label>{translation.products.card.sincronizarProduto}</label>
                    </ImgTooltip>

                    <ImgTooltip>
                        < img onClick={() => { getLinkProduct() }} src={platforms[JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform]?.image} />
                        <label>{translation.products.card.atualizarProduto}</label>
                    </ImgTooltip>
                </>


            )
        }
    }

    const getLinkProduct = () => {
        switch (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform) {
            case "nuvemshop":
                window.open(`https://${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}/admin/products/${props.item.product_id}/`)
                break;
            case "shopify":
                window.open(`https://${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}/admin/products/${props.item.product_id}/`)
                break;
            case "montink":
                window.open(`https://admin.montink.com/`)
                break;

            default:
                break;
        }
    }

    useEffect(() => {

        // dispatch({
        //     type: "SAGA_LIST_PRODUCTS", payload: {
        //         search: "",
        //         perPage: 20,
        //         page: 1
        //     }
        // })
    }, [visibleModalListReviews])

    const closeMenu = (event) => {
        setVisibleSubmenu(false)
    };

    useEffect(() => {

        if (visibleSubmenu) {
            window.addEventListener('mouseup', closeMenu);
            // cleanup this component
            return () => {
                window.removeEventListener('mouseup', closeMenu);
            };
        }
    }, [visibleSubmenu])

    return (
        <Container>
            <ModalListReviews setControlUpdate={props.setControlUpdate} search={props.item.name} id={props.item.id} visible={visibleModalListReviews} setVisibleModal={setVisibleModalListReviews} />
            <ModalListQuestions setControlUpdate={props.setControlUpdate} search={props.item.name} id={props.item.id} visible={visibleModalListQuestions} setVisibleModal={setVisibleModalListQuestions} />

            <ModalImportShopee visible={visibleModalImportShopee} setVisibleModal={setVisibleModalImportShopee} item={props.item} />
            <ModalImportExcel visible={visibleModalImportExcel} setVisibleModal={setVisibleModalImportExcel} item={props.item} />


            <ModalUpdate visible={visibleModalUpdate} setVisibleModal={setVisibleModalUpdate} item={props.item} />
            <ModalCode visible={visibleModalCode} setVisibleModal={setVisibleModalCode} item={props.item} />
            <ModalPreviewImg visible={visibleModalPreviewImg} setVisibleModal={setVisibleModalPreviewImg} item={props.item} />
            <Confirmation
                confirmation={deleteProduct}
                visible={visibleModalConfirmation}
                setVisibleModal={setVisibleModalConfirmation}
                title={translation.products.card.desejaExcluir}
                infor={translation.products.card.obs} />
            <BoxCheck>
                <input type="checkbox" checked={selectCard} onChange={(e) => { handleStatusCard(e.target.checked) }} />
            </BoxCheck>
            <Section>
                <SectionLeft>

                    {props.item.image_url == null || props?.item?.image_url.length == 0 ?
                        <BoxSvg>
                            <FiCameraOff color={"gray"} />
                        </BoxSvg> :
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <img onClick={() => { setVisibleModalPreviewImg(true) }} src={props?.item?.image_url} />
                            <NameMobile onClick={() => { window.open(props.item?.canonical_url) }}>{props.item?.name}</NameMobile>
                        </div>
                    }
                    <span>
                        {/* <label onClick={() => { window.open(props.item.canonical_url) }}>{props.item.name}</label> */}
                        <label onClick={() => { console.log("canonical_url", props.item?.canonical_url); window.open(props.item?.canonical_url.includes("https://") ? props.item?.canonical_url : `https://${props.item?.canonical_url}`) }}>{props.item?.name}</label>
                        <div>
                            <label
                                //onClick={() => { viewReviews() }}
                                onClick={() => { props.item.total_review_all != 0 && setVisibleModalListReviews(true) }}
                            >
                                {props.item.avg_review.toFixed(1)} <AiFillStar color="ORANGE" size={20} /> {`(${props.item.total_review_all} ${translation.products.card.avaliacoes})`}
                            </label>
                            <label
                                //onClick={() => { viewQuestions() }}
                                onClick={() => { props.item.total_ask != 0 && setVisibleModalListQuestions(true) }}
                            >
                                <RiQuestionnaireFill size={20} /> {props.item.total_ask} {translation.products.card.perguntas}
                            </label>
                            <ImportReviews>
                                <span>
                                    <AiOutlineImport size={20} color={"gray"} />
                                    <label onClick={() => { setVisibleSubmenu(!visibleSubmenu) }}>{translation.MeusProdutos.importarAvaliações}</label> 

                                    <ImportReviewsMenu visible={visibleSubmenu}>
                                        <span onClick={() => { setVisibleModalImportShopee(true) }}>
                                            <img src={LogoShopee} />
                                            <label>shoope</label>
                                        </span>

                                        {/* <span >
                                            <img src={LogoAliexpress} />
                                            <label >Aliexpress</label>
                                        </span>

                                        <span>
                                            <img src={LogoShein} />
                                            <label >Shein</label>
                                        </span> */}


                                        <span onClick={() => { setVisibleModalImportExcel(true) }}>
                                            <img src={IconExcel} />
                                            <label >Excel</label>
                                        </span>

                                    </ImportReviewsMenu>
                                </span>

                            </ImportReviews>
                            {/* <span onClick={() => { setVisibleModalImportShopee(true) }} style={{ display: "flex", flexDirection: "row", }}>
                                <img src={LogoShopee} style={{ width: "25px", height: "25px", marginRight: "0px" }} />
                                <label style={{ marginLeft: "5px" }} onClick={() => { setVisibleModalImportShopee(true) }}>{translation.products.card.importarAvaliacoes}</label>
                            </span> */}
                        </div>
                        <label>{new Date(props.item.created_at).toLocaleDateString('pt-BR')}</label>
                    </span>
                </SectionLeft>
                <BoxActions>
                    {getActionsProducts()}
                </BoxActions>
            </Section>
            {/* <Box>
                <BoxContents color={colorBorder(props.item.status)}>
                    <BoxContentsLeft>
                        <p><a target="_blank" href={props.item.canonical_url}>{props.item.name}</a></p>
                        <SubTitle>
                            <span>
                                <AiFillStar size={20} color={props.item.avg_review > 0 ? "orange" : "gray"} />
                                <AiFillStar size={20} color={props.item.avg_review > 1 ? "orange" : "gray"} />
                                <AiFillStar size={20} color={props.item.avg_review > 2 ? "orange" : "gray"} />
                                <AiFillStar size={20} color={props.item.avg_review > 3 ? "orange" : "gray"} />
                                <AiFillStar size={20} color={props.item.avg_review > 4 ? "orange" : "gray"} />
                            </span>
                        </SubTitle>
                        <label>
                            {props.item.description}
                        </label>
                    </BoxContentsLeft>
                    <div onClick={() => { setVisibleModalReviews(true) }}>
                        {props.item.canonical_url && <Img src={props.item.image_url} />}

                    </div>
                </BoxContents>
                <BoxOptions>
                    <ButtonAction color={"white"} background={"#20D489"} onClick={() => { setVisibleModalCode(true) }}>
                        Embed
                        <span>
                            <HiCode size={15} color={"white"} />
                        </span>
                    </ButtonAction>

                    <ButtonAction color={"white"} background={"#0275d8"} onClick={() => { setVisibleModalUpdate(true) }}>
                        Editar
                        <span>
                            <AiFillEdit size={15} color={"white"} />
                        </span>
                    </ButtonAction>

                    <ButtonAction color={"white"} background={"#F13F6C"} onClick={() => { setVisibleModalConfirmation(true) }}>
                        Excluir
                        <span>
                            <AiOutlineDelete size={15} color={"white"} />
                        </span>
                    </ButtonAction>
                </BoxOptions>
            </Box> */}
        </Container >
    )
}

export default Card
