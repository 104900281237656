
import { useDispatch, useSelector } from 'react-redux';
import { BoxContent, BoxImage, Container, Body, Item, Actions, Close, Title, ContainerPreview, ContainerColumn, ContainerTitle, BoxAlerts } from "./styled";
import { GrFormClose } from "react-icons/gr";
import imgBackground from "../../../../assets/imgs/preview-background.png";

import { useEffect, useState } from 'react';
import BoxConfigEmail from '../box-config-email';
import IconCam from "../../../../assets/imgs/icon-camera.svg";
import AlertCloseBoss from '../../../../components/alert-close-boss';
import { getTranslation } from '../../../../translations';
import { reviews } from "../../pages/home/dataPreview";

import CardDropi from "../card-review-dropi-preview";
import CardLily from "../card-review-lily-preview";
import CardTiktok from '../card-review-tiktok-preview';
import Card2Lily from '../card2-review-lily-preview';
import Card3Lily from '../card3-review-lily-preview';
import Card2Dropi from '../card2-review-dropi-preview';
import Card3Dropi from '../card3-review-dropi-preview';
import { BoxMessage } from '../../../questions/pages/home/styled';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Card1Home from '../card1-home';

const PreviewHome = (props) => {
  const dispatch = useDispatch()
  const [optionSelected, setOptionSelected] = useState("reviews")
  const [icon, setIcon] = useState({ size: 20, color: "orange" })

  const configControl = useSelector(state => state.reducerControlConfig)
  const reviewsFavorites = useSelector(state => state.reducerReviews)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
  const history = useHistory();
  useEffect(() => {
    //console.log("teste configControl 123 home => :", configControl)
    //console.log("Teste props => :", props)
    setIcon({ ...icon, color: configControl.fontColor })
  }, [configControl])


  const velocity = 6 * 25;

  const getCard = (card, item) => {
    //console.log("getCard", card)
    switch (card) {
      case "lily3":
        return <Card3Lily item={item} />
      case "lily2":
        return <Card2Lily item={item} />
      case "lily":
        return <CardLily item={item} />
      case "dropi":
        return <CardDropi item={item} />
      case "dropi2":
        return <Card2Dropi item={item} />
      case "dropi3":
        return <Card3Dropi item={item} />
      case "tiktok":
        return <CardTiktok item={item} />
      case "home1":
        return <Card1Home item={item} />
      default:
        break;
    }
  }

  useEffect(() => {
    //console.log("configcxontrol", configControl)
  }, [configControl])

  useEffect(() => {
    dispatch({
      type: "SAGA_LIST_REVIEWS", payload: {
        rating: "",
        status: "all",
        search: "",
        perPage: 10,
        page: 1,
        medias: "",
        favorite: true
      }
    })
  }, [])

  const selectedMenu = useLocation();

  const visibleFormHome = () => {
    if (selectedMenu.pathname == "/personalizar/home") {
      if (
        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "" &&
        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != null &&
        typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "undefined"
        //&&        reviewsFavorites?.data?.meta?.total > 7
      ) {
        return true
      } else {
        return false
      }
    } else {
      return true
    }

  }

  return (
    <>
      <Container img={imgBackground}>

        {

          visibleFormHome() ?
            <>
              <span style={{ margin: "40px 40px -20px 40px" }}>
                <AlertCloseBoss borderleft={true} background={"#ffffff"} close={true} margin={"0px 0px 20px 0px"} visible={true} >
                  <label style={{ color: "#5C0707", alignItems: "center", margin: "0px" }} >
                    {translation.thema.formHome.inforHomepage}
                  </label>
                  <span style={{}}>
                    <label onClick={() => {
                      //setVisibleModalInforTema(true) 
                    }} style={{ color: "#5C0707", display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                      {translation.thema.formEmail.verComo}
                    </label>
                  </span>
                </AlertCloseBoss>
              </span>
              <Body color={configControl?.color_text} colorBorder={configControl?.color_border} backgroundColor={configControl?.homereviews_background_color} >
                <ContainerTitle align={configControl?.homereviews_align_title} font={configControl?.homereviews_font_title} fontSize={configControl?.homereviews_font_size_title} color={configControl?.homereviews_color_title}>
                  <label>{configControl?.homereviews_title}</label>
                </ContainerTitle>
                <ContainerPreview animation={configControl?.homereviews_animation == 1 ? true : false}>
                  <ContainerColumn animation={configControl?.homereviews_animation == 1 ? true : false} velocity={velocity / 2}>
                    {Array(configControl?.homereviews_animation == 1 ? 5 : 2).fill(getCard(configControl.layout, reviews[0]))}
                  </ContainerColumn>

                  <ContainerColumn animation={configControl?.homereviews_animation == 1 ? true : false} velocity={velocity}>
                    {Array(configControl?.homereviews_animation == 1 ? 5 : 2).fill(getCard(configControl.layout, reviews[0]))}
                  </ContainerColumn>

                  <ContainerColumn animation={configControl?.homereviews_animation == 1 ? true : false} velocity={velocity / 2}>
                    {Array(configControl?.homereviews_animation == 1 ? 5 : 2).fill(getCard(configControl.layout, reviews[0]))}
                  </ContainerColumn>
                </ContainerPreview>
              </Body>
            </> :
            <Container style={{}}>
              <BoxAlerts >
                <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                <span>

                  {
                    JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "" &&
                      JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != null &&
                      typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "undefined" ?
                      null : <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                        {translation.questions.nenhumaIntegracao}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { history.push("/integracoes") }} >{translation.questions.cliqueAqui}. </strong>
                      </label>
                  }

                  {/* {
                    reviewsFavorites?.data?.meta?.total < 8 &&
                    <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                      {translation.thema.minimoReviewsFavoritas}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { history.push("/avaliacoes") }} >{translation.questions.cliqueAqui}. </strong>
                    </label>
                  } */}

                </span>
              </BoxAlerts>

            </Container>
        }

      </Container>
      <BoxConfigEmail />
    </>

  )
}

export default PreviewHome