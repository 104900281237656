import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    background: white;
    //background-color: ${props => props.background} ;
    flex: 1;
    height: 200px;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    box-shadow: 0 0 2em #DCDBDA;
    margin: 20px 20px;
    border-radius: 8px;
    width: 100% !important;
    min-width: 90% !important;
    //background-color: red;
`


export const TextReview = styled.label`
    display: flex;
    //background: red;
    text-align: center;
    justify-content: center;
    margin-bottom: 10px;
    font-style: italic;
    font-weight: 500; 
    font-size: 18px;
    color: ${props => props.fontColor};
`

export const Name = styled.label`
    display: flex;
    //background: red;
    text-align: center;
    justify-content: center;
    font-size: 14px;
    color: ${props => props.fontColor};

`

export const DateInfor = styled.label`
    display: flex;
    //background: red;
    text-align: center;
    justify-content: center;
    font-size: 10px;
    color: ${props => props.fontColor};

`

export const BoxStars = styled.div`
    display: flex;
    //background: red;
    justify-content: center;

`