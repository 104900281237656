import { Container } from './styled'
import { reviews } from "../../pages/home/dataPreview";
import CardDropi from "../card-review-dropi-preview";
import CardLily from "../card-review-lily-preview";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import CardTiktok from '../card-review-tiktok-preview';
import Card2Lily from '../card2-review-lily-preview';
import Card3Lily from '../card3-review-lily-preview';
import Card2Dropi from '../card2-review-dropi-preview';
import Card3Dropi from '../card3-review-dropi-preview';
import Card1Home from '../card1-home';

const BoxReviews = () => {

    const config = useSelector(state => state.reducerControlConfig)

    useEffect(() => {
        //console.log("BoxReviews - config", config)
    }, [config])


    const getCard = (card, item) => {
        switch (card) {
            case "lily3":
                return <Card3Lily item={item} />
            case "lily2":
                return <Card2Lily item={item} />
            case "lily":
                return <CardLily item={item} />
            case "dropi":
                return <CardDropi item={item} />
            case "dropi2":
                return <Card2Dropi item={item} />
            case "dropi3":
                return <Card3Dropi item={item} />
            case "tiktok":
                return <CardTiktok item={item} />
            case "home1":
                return <Card1Home item={item} />
            default:
                break;
        }
    }


    return (
        <Container>
            {reviews.map((item) => {
                return (getCard(config.layout, item))
            })}
        </Container>
    )
}

export default BoxReviews