import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { AlertSave, Box, BoxActions, BoxForm, ButtonDefault, ButtonSave, EditCustom, ErrorMessage, FormCustom, InputCustomItem, InputCustomItemColor, SelectCustom, Tooltips } from './styled';
import { FaRegQuestionCircle } from "react-icons/fa";
import PreviewTheme from "../../components/preview-theme";
import AlertCloseBoss from '../../../../components/alert-close-boss';
import ModalInforVideo from '../../../../components/modal-infor-video';
import { getTranslation } from '../../../../translations';
import IconEdit from '../../../../assets/imgs/icon-edit.svg';
import IconClose from '../../../../assets/imgs/close-white.svg';
import IconBossSave from '../../../../assets/imgs/boss-save.svg';
import { isSave } from '../../../../utils/custom';
import ModalAlertSave from '../modal-alert-save';

const FormTema = () => {

    const [visibleModalInforTema, setVisibleModalInforTema] = useState(false)


    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const edit = useSelector(state => state.reducerControlEdit)
    const dispatch = useDispatch()

    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const controlSaveProductColor = useSelector(state => state.reducerControlSaveProductColor)
    const controlSaveProductConfig = useSelector(state => state.reducerControlSaveProductConfig)



    return (
        <div style={{ width: "100%" }}>
            <ModalInforVideo visible={visibleModalInforTema} setVisibleModal={setVisibleModalInforTema} video={"https://www.youtube.com/embed/1o8_iwGt7NU"} />
            {/* <AlertCloseBoss close={true} margin={"0px 0px 20px 0px"} visible={true} >
                <label style={{ alignItems: "center", margin: "0px" }} >
                    {translation.thema.formTema.aquiVoce}
                </label>
                <span style={{}}>
                    <label onClick={() => { setVisibleModalInforTema(true) }} style={{ display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                        {translation.thema.formTema.verComo}
                    </label>
                </span>
            </AlertCloseBoss> */}
            <FormCustom>
                <Box>
                    <PreviewTheme />
                    {/* <span style={{ position: "absolute", left: "50%", marginLeft: "-60px" }}>
                        <EditCustom onClick={() => { dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "produto/cores" }) }}>
                            <img src={IconEdit} />
                            Editar
                        </EditCustom>
                    </span> */}


                    {
                        !controlSaveProductColor &&
                        <ModalAlertSave>
                            <span>
                                <AlertSave>
                                    <img src={IconBossSave} />
                                    <span>
                                        <label>{translation.thema.alertText}</label>
                                        <div>
                                            <label onClick={() => { dispatch({ type: "SAGA_GET_CONFIG" }) }} >
                                                {translation.thema.alertDescartar}
                                            </label>
                                            <span onClick={() => {

                                                dispatch({
                                                    type: "SAGA_UPDATE_CONFIG",
                                                    payload: {
                                                        id: config.data?.id,
                                                        star_color_primary: configControl.star_color_primary,
                                                        star_color_secondary: configControl.star_color_secondary,
                                                        font_color_primary: configControl.font_color_primary,
                                                        font_color_secondary: configControl.font_color_secondary,
                                                        primary_color: configControl.primary_color,
                                                        background_color_primary: configControl.background_color_primary,
                                                        background_color_secondary: configControl.background_color_secondary,
                                                    }
                                                })
                                            }}
                                            >
                                                {translation.thema.alertSalvar}
                                            </span>
                                        </div>
                                    </span>
                                </AlertSave>
                            </span>
                        </ModalAlertSave>
                    }


                    {
                        !controlSaveProductConfig &&
                        <ModalAlertSave>
                            <span>
                                <AlertSave>

                                    <img src={IconBossSave} />
                                    <span>
                                        <label>{translation.thema.alertText}</label>
                                        <div>
                                            <label onClick={() => {
                                                dispatch({ type: "CONFIG_CONTROL_SET", payload: config.data })
                                                dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_COLOR_SET", payload: true })
                                                dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_CONFIG_SET", payload: true })
                                            }} >
                                                {translation.thema.alertDescartar}
                                            </label>
                                            <span onClick={() => {
                                                //console.log("testssss", configControl, config.data.id)
                                                dispatch({
                                                    type: "SAGA_UPDATE_CONFIG",
                                                    payload: {
                                                        id: config.data?.id,
                                                        no_review_star: configControl.no_review_star,
                                                        visible_button_review: configControl.visible_button_review,
                                                        visible_button_ask: configControl.visible_button_ask,
                                                        visible_date_review: configControl.visible_date_review,
                                                        itens_per_page: configControl.itens_per_page,
                                                        layout: configControl.layout,
                                                        layout_question: configControl.layout_question,
                                                        layout_form: configControl.layout_form,
                                                        layout_form_ask: configControl.layout_form_ask,
                                                        terms_use: configControl.terms_use,
                                                        font: configControl.font,
                                                        order_reviews: configControl.order_review,
                                                        order_asks: configControl.order_asks,
                                                        layout_stars: configControl.layout_stars
                                                    }
                                                })
                                            }}
                                            >
                                                {translation.thema.alertSalvar}
                                            </span>
                                        </div>
                                    </span>
                                    {/* <img style={{ width: "7px", height: "7px", cursor: "pointer" }} src={IconClose} /> */}
                                </AlertSave>
                            </span>
                        </ModalAlertSave>
                    }
                </Box>

                {/* {
                    isSave(config, configControl) && edit != "produto/cores" ?
                        <span style={{ position: "absolute", left: "50%", marginLeft: "-60px" }}>
                            <EditCustom onClick={() => { dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "produto/cores" }) }}>
                                <img src={IconEdit} />
                                Editar
                            </EditCustom>
                        </span> :
                        !isSave(config, configControl) &&
                        <span style={{ position: "absolute", left: "50%", marginLeft: "-350px" }}>
                            <AlertSave>
                                <img src={IconBossSave} />
                                <span>
                                    <label>{translation.thema.alertText}</label>
                                    <div>
                                        <label onClick={() => { dispatch({ type: "SAGA_GET_CONFIG" }) }} >
                                            {translation.thema.alertDescartar}
                                        </label>
                                        <span onClick={() => {
                                            console.log("testssss", configControl, config.data.id)
                                            dispatch({
                                                type: "SAGA_UPDATE_CONFIG",
                                                payload: {
                                                    id: config.data?.id,
                                                    star_color_primary: configControl.starColorPrimary,
                                                    star_color_secondary: configControl.starColorSecondary,
                                                    font_color_primary: configControl.fontColorPrimary,
                                                    font_color_secondary: configControl.fontColorSecondary,
                                                    primary_color: configControl.primaryColor,
                                                    background_color_primary: configControl.background_color_primary,
                                                    background_color_secondary: configControl.background_color_secondary,
                                                    theme: configControl.theme,

                                                    no_review_star: configControl.no_review_star,
                                                    visible_button_review: configControl.visibleButtonReview,
                                                    visible_button_ask: configControl.visibleButtonAsk,
                                                    visible_date_review: configControl.visibleDateReview,
                                                    itens_per_page: configControl.itens_per_page,
                                                    layout: configControl.layout,
                                                    layout_question: configControl.layoutQuestion,
                                                    layout_form: configControl.layout_form,
                                                    layout_form_ask: configControl.layout_form_ask,

                                                    font: configControl.font,
                                                    order_reviews: configControl.order_reviews,
                                                    order_asks: configControl.order_ask,
                                                    terms_use: configControl.terms_use,
                                                    layout_stars: configControl.layout_stars
                                                }
                                            })
                                        }}
                                        >
                                            {translation.thema.alertSalvar}
                                        </span>
                                    </div>
                                </span>
                                
                            </AlertSave>
                        </span>
                } */}




            </FormCustom>
            <div style={{ display: "flex", justifyContent: "center", }}>
                <label
                    style={{ borderRadius: "30px", padding: "15px 25px", border: "1px solid rgb(241, 241, 241)", fontSize: "14px", marginBottom: "20px" }}
                >{translation.thema.formTema.problemas}
                    <strong onClick={() => { window.open("https://api.whatsapp.com/send?phone=558171033488") }} style={{ marginLeft: "5px", cursor: "pointer" }}>{translation.thema.formTema.cliqueAqui}, </strong>
                </label>
            </div>
        </div >

    )

}
export default FormTema