import styled from "styled-components";


export const Container = styled.div`
    //background-color: yellow ;
    display: flex ;
    width: 100% ;
    flex-direction: column ;
    padding: 0px 20px;
    gap: 10px;
`

export const Title = styled.label`
    //background-color: blueviolet ;
    margin-bottom: 0px ;
    display: flex ;
    align-items: center ;

    label{
        display: flex ;
        flex: 1 ;
        //background-color: green ;
        justify-content: left ;
        margin-left: 5px;
        font-size: 12px;
        color: #000 ;
        cursor: pointer;
        
    }

    span{
        //background-color: red;
        cursor: pointer;
    }
`

export const Subtitle = styled.div`
    //background-color: red ;
    font-size: 10px;
`


export const BoxOptions = styled.div`
    //background-color: red ;
    flex-direction: column ;
    display: flex ;
    gap: 10px;
    border: 1px solid #D3DAE3;
    padding: 10px ;
    border-radius: 4px;
    width: calc(100% - 10px);

    label{
        font-size: 10px ;
    }
`

export const ButtonHelp = styled.div`
    //background-color: red ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 48px ;
    border-radius: 4px ;
    justify-content: center ;
    //border: 1px dashed #0D6EFD;
    cursor: pointer;
    background-color: #121212 ;
    width: calc(100% - 10px);

    label{
        font-size: 12px ;
        cursor: pointer;
        color: #fff ; 
    }

    img{
        width: 44px ;
        height: 44px ;
        cursor: pointer;
    }
`

export const ButtonSave = styled.div`
    background-color: #00803B  ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 36px ;
    border-radius: 4px ;
    justify-content: center ;
    font-size: 12px ;
    color: #fff ;
    cursor: pointer;
    flex: 1;
`

export const ButtonReset = styled.div`
    //background-color: red ;
    display: flex ;
    align-items: center ;
    gap: 10px;
    height: 36px ;
    border-radius: 4px ;
    justify-content: center ;
    border: 1px solid #D3DAE3;
    cursor: pointer;
    flex: 1;

    label{
        font-size: 12px ;
        cursor: pointer;
        color: #000 ;
    }

    img{
        width: 14px ;
        height: 16px ;
    }

`
export const BoxButtons = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: calc(100% - 10px);
    //background-color: red;
` 

export const SelectCustom = styled.select`
    display: flex;
    background-color: #F5F3F0;
    height: 34px;
    width: 100% !important;
    font-weight: 600;
    color: #353535;
    border-width: 1px 1px 1px 0px;
    border-color: #EFF2F5;
    border-style: solid;
    border-radius: 0px 5px 5px 0px;
    padding: 0px 10px 0px 5px;
    outline: none;
    font-size: 12px;
    cursor: pointer;

    @media(min-width: 480px) {
        width: 280px;
    }
`

export const ItemSelect = styled.div`
    //background-color:  yellow;
    display: flex ;
    flex-direction: column ;

    label{
        font-size: 10px ;
    }
`