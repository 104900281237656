
import Modal from 'react-modal';
import { Container, Close, Title, Infor, ButtonCustom } from "./styled";
import { GrFormClose } from "react-icons/gr";
import { IoIosClose } from "react-icons/io";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const ModalConfirmation = (props) => {

    //console.log("PROPS MODAL", props)

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => props.setVisibleModal(false)}
        >
            <Container>
                <Close
                    onClick={() => {
                        props.setVisibleModal(false)
                        //alert("teste")
                        //console.log("props", props)
                    }}
                ><IoIosClose size={25} color={"#5e6278"} /></Close>
                <Title>
                    <strong>{props.title ? props.title : "Deseja excluir esse item?"}</strong>
                </Title>
                {props.infor && <Infor>{props.infor}</Infor>}
                <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                    <ButtonCustom onClick={() => { props.confirmation() }} >
                        {props.textButtom == undefined ? "Confirmar" : props.textButtom}
                    </ButtonCustom>
                </div>
            </Container>
        </Modal>
    )
}

export default ModalConfirmation
