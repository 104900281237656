import { Container, PaginateInfor, PaginateNav, Filters, BoxLoading, Search, Infor, ListCard, Paginate, ActionsSelect, ActionsSelectButton, ButtonCustom, BoxInforInitial, BoxInforInitialLeft, BoxInforInitialRight, ContainerInfor, BoxMessage } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import { AiOutlineSearch, AiOutlineLoading, AiFillCheckSquare, AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { useState } from "react";
import Card from "../../components/card";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { displayScript, isVisibleScript } from "../../../../utils/displayScript";
import { useHistory } from "react-router-dom";
import { AiOutlineCheck, AiFillEyeInvisible, AiOutlineDelete, AiFillEye } from "react-icons/ai";
import { toast } from "react-toastify";
import CardLoading from "../../components/card-loading";
import ModalInforVideo from "../../../../components/modal-infor-video";
import ModalInforVideoIntegration from "../../../../components/modal-infor-video";
import ModalInforVideoClean from "../../../../components/modal-infor-video";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import { ActionsDropdown } from "../../../reviews/pages/home/styled";
import { IoIosArrowDown, IoMdTrash } from "react-icons/io";
import { HiBadgeCheck, HiOutlineBadgeCheck } from "react-icons/hi";
import { getTranslation } from "../../../../translations";
import LoadingLogo from "../../../../components/Loading-logo";

const Home = () => {

    const [search, setSearch] = useState("")
    const [statusQuestion, setStatusQuestion] = useState("all")
    const questions = useSelector(state => state.reducerQuestions)
    const ProductSearchQuestions = useSelector(state => state.reducerSearchQuestionProduct)
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const [checkedAll, setCheckedAll] = useState(false)

    const [visibleModalInforQuestions, setVisibleModalInforQuestions] = useState(false)
    const [visibleModalInforQuestionsIntegration, setVisibleModalInforQuestionsIntegration] = useState(false)
    const [visibleModalInforQuestionsClean, setVisibleModalInforQuestionsClean] = useState(false)

    const dispatch = useDispatch()
    const history = useHistory();

    const statusUpdateQuestion = useSelector(state => state.reducerUpdateQuestion)
    const statusDeleteQuestion = useSelector(state => state.reducerDeleteQuestion)
    const statusAddReplies = useSelector(state => state.reducerAddReplies)
    const statusUpdateReplies = useSelector(state => state.reducerUpdateReplies)
    const questionsSelect = useSelector(state => state.reducerControlSelectQuestions)
    const statusDeleteSelectQuestions = useSelector(state => state.reducerDeleteSelectQuestions)
    const statusUpdateSelectQuestions = useSelector(state => state.reducerUpdateSelectQuestions)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        if (!statusUpdateReplies.loading && !statusUpdateReplies.error && statusUpdateReplies.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_QUESTIONS", payload: {
                    status: statusQuestion,
                    search: search,
                    perPage: perPage,
                    page: page,
                }
            })
        }
    }, [statusUpdateReplies])

    useEffect(() => {
        if (!statusUpdateQuestion.loading && !statusUpdateQuestion.error && statusUpdateQuestion.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_QUESTIONS", payload: {
                    status: statusQuestion,
                    search: search,
                    perPage: perPage,
                    page: page,
                }
            })
        }
    }, [statusUpdateQuestion])

    useEffect(() => {
        if (!statusDeleteQuestion.loading && !statusDeleteQuestion.error && statusDeleteQuestion.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_QUESTIONS", payload: {
                    status: statusQuestion,
                    search: search,
                    perPage: perPage,
                    page: page,
                }
            })
        }
    }, [statusDeleteQuestion])

    useEffect(() => {
        if (!statusAddReplies.loading && !statusAddReplies.error && statusAddReplies.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_QUESTIONS", payload: {
                    status: statusQuestion,
                    search: search,
                    perPage: perPage,
                    page: page,
                }
            })
        }
    }, [statusAddReplies])

    useEffect(() => {
        if (typeof search !== "undefined" || typeof statusQuestion !== "undefined") {
            dispatch({
                type: "SAGA_LIST_QUESTIONS", payload: {
                    status: statusQuestion,
                    search: search,
                    perPage: perPage,
                    page: page,
                }
            })
        }
    }, [page, statusQuestion])

    // useEffect(() => {
    //     if (!questions.loading && !questions.error && questions.data.length == 0) {

    //     } else {
    //         dispatch({
    //             type: "SAGA_LIST_QUESTIONS", payload: {
    //                 status: statusQuestion,
    //                 search: search,
    //                 perPage: perPage,
    //                 page: page,
    //             }
    //         })
    //     }
    // }, [statusQuestion])

    const goSearch = () => {
        dispatch({
            type: "SAGA_LIST_QUESTIONS", payload: {
                status: statusQuestion,
                search: search,
                perPage: perPage,
                page: page,
            }
        })
    }

    useEffect(() => {
        if (ProductSearchQuestions[0] === "$") {
            setSearch(ProductSearchQuestions.split("$")[1],)
            dispatch({
                type: "SAGA_LIST_QUESTIONS", payload: {
                    status: statusQuestion,
                    search: ProductSearchQuestions.split("$")[1],
                    perPage: perPage,
                    page: page,
                }
            })
            dispatch({ type: "PRODUCT_ADD_SEARCH_QUESTIONS", payload: "" })
        }

    }, [ProductSearchQuestions])


    // useEffect(() => {
    //     if (!questions.loading && !questions.error && questions.data.length == 0) {
    //     } else {
    //         goSearch()
    //     }       
    // }, [page])

    const countItens = () => {
        //console.log("QUESTIONS DATA", questions.data)
        let result = 0
        if (page == questions.data?.meta?.last_page) result = questions.data?.meta?.total
        else result = page * perPage
        return result
    }

    const messageClear = () => {
        const integration = JSON.parse(localStorage.getItem("depoimentos@login"))
        //console.log("teste: ", integration.integration)
        if (integration.integration) {
            if (integration.integration == null) {
                return <label>{translation.questions.nenhumaPergunta}, <strong onClick={() => { history.push("/integracoes") }} >{translation.questions.cliqueAqui} </strong>,  {translation.questions.integrarConta}</label>
            } else {
                return <label>{translation.questions.nenhumaPergunta}.</label>
            }
        } else {
            return <label>{translation.questions.nenhumaPergunta}, <strong onClick={() => { history.push("/integracoes") }} >{translation.questions.cliqueAqui} </strong>,  {translation.questions.integrarConta}</label>
        }

    }

    useEffect(() => {
        if (!statusUpdateSelectQuestions.loading && !statusUpdateSelectQuestions.error && statusUpdateSelectQuestions.data.length == 0) {
        } else {
            const id = "statusUpdateSelectQuestions"
            if (statusUpdateSelectQuestions.loading) {
                toast.loading(translation.questions.aguarde, { toastId: id })
            } else {
                if (statusUpdateSelectQuestions.error) {
                    toast.update(id, { render: translation.questions.erroOperacao, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.questions.operacaoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //setVisibleModalConfirmation(false)
                    handleChecked(false)
                    goSearch()
                }
            }
        }
    }, [statusUpdateSelectQuestions])

    useEffect(() => {
        if (!statusDeleteSelectQuestions.loading && !statusDeleteSelectQuestions.error && statusDeleteSelectQuestions.data.length == 0) {
        } else {
            const id = "statusDeleteSelectQuestions"
            if (statusDeleteSelectQuestions.loading) {
                toast.loading(translation.questions.aguarde, { toastId: id })
            } else {
                if (statusDeleteSelectQuestions.error) {
                    toast.update(id, { render: translation.questions.erroExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.questions.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //setVisibleModalConfirmation(false)
                    handleChecked(false)
                    goSearch()
                }
            }
        }
    }, [statusDeleteSelectQuestions])

    const updateAll = (action) => {
        if (action == "hidden") {
            for (let iterator of questionsSelect) {
                //console.log("iterator: ", iterator)
                iterator.status = "hidden"
            }
        }

        dispatch({
            type: "SAGA_UPDATE_SELECT_QUESTION",
            payload: {
                itens: questionsSelect,
            }
        })
    }


    const deleteAll = () => {
        //if (selectReviews.length > 0) dispatch({ type: "SAGA_DELETE_SELECT_REVIEWS", payload: { itens: selectReviews } })
        dispatch({
            type: "SAGA_DELETE_SELECT_QUESTION",
            payload: {
                itens: questionsSelect
            }
        })
    }

    const handleChecked = (checked) => {
        setCheckedAll(checked)
    }

    useEffect(() => {

    }, [questionsSelect])

    const [visibleMenuActions, setVisibleMenuActions] = useState(false)

    useEffect(() => {

        if (visibleMenuActions) {
            window.addEventListener('mouseup', closeMenuActions);
            // cleanup this component
            return () => {
                window.removeEventListener('mouseup', closeMenuActions);
            };
        }
    }, [visibleMenuActions])

    const closeMenuActions = (event) => {
        if (visibleMenuActions) setVisibleMenuActions(false)
    };

    return (
        <Body>
            <ModalInforVideoIntegration visible={visibleModalInforQuestionsIntegration} setVisibleModal={setVisibleModalInforQuestionsIntegration} video={"https://www.youtube.com/embed/Ve4aHQ3y1-s"} />
            <ModalInforVideoClean visible={visibleModalInforQuestionsClean} setVisibleModal={setVisibleModalInforQuestionsClean} video={"https://www.youtube.com/embed/AAitCE51W40"} />
            <ModalInforVideo visible={visibleModalInforQuestions} setVisibleModal={setVisibleModalInforQuestions} video={"https://www.youtube.com/embed/AAitCE51W40"} />
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1" }} >
                <Header />
                <Contents>
                    <Container>
                        {questions.loading ?
                            // <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            //     {Array(5).fill(<CardLoading />)}
                            // </div>
                            <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                {/* {Array(6).fill(<CardLoading />)} */}
                                <LoadingLogo />
                            </div>
                            :
                            typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == "undefined" || JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
                                <ContainerInfor>
                                    <BoxMessage>
                                        <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                        <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                            {translation.questions.nenhumaIntegracao}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { history.push("/integracoes") }} >{translation.questions.cliqueAqui}. </strong>
                                            {/* {translation.questions.comoFunciona} */}
                                        </label>
                                    </BoxMessage>
                                </ContainerInfor> :
                                questions.data?.default == true && questions.data?.data.length == 0 ?
                                    <BoxMessage>
                                        <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                        <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                            {translation.questions.naoPossui}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { setVisibleModalInforQuestionsClean(true) }} >{translation.questions.cliqueAqui}. </strong>
                                            {/* {translation.questions.comoFunciona} */}
                                        </label>
                                    </BoxMessage> :
                                    <>
                                        <AlertCloseBoss close={true} margin={"0px 0px 20px 0px"} visible={true} >
                                            <label style={{ alignItems: "center", margin: "0px" }} >
                                                {translation.questions.respondaDuvidas}
                                            </label>
                                            <span style={{}}>
                                                <label onClick={() => { setVisibleModalInforQuestions(true) }} style={{ display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                                                    {translation.questions.verComo}
                                                </label>
                                            </span>
                                        </AlertCloseBoss>
                                        <Filters>
                                            <Search  >
                                                <input
                                                    type={"text"}
                                                    placeholder={translation.questions.pesquisar}
                                                    value={search}
                                                    onChange={(e) => { setSearch(e.target.value) }} />
                                                <span onClick={() => { setPage(1); goSearch() }}>
                                                    <AiOutlineSearch size={25} color={"gray"} />
                                                </span>
                                            </Search>
                                            <select value={statusQuestion} onChange={(e) => { setStatusQuestion(e.target.value) }} >
                                                <option value="all" >{translation.questions.todos}</option>
                                                <option value="" >{translation.questions.respondidosPendentes}</option>
                                                <option value="answered" >{translation.questions.respondidos}</option>
                                                <option value="pending" >{translation.questions.pendentes}</option>
                                                <option value="hidden" >{translation.questions.escondidos}</option>
                                            </select>
                                        </Filters>
                                        {
                                            questions?.data?.data?.length != 0 ?
                                                <>
                                                    <ActionsSelect >
                                                        <span>
                                                            <input type="checkbox" checked={checkedAll} onChange={(e) => { handleChecked(e.target.checked) }} />
                                                        </span>
                                                        <label style={{ fontSize: "14px" }}>{translation.questions.selecionarTodos}</label>
                                                        <ActionsDropdown visible={visibleMenuActions} onClick={() => { setVisibleMenuActions(true) }}>
                                                            <label>{translation.questions.acoes}</label>
                                                            <IoIosArrowDown size={15} color={"gray"} />
                                                            <div>
                                                                <span onClick={() => { questionsSelect.length > 0 ? updateAll("back") : toast.warning(translation.questions.nenhumItem) }}>
                                                                    <AiFillEye size={15} color={"#0275d8"} />
                                                                    <label>{translation.questions.tornarVisivel}</label>
                                                                </span>
                                                                <span onClick={() => { questionsSelect.length > 0 ? updateAll("hidden") : toast.warning(translation.questions.nenhumItem) }}>
                                                                    <AiFillEyeInvisible size={15} color={"#0275d8"} />
                                                                    <label>{translation.questions.tornarInvisivel}</label>
                                                                </span>
                                                                <span onClick={() => { questionsSelect.length > 0 ? deleteAll() : toast.warning(translation.questions.nenhumItem) }}>
                                                                    <IoMdTrash size={15} color={"#F13F6C"} />
                                                                    <label>{translation.questions.excluir}</label>
                                                                </span>
                                                            </div>
                                                        </ActionsDropdown>
                                                        {/* <ActionsSelectButton>
                                                            <span>
                                                                <AiFillEyeInvisible onClick={() => { questionsSelect.length > 0 ? updateAll("hidden") : toast.warning(translation.questions.nenhumItem) }} size={20} color={"gray"} />
                                                                <label>Deseja tornar invisível todas as perguntas selecionadas?</label>
                                                            </span>
                                                            <span>
                                                                <AiFillEye onClick={() => { questionsSelect.length > 0 ? updateAll("back") : toast.warning(translation.questions.nenhumItem) }} size={20} color={"gray"} />
                                                                <label>Deseja tornar visível todas as perguntas selecionadas?</label>
                                                            </span>
                                                            <span>
                                                                <AiOutlineDelete onClick={() => { questionsSelect.length > 0 ? deleteAll() : toast.warning(translation.questions.nenhumItem) }} size={20} color={"gray"} />
                                                                <label>Deseja excluir todas as perguntas selecionadas?</label>
                                                            </span>
                                                        </ActionsSelectButton> */}
                                                    </ActionsSelect>
                                                    <ListCard>
                                                        {questions.data.data?.map((item) => { return <Card key={item.id} checkedAll={checkedAll} item={item} /> })}
                                                        <Paginate>
                                                            <PaginateNav>
                                                                <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                                                    <MdKeyboardArrowLeft size={35} color={questions.data?.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                                                </span>
                                                                <label>{questions.data?.meta?.current_page}</label>
                                                                <span onClick={() => { if (page < questions.data?.meta?.last_page) setPage(page + 1) }}>
                                                                    <MdKeyboardArrowRight size={35} color={questions.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                                                </span>
                                                            </PaginateNav>
                                                            <PaginateInfor>
                                                                <label>{translation.questions.total} {countItens()} - {questions.data?.meta?.total}</label>
                                                            </PaginateInfor>
                                                        </Paginate>
                                                    </ListCard>
                                                </> :
                                                <label style={{ marginTop: "50px", display: "flex", flex: "1", justifyContent: "center" }} >{translation.questions.nenhumaPergunta}.</label>
                                        }

                                    </>

                        }

                    </Container>
                </Contents>
            </div>
            {isVisibleScript(false)}
        </Body>
    )
}

export default Home
