
import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.headers.common['Accept'] = 'application/json' // for all requests
const url = process.env.REACT_APP_API_URL
const axiosConfig = {
    baseURL: url
};

const axiosConfigMain = {
    baseURL: "https://api-lily.herokuapp.com"
};

if(process.env.REACT_APP_HABILITAR_PLUS) {
    axiosConfig.withCredentials = true;
}
//baseURL: "https://api-depoimento.herokuapp.com" 
const api = axios.create(axiosConfig);
const apiMain = axios.create(axiosConfigMain);


export const setupInterceptors = history => {
    api.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error    

        if (error.response?.status === 402) { // USUÁRIO PRECISA RENOVAR O PLANO
            toast.error(error.response.data.description, { toastId: 7634534 })
            history.replace('/pagamentos');
        }
        
        if (error.response?.status === 401) { // USUÁRIO NÃO AUTENTICADO
            localStorage.removeItem("depoimentosAdmin@login");
            localStorage.removeItem("depoimentos@login");
            history.replace("/login")
        }

        return Promise.reject(error);
    });
}

export const POST = (data) => {
    //console.log("POST DATA", data)
    return (
        api.post(data.endpoint, data.data, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
            }
        }).then(res => {
            //console.log("RES POST", res)
            return res
        }).catch(error => {
            //console.log("Error - POST - response", error.response)
            //console.log("Error - POST", error)
            return false
        })
    )
}

export const POST_ADMIN = (data) => {
    //console.log("POST DATA", data)
    return (
        api.post(data.endpoint, data.data, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentosAdmin@login'))?.access_token.token
            }
        }).then(res => {
            //console.log("RES POST", res)
            return res
        }).catch(error => {
            //console.log("Error - POST - response", error.response)
            //console.log("Error - POST", error)
            return false
        })
    )
}

export const PUT_ADMIN = (data) => {
    //console.log("POST DATA", data)
    return (
        api.put(data.endpoint, data.data, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentosAdmin@login'))?.access_token.token
            }
        }).then(res => {
            //console.log("RES POST", res)
            return res
        }).catch(error => {
            //console.log("Error - POST - response", error.response)
            //console.log("Error - POST", error)
            return false
        })
    )
}

export const POST_ADMIN_CUSTOM = (data) => {
    //console.log("POST DATA", data)
    return (
        api.post(data.endpoint, data.data, {
            headers: {
                Authentication: `Bearer ${data.token}`
            }
        }).then(res => {
            //console.log("RES POST", res)
            return res
        }).catch(error => {
            //console.log("Error - POST - response", error.response)
            //console.log("Error - POST", error)
            return false
        })
    )
}

export const POST_ERROR = (data) => {
    return (
        api.post(data.endpoint, data.data, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentos@login')).access_token.token
            }
        }).then(res => {
            //console.log("res")
            //console.log(res)
            return res
        }).catch(error => {
            //console.log("error")
            //console.log(error)
            return error.response
        })
    )
}

export const GET = (data) => {

    //console.log("GET - data", data)
    return (
        api.get(data.endpoint, {
            headers: {
                Authorization: "Bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token?.token
            }
        }).then(res => {
            //console.log("GET - res", res)
            return res
        }).catch(error => {
            return false
        })
    )
}

export const GET_ADMIN = (data) => {

    //console.log("GET - data", data)
    return (
        api.get(data.endpoint, {
            headers: {
                Authorization: "Bearer " + JSON.parse(localStorage.getItem('depoimentosAdmin@login'))?.access_token.token
            }
        }).then(res => {
            //console.log("GET - res", res)
            return res
        }).catch(error => {
            return false
        })
    )
}

export const GET_ADMIN_CUSTOM1 = (data) => {

    //console.log("GET - data", data)
    return (
        api.get(data.endpoint, {
            headers: {
                Authorization: "Bearer " + JSON.parse(localStorage.getItem('depoimentosAdmin@login'))?.access_token.token
            }
        }).then(res => {
            //console.log("GET - res", res)
            return res
        }).catch(error => {
            return false
        })
    )
}

export const PUT = (data) => {
    //console.log("DATA  - put:", data)
    return (
        api.put(data.endpoint, data.data, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
            }
        }).then(res => {
            //console.log("res - put:", res)
            return res
        }).catch(error => {
            //console.log("error - put:", error)
            return false
        })
    )
}

export const DELETE = (data) => {

    return (
        api.delete(data.endpoint, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
            }
        }).then(res => {
            //console.log("DELET RES", res)
            return res
        }).catch(error => {
            //console.log("DELETE Error", error)
            return false
        })
    )
}
export const DELETE_CUSTOM = (data) => {
    //console.log("data delete", data.data)
    //console.log("Token", "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token)
    return (
        api.delete(data.endpoint, {
            headers: {
                Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
            }, data: data.data
        }).then(res => {
            //console.log("RES POST", res)
            return res
        }).catch(error => {
            //console.log("Error - POST - response", error.response)
            //console.log("Error - POST", error)
            return false
        })
    )
}

export const API_LOGAR = (data) => {
    return (
        api.post(process.env.REACT_APP_HABILITAR_PLUS ? "/eplus/auth/logar" : "/login", data,)
            .then(res => {
                if (res.status === 200) return res.data
            }).catch(error => {
                return false
            })
    )
}

export const API_CONCLUIR_LOGIN_PLUS = (data) => {
    return (
        api.get("/eplus/auth/link-magico", { params: data })
            .then(res => {
                if (res.status === 200) return res.data
            }).catch(error => {
                return false
            })
    )
}

export const API_REGISTER = (data) => {
    return (
        api.post(process.env.REACT_APP_HABILITAR_PLUS ? "/eplus/auth/cadastrar" : "/register", data,)
            .then(res => {
                if (res.status === 201) return { error: false, data: res.data }
            }).catch(error => {
                return { error: true, data: error.response.data }
            })
    )
}

export const API_PASSWORD = (data) => {
    return (
        api.post(process.env.REACT_APP_HABILITAR_PLUS ? "/eplus/auth/recupera-senha" : "/forgotPassword", data,)
            .then(res => {
                if (res.status === 200) return res.data
            }).catch(error => {
                return false
            })
    )
}


// export const PUT_CUSTOM2 = (data) => {
//     const response = await fetch("https://api-depoimento.herokuapp.com/reviews/" + data.data.id, {
//         method: 'PUT',
//         body: data,
//         headers: {
//             Authorization: "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
//         }
//     }).then(function (response) {
//         console.log("THEN",response);
//         return response
//     }).catch(function (response) {
//         console.log("cath",response);
//         return response
//     });
// }

export const PUT_CUSTOM = async (data) => {
    //console.log("put - data", data.data)
    let formData = new FormData();
    for (const name in data.data) {
        formData.append(name, data.data[name]);
    }

    try {

        //console.log("TESTE:", formData)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/configurations/` + data.data.id, {
            method: 'PUT',
            body: formData,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
            }
        })

        let result = await response.json()
        //console.log("TESTANDO", result)
        return result
    } catch (error) {
        //console.log("TESTANDO error", error)
        return false
    }

}


export const POST_CUSTOM = async (data) => {
    //console.log("post - data", data.data)
    let formData = new FormData();
    for (const name in data.data) {
        formData.append(name, data.data[name]);
    }

    try {

        //console.log("TESTE:", formData)
        const response = await fetch(`${process.env.REACT_APP_API_URL}/emailpreview/`, {
            method: 'POST',
            body: formData,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Authorization': "bearer " + JSON.parse(localStorage.getItem('depoimentos@login'))?.access_token.token
            }
        })

        let result = await response.json()
        //console.log("TESTANDO", result)
        return result
    } catch (error) {
        //console.log("TESTANDO error", error)
        return false
    }

}