import React from 'react'
import { BoxStars, Container, DateInfor, Name, TextReview } from './styled'
import { AiFillStar } from "react-icons/ai";
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

const Card1Home = (props) => {
    //console.log("props card01", props)
    const config = useSelector(state => state.reducerControlConfig)
    const [icon, setIcon] = useState({ size: 20, color: "orange" })


    useEffect(() => {
        setIcon({ ...icon, colorActive: config.star_color_primary, colorInative: config.star_color_secondary })
    }, [config])

    useEffect(() => {
        //console.log("config Card 01 home =>", config)
    }, [config])

    return (
        <Container background={config.background_color_secondary} colorBorder={config.primary_color}>
            <TextReview fontColor={config.font_color_primary}>
                "{props.item.text}"
            </TextReview>
            <BoxStars>
                {/* <AiFillStar size={icon.size} color={icon.color} />
                <AiFillStar size={icon.size} color={icon.color} />
                <AiFillStar size={icon.size} color={icon.color} />
                <AiFillStar size={icon.size} color={icon.color} />
                <AiFillStar size={icon.size} color={icon.color} /> */}
                <AiFillStar size={icon.size} color={props.item.qtdStars > 0 ? icon.colorActive : icon.colorInative} />
                <AiFillStar size={icon.size} color={props.item.qtdStars > 1 ? icon.colorActive : icon.colorInative} />
                <AiFillStar size={icon.size} color={props.item.qtdStars > 2 ? icon.colorActive : icon.colorInative} />
                <AiFillStar size={icon.size} color={props.item.qtdStars > 3 ? icon.colorActive : icon.colorInative} />
                <AiFillStar size={icon.size} color={props.item.qtdStars > 4 ? icon.colorActive : icon.colorInative} />
            </BoxStars>
            <Name fontColor={config.font_color_primary}>
                {props.item?.name} Silva
            </Name>
            <DateInfor fontColor={config.font_color_primary}>
                {config.visible_date_review == "1" && "01/12/2023"}
            </DateInfor>
        </Container>
    )
}

export default Card1Home