import { put, call, } from "redux-saga/effects";
import { POST } from "../../../services/api";



export function* getTokenNuvemshop(action) {

  

    yield put({ type: "NUVEMSHOP_TOKEN_REQUEST", })
    //yield delay(200);
    const result = yield call(POST, { endpoint: `/integrations/nuvemshop`, data: action.payload })
    if (result === false) {
        yield put({ type: "NUVEMSHOP_TOKEN_ERROR" })
    } else {
        yield put({ type: "NUVEMSHOP_TOKEN_SUCCESS", payload: result.data })
        //console.log("result", result)
        localStorage.setItem('nuvemshop@code', JSON.stringify(result.data.data))
    }
}

