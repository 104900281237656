import { combineReducers } from "redux";

import reducerLogin from "../modules/login/redux/reducers/reducerLogin";
import reducerRegister from "../modules/login/redux/reducers/reducerRegister";
import reducerPassword from "../modules/login/redux/reducers/reducerPassword";
import reducerControlLanguage from "../modules/login/redux/reducers/reducerControlLanguage";

import reducerIntegration from "../modules/integrations/redux/reducers/reducerIntegration";
import reducerSync from "../modules/integrations/redux/reducers/reducerSync";
import reducerControlPlatform from "../modules/integrations/redux/reducers/reducerControlPlatform";
import reducerGetPlatforms from "../modules/integrations/redux/reducers/reducerGetPlatforms";
import reducerDeleteIntegration from "../modules/integrations/redux/reducers/reducerDeleteIntegration";
import reducerControlSyncProducts from "../modules/integrations/redux/reducers/reducerControlSyncProducts";
import reducerIntegrationWix from "../modules/integrations/redux/reducers/reducerIntegrationWix";

import reducerReviews from "../modules/reviews/redux/reducers/reducerReviews";
import reducerUpdateReview from "../modules/reviews/redux/reducers/reducerUpdateReview";
import reducerDeleteReview from "../modules/reviews/redux/reducers/reducerDeleteReview";
import reducerDeleteSelectReviews from "../modules/reviews/redux/reducers/reducerDeleteSelectReviews";
import reducerUpdateSelectReviews from "../modules/reviews/redux/reducers/reducerUpdateSelectReview";
import reducerControlSelectReviews from "../modules/reviews/redux/reducers/reducerControlSelectReviews";

import reducerQuestions from "../modules/questions/redux/reducers/reducerQuestions";
import reducerUpdateQuestion from "../modules/questions/redux/reducers/reducerUpdateQuestion";
import reducerDeleteQuestion from "../modules/questions/redux/reducers/reducerDeleteQuestion";
import reducerAddReplies from "../modules/questions/redux/reducers/reducerAddReplies";
import reducerUpdateReplies from "../modules/questions/redux/reducers/reducerUpdateReplies";
import reducerControlSelectQuestions from "../modules/questions/redux/reducers/reducerControlSelectQuestions";
import reducerUpdateSelectQuestions from "../modules/questions/redux/reducers/reducerUpdateSelectQuestions";
import reducerDeleteSelectQuestions from "../modules/questions/redux/reducers/reducerDeleteSelectQuestions";

import reducerCupons from "../modules/cupons/redux/reducers/reducerCupons";
import reducerUpdateCupom from "../modules/cupons/redux/reducers/reducerUpdateCupom";
import reducerDeleteCupom from "../modules/cupons/redux/reducers/reducerDeleteCupom";
import reducerAddCupom from "../modules/cupons/redux/reducers/reducerAddCupom";

import reducerPayments from "../modules/assinatura/redux/reducers/reducerPayments";

import reducerUpdateAccount from "../modules/account/redux/reducers/reducerUpdateAccount";
import reducerDeleteAccount from "../modules/account/redux/reducers/reducerDeleteAccount";

import reducerDashboard from "../modules/dashboard/redux/reducers/reducerDashboard";
import reducerQueryHelp from "../modules/dashboard/redux/reducers/reducerQueryHelp";

import reducerAdminList from "../modules/admin/redux/reducers/reducerAdminList";
import reducerAdminDeleteCard from "../modules/admin/redux/reducers/reducerAdminDeleteCard";
import reducerAdminDebug from "../modules/admin/redux/reducers/reducerAdminDebug";
import reducerAdminDropi from "../modules/admin/redux/reducers/reducerAdminDropi";
import reducerAdminAddTime from "../modules/admin/redux/reducers/reducerAdminAddTime";
import reducerAdminXpatch from "../modules/admin/redux/reducers/reducerAdminXpatch";

import reducerUpdateConfig from "../modules/thema/redux/reducers/reducerUpdateConfig";
import reducerGetConfig from "../modules/thema/redux/reducers/reducerGetConfig";
import reducerControlConfig from "../modules/thema/redux/reducers/reducerControlConfig";
import reducerControlAba from "../modules/thema/redux/reducers/reducerControlAba";
import reducerSendEmail from "../modules/thema/redux/reducers/reducerSendEmail";
import reducerUpdateConfigHome from "../modules/thema/redux/reducers/reducerUpdateConfigHome";
import reducerControlEdit from "../modules/thema/redux/reducers/reducerControlEdit";
import reducerControlSaveHomeColor from "../modules/thema/redux/reducers/reducerControlSaveHomeColor";
import reducerControlSaveHomeConfig from "../modules/thema/redux/reducers/reducerControlSaveHomeConfig";
import reducerControlSaveEmailColor from "../modules/thema/redux/reducers/reducerControlSaveEmailColor";
import reducerControlSaveEmailConfig from "../modules/thema/redux/reducers/reducerControlSaveEmailConfig";
import reducerControlSaveProductColor from "../modules/thema/redux/reducers/reducerControlSaveProductColor";
import reducerControlSaveProductConfig from "../modules/thema/redux/reducers/reducerControlSaveProductConfig";
import reducerUpdateConfigHomePosition from "../modules/thema/redux/reducers/reducerUpdateConfigHomePosition";

import reducerListNotify from "../components/Header/redux/reducers/reducerListNotify";
import reducerControlMenu from "../components/Header/redux/reducers/reducerControlMenu";

import reducerTokenNuvemshop from "../routers/redux/reducers/reducerTokenNuvemshop";

import reducerControlIntegrationSteps from "../modules/steps/redux/reducers/reducerControlIntegrationSteps"

import reducerProducts from "../modules/products/redux/reducers/reducerProducts";
import reducerUpdateProduct from "../modules/products/redux/reducers/reducerUpdateProduct";
import reducerUpdateDataProduct from "../modules/products/redux/reducers/reducerUpdateDataProduct";
import reducerDeleteProduct from "../modules/products/redux/reducers/reducerDeleteProduct";
import reducerAddProduct from "../modules/products/redux/reducers/reducerAddProduct";
import reducerControlSelectProducts from "../modules/products/redux/reducers/reducerControlSelectProducts";
import reducerUpdateSelectProducts from "../modules/products/redux/reducers/reducerUpdateSelectProducts";
import reducerDeleteSelectProducts from "../modules/products/redux/reducers/reducerDeleteSelectProducts";
import reducerSearchQuestionProduct from "../modules/products/redux/reducers/reducerSearchQuestionProduct";
import reducerSearchReviewProduct from "../modules/products/redux/reducers/reducerSearchReviewProduct";
import ControlModalReviews from "../modules/products/redux/reducers/reducerControlModalReviews";

const rootReducer = combineReducers({
    reducerLogin,
    reducerPassword,
    reducerRegister,
    reducerIntegration,
    reducerSync,
    reducerGetPlatforms,
    reducerControlPlatform,
    reducerControlSyncProducts,
    reducerDeleteIntegration,
    reducerReviews,
    reducerUpdateReview,
    reducerDeleteReview,
    reducerDeleteSelectReviews,
    reducerUpdateSelectReviews,
    reducerControlSelectReviews,
    reducerQuestions,
    reducerUpdateQuestion,
    reducerDeleteQuestion,
    reducerControlSelectQuestions,
    reducerDeleteSelectQuestions,
    reducerUpdateSelectQuestions,
    reducerAddReplies,
    reducerUpdateReplies,
    reducerCupons,
    reducerUpdateCupom,
    reducerDeleteCupom,
    reducerAddCupom,
    reducerPayments,
    reducerUpdateAccount,
    reducerDeleteAccount,
    reducerDashboard,
    reducerQueryHelp,
    reducerAdminList,
    reducerAdminDeleteCard,
    reducerAdminDebug,
    reducerAdminDropi,
    reducerGetConfig,
    reducerControlConfig,
    reducerUpdateConfig,
    reducerListNotify,
    reducerSendEmail,
    reducerProducts,
    reducerUpdateProduct,
    reducerDeleteProduct,
    reducerAddProduct,
    reducerDeleteSelectProducts,
    reducerUpdateSelectProducts,
    reducerControlSelectProducts,
    reducerSearchQuestionProduct,
    reducerSearchReviewProduct,
    ControlModalReviews,
    reducerAdminAddTime,
    reducerControlIntegrationSteps,
    reducerUpdateConfigHome,
    reducerUpdateDataProduct,
    reducerIntegrationWix,
    reducerAdminXpatch,
    reducerControlAba,
    reducerControlMenu,
    reducerControlEdit,
    reducerTokenNuvemshop,
    reducerControlSaveHomeColor,
    reducerControlSaveHomeConfig,
    reducerControlSaveEmailColor,
    reducerControlSaveEmailConfig,
    reducerControlSaveProductColor,
    reducerControlSaveProductConfig,
    reducerControlLanguage,
    reducerUpdateConfigHomePosition
})

export default rootReducer;