import { Container, Btn } from "./styled";
import { useState } from "react";
import { useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai"
import ModalInfor from "../Modal-Integration";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ModalConfirmation from "../../../../components/Modal-Confirmation";
import { BsTools } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { getTranslation } from "../../../../translations";

const Card = (props) => {

    const [visibleModalInfor, setVisibleModalInfor] = useState(false);
    const statusDeleteIntegration = useSelector(state => state.reducerDeleteIntegration)
    const [visibleModalConfirmation, setVisibleModalConfirmation] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    //console.log("CARD props 00 => ", props)
    //console.log("Teste card", JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url?.split(".")[0])

    useEffect(() => {
        if (!statusDeleteIntegration.loading && !statusDeleteIntegration.error && statusDeleteIntegration.data.length == 0) {
        } else {
            const id = "statusDeleteIntegration"
            if (statusDeleteIntegration.loading) {
                toast.loading(translation.integrations.card.aguarde, { toastId: id })
            } else {
                if (statusDeleteIntegration.error) {
                    toast.update(id, { render: translation.integrations.card.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.integrations.card.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    setVisibleModalConfirmation(false)
                }
            }
        }
    }, [statusDeleteIntegration])

    const deleteIntegration = () => { dispatch({ type: "SAGA_DELETE_INTEGRATION", payload: { platform: props.platform.title == "Loja Integrada" ? "loja_integrada" : props.platform.title.toLowerCase().replace(" ", "") } }) }

    const getInfor = (platform) => {
        //console.log("plat => ", platform)
        switch (platform) {
            case "Dropi":
                return `${translation.integrations.card.chaveEmpreender} ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integrationDropi?.user_token}`
            case "Shopify":
                return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Nuvemshop":
                return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Custom":
                return `Site: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Montink":
                return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Hotmart":
                return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Land Page":
                return `Site: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Wix":
                return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
            case "Sak":
                return ``
            default:
                return `Loja: ${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}`
        }
    }

    const validate = () => {
        //console.log("jSON", JSON.parse(localStorage.getItem("depoimentos@login"))?.integration)
        //console.log("props.platform", props.platform)

        if (props.platform.title == "Shopee") {
            history.push("/produtos")
            return null
        }

        if (props.platform.title == "Dropi") {
            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration) {
                //alert("Fazer integração")
                !props.install ? setVisibleModalInfor(true) : setVisibleModalConfirmation(true)
            } else if (props.install) {
                setVisibleModalConfirmation(true)
            } else {
                //alert("Por favor integrar uma plataforma primeiro")
                toast.info(translation.integrations.card.integrePrimeiro, { autoClose: false, })
            }
            //if (!props.install) setVisibleModalInfor(true)
        } else if (props.platform.title == "Rastreio") {
            alert("Rastreio")
        } else if (props.platform.title == "Sak") {
            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration) {
                //alert("Fazer integração")
                !props.install ? setVisibleModalInfor(true) : setVisibleModalConfirmation(true)
            } else if (props.install) {
                setVisibleModalConfirmation(true)
            } else {
                //alert("Por favor integrar uma plataforma primeiro")
                toast.info(translation.integrations.card.integrePrimeiro, { autoClose: false, })
            }
        } else {
            if (JSON.parse(localStorage.getItem("depoimentos@login"))?.integration) {
                if (props.platform.title.toLowerCase().replace(" ", "") === JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform) {
                    setVisibleModalConfirmation(true)
                } else if (props.platform.title === "Loja Integrada" && JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform === "loja_integrada") {
                    setVisibleModalConfirmation(true)
                } else {
                    //alert("Já existe uma plataforma integrada. Desinstale primeiro.")
                    toast.info(translation.integrations.card.jaExiste, { autoClose: false, })
                }
            } else {
                setVisibleModalInfor(true)
            }
        }
    }

    return (
        <Container titleColor={props.platform?.titleColor} >
            <Btn
                //onClick={() => { if (!props.install) setVisibleModalInfor(true) }}
                onClick={() => { validate() }}
            >
                {props.install ?
                    <span>
                        <AiFillCheckCircle size={25} color={"green"} />
                    </span> :
                    <label>{translation.integrations.card.instalar}</label>}
            </Btn>
            <ModalInfor
                platform={props.platform}
                visible={props.platform.title == "Rastreio" ? false : visibleModalInfor}
                setVisibleModal={setVisibleModalInfor}
                item={props.item}
            />
            <ModalConfirmation
                textButtom={"Excluir integração"}
                confirmation={deleteIntegration}
                visible={props.platform.title == "Rastreio" ? false : visibleModalConfirmation}
                setVisibleModal={setVisibleModalConfirmation}
                title={`${translation.integrations.card.desejaExcluir} ${props.platform.title == "Custom" ? translation.integrations.card.integracaoManual : props.platform.title == "Nuvemshop" ? translation.integrations.nuvemshop : props.platform.title} ?`}
                infor={getInfor(props.platform.title == "Custom" ? translation.integrations.card.integracaoManual : props.platform.title)}
            />
            <div
                onClick={() => { validate() }}
            //onClick={() => { !props.install ? setVisibleModalInfor(true) : setVisibleModalConfirmation(true) }}
            >
                {props.platform.title == "Custom" ?
                    <BsTools size={30} color={"#FE852B"} /> :
                    <img src={props.platform?.image} height={props.platform.title == "Wix" ? 50 : 50} />}
                <label>{props.platform?.message}</label>
            </div>
            <span
                onClick={() => { validate() }}
            //onClick={() => { !props.install ? setVisibleModalInfor(true) : setVisibleModalConfirmation(true) }}
            >
                <label>
                    {props.platform?.title == "Wix" ? translation.integrations.card.emBreve :
                        props.platform?.title == "Custom" ? translation.integrations.card.integracaoManual :
                            props.platform?.title == "Nuvemshop" ? translation.integrations.nuvemshop :
                                props.platform?.title}
                </label>
            </span>
        </Container >
    )
}

export default Card
