import { ButtonCustom, BoxLoading, Container, Header, Body, Filters, DataInfor, Menu, Search, Paginate, PaginateInfor, PaginateNav, Options, OptionFilter } from "./styled";
import { AiOutlineSearch, AiOutlineLoading } from "react-icons/ai";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useState } from "react";
import ConfirmationDelete from "../../../../components/Modal-Confirmation";
import { useHistory } from "react-router-dom";
import Card from "../../components/Card";
const Home = () => {


    const [search, setSearch] = useState("")
    const [perPage, setPerPage] = useState(20)
    const [page, setPage] = useState(1)

    const users = useSelector(state => state.reducerAdminList)
    const dispatch = useDispatch()
    const history = useHistory();

    const [inativeFilter, setInativeFilter] = useState(false)
    const [integrationFilter, setIntegrationFilter] = useState(false)
    const [shopifyFilter, setShopifyFilter] = useState(false)
    const [nuvemshopFilter, setNuvemshopFilter] = useState(false)
    const [dropiFilter, setDropiFilter] = useState(false)
    const [hotmartFilter, setHotmartFilter] = useState(false)
    const [siteFilter, setSiteFilter] = useState(false)
    const [landpageFilter, setLandpageFilter] = useState(false)
    const [montinkFilter, setMontinkFilter] = useState(false)
    const [lojaIntegradaFilter, setLojaIntegradaFilter] = useState(false)
    const [cartpandaFilter, setCartpandaFilter] = useState(false)
    const [trialFilter, setTrialFilter] = useState(false)
    const [expiredFilter, setExpiredFilter] = useState(false)





    useEffect(() => {
        if (localStorage.getItem("depoimentosAdmin@login")) {
            dispatch({
                type: "SAGA_LIST_ADMIN", payload: {
                    search: search,
                    perPage: perPage,
                    page: page,
                    isIntegrated: integrationFilter,
                    platforms: `${shopifyFilter && "shopify,"}${nuvemshopFilter && "nuvemshop,"}${hotmartFilter && "hotmart,"}${siteFilter && "custom,"}${dropiFilter && "dropi,"}${landpageFilter && "landpage,"}${cartpandaFilter && "cartpanda,"}${lojaIntegradaFilter && "loja_integrada,"}${montinkFilter && "montink,"}`,
                    isDesactived: inativeFilter
                }
            })
        } else {
            history.push("/")
        }
    }, [])

    const statusDeleteCard = useSelector(state => state.reducerAdminDeleteCard)
    useEffect(() => {
        if (!statusDeleteCard.loading && !statusDeleteCard.error && statusDeleteCard.data.length == 0) {
        } else {
            if (!statusDeleteCard.loading && !statusDeleteCard.error) {
                dispatch({
                    type: "SAGA_LIST_ADMIN", payload: {
                        search: "",
                        perPage: 20,
                        page: 1,
                        isIntegrated: integrationFilter,
                        platforms: `${shopifyFilter && "shopify,"}${nuvemshopFilter && "nuvemshop,"}${hotmartFilter && "hotmart,"}${siteFilter && "custom,"}${dropiFilter && "dropi,"}${landpageFilter && "landpage,"}${cartpandaFilter && "cartpanda,"}${lojaIntegradaFilter && "loja_integrada,"}${montinkFilter && "montink,"}`,
                        isDesactived: inativeFilter
                    }
                })
            }
        }
    }, [statusDeleteCard])

    const goSearch = () => {
        dispatch({
            type: "SAGA_LIST_ADMIN", payload: {
                search: search.trim(),
                perPage: perPage,
                page: page,
                isTrial: trialFilter,
                isExpired: expiredFilter,
                isIntegrated: integrationFilter,
                platforms: `${shopifyFilter ? "shopify," : ""}${nuvemshopFilter ? "nuvemshop," : ""}${hotmartFilter ? "hotmart," : ""}${siteFilter ? "custom," : ""}${dropiFilter ? "dropi," : ""}${landpageFilter ? "landpage," : ""}${montinkFilter ? "montink," : ""}${lojaIntegradaFilter ? "loja_integrada," : ""}${cartpandaFilter ? "cartpanda," : ""}`,
                isDesactived: inativeFilter
            }
        })
    }

    useEffect(() => {
        goSearch()
    }, [, page, trialFilter, expiredFilter, inativeFilter, integrationFilter, shopifyFilter, nuvemshopFilter, dropiFilter, hotmartFilter, siteFilter, landpageFilter, montinkFilter, lojaIntegradaFilter, cartpandaFilter])


    useEffect(() => {
        //console.log("USERS =>", users)
    }, [users])

    const countItens = () => {
        let result = 0
        if (page == users.data?.meta?.last_page) result = users.data?.meta?.total
        else result = page * perPage
        return result
    }

    return (
        <Container>
            <Header>
                <Menu>
                    <label>Inicio</label>
                    <label onClick={() => {
                        localStorage.removeItem("depoimentosAdmin@login");
                        localStorage.removeItem("depoimentos@login");
                        history.push("/login")
                    }} >
                        Sair
                    </label>
                </Menu>
            </Header>
            <Body>
                <Filters>
                    <Search >
                        <input
                            type={"text"}
                            placeholder={"Pesquisar..."}
                            value={search}
                            onChange={(e) => { setSearch(e.target.value) }}
                        />
                        <span onClick={() => { setPage(1); goSearch() }}                        >
                            <AiOutlineSearch size={25} color={"gray"} />
                        </span>
                    </Search>
                    <Options>
                        <OptionFilter onClick={() => setInativeFilter(!inativeFilter)} active={inativeFilter}>Inativos</OptionFilter>
                        <OptionFilter onClick={() => setIntegrationFilter(!integrationFilter)} active={integrationFilter}>Integração</OptionFilter>
                        <OptionFilter onClick={() => setShopifyFilter(!shopifyFilter)} active={shopifyFilter}>Shopify</OptionFilter>
                        <OptionFilter onClick={() => setNuvemshopFilter(!nuvemshopFilter)} active={nuvemshopFilter}>Nuvemshop</OptionFilter>
                        <OptionFilter onClick={() => setDropiFilter(!dropiFilter)} active={dropiFilter}>Dropi</OptionFilter>
                        <OptionFilter onClick={() => setHotmartFilter(!hotmartFilter)} active={hotmartFilter}>Hotmart</OptionFilter>
                        <OptionFilter onClick={() => setSiteFilter(!siteFilter)} active={siteFilter}>Site</OptionFilter>
                        <OptionFilter onClick={() => setCartpandaFilter(!cartpandaFilter)} active={cartpandaFilter}>Cartpanda</OptionFilter>
                        <OptionFilter onClick={() => setLandpageFilter(!landpageFilter)} active={landpageFilter}>Landpage</OptionFilter>
                        <OptionFilter onClick={() => setMontinkFilter(!montinkFilter)} active={montinkFilter}>Montink</OptionFilter>
                        <OptionFilter onClick={() => setLojaIntegradaFilter(!lojaIntegradaFilter)} active={lojaIntegradaFilter}>Loja Integrada</OptionFilter>
                        <OptionFilter onClick={() => setTrialFilter(!trialFilter)} active={trialFilter}>Trial</OptionFilter>
                        <OptionFilter onClick={() => setExpiredFilter(!expiredFilter)} active={expiredFilter}>Expirado</OptionFilter>
                   </Options>
                </Filters>
                {
                    users.loading ?
                        <BoxLoading >
                            <span>
                                <AiOutlineLoading size={30} color={"#20d489"} />
                            </span>
                        </BoxLoading> :
                        <>
                            <DataInfor>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Loja [chave empreender]</th>
                                        <th>Whatsapp</th>
                                        <th>Criação</th>
                                        <th>Vencimento</th>
                                        <th>E-mail</th>
                                        <th>Cartão</th>
                                        <th>Opções</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.data.data?.map((item) => {
                                        return <Card item={item} />
                                    })}
                                </tbody>
                            </DataInfor>
                            <Paginate>
                                <PaginateNav>
                                    <span onClick={() => {
                                        if (page != 1) setPage(page - 1)
                                    }}>
                                        <MdKeyboardArrowLeft
                                            size={35}
                                            color={users.data?.meta?.current_page == 1 ? "gray" : "#19B674"}
                                        />
                                    </span>
                                    <label>
                                        {users.data?.meta?.current_page}
                                    </label>
                                    <span onClick={() => { if (page < users.data?.meta?.last_page) setPage(page + 1) }}>
                                        <MdKeyboardArrowRight
                                            size={35}
                                            color={users.data?.meta?.last_page == page ? "gray" : "#19B674"} />
                                    </span>
                                </PaginateNav>
                                <PaginateInfor>
                                    <label>
                                        Total {countItens()} - {users.data?.meta?.total}
                                    </label>
                                </PaginateInfor>
                            </Paginate>
                        </>
                }
            </Body>
        </Container>
    )
}

export default Home
