import React, { useState } from 'react'
import { BoxButtons, BoxColors, BoxOptions, BoxTema, ButtonHelp, ButtonReset, ButtonSave, Container, Efeito, EstiloWidget, Item, ItemLayout, ItemSelect, Layout, Posicao, SelectCustom, Subtitle, Titulo } from './styled'

import IconReset from "../../../../assets/imgs/icon-reset.svg";
import IconCamera from "../../../../assets/imgs/icon-camera.svg";
import IconSeta from "../../../../assets/imgs/seta-back.svg";
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import { useDispatch, useSelector } from 'react-redux';
import { TextAreaCustom } from '../form-email/styled';
import { CounterItemsCircle } from '../box-config-tema/styled';
import { BoxLoadingCustom, InputFileCustomTeste, InputTextAreaCustom, InputTextCustom } from '../box-config-email/styled';
import { useEffect } from 'react';
import { AiOutlineLoading } from 'react-icons/ai';
import { FaUpload } from 'react-icons/fa';
import imageCompression from 'browser-image-compression';

import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { getTranslation } from '../../../../translations';
import { BarSubmenu, BarSubmenuItem, ItemOption, Main, MenuOption, Title } from '../bar-config-product-cores/styled';
import { IoIosArrowBack } from 'react-icons/io';


import IconText from "../../../../assets/imgs/icon-text.svg";
import IconColorText from "../../../../assets/imgs/icon-color-text.svg";
import IconLayout from "../../../../assets/imgs/icon-layout.svg";
import IconPosicao from "../../../../assets/imgs/icon-posicao.svg";
import IconEstiloWidget from "../../../../assets/imgs/icon-estilo-widget.svg";
import IconVideoHome from "../../../../assets/imgs/icon-video-home.svg";
import IconEfeito from "../../../../assets/imgs/icon-efeito.svg";
import { Carrossel, Favoritas, ItemActive } from '../bar-config-home-habilitar/styled';

import IconBoxDesktop from "../../../../assets/imgs/icon-box-desktop.svg";
import IconGridDesktop from "../../../../assets/imgs/icon-grid-desktop.svg";
import IconGridMobile from "../../../../assets/imgs/icon-grid-mobile.svg";
import IconLineDesktop from "../../../../assets/imgs/icon-line-desktop.svg";
import IconLineMobile from "../../../../assets/imgs/icon-line-mobile.svg";

import IconScroll from "../../../../assets/imgs/icon-scroll.svg";

import IconLink from "../../../../assets/imgs/icon-link.svg";
import IconSlider from "../../../../assets/imgs/icon-slider-home.svg";
import IconFavorite from "../../../../assets/imgs/icon-coracao.svg";


import IconImgProduct from "../../../../assets/imgs/icons-sidebar/submenu-product.svg";
import IconImgProductInativo from "../../../../assets/imgs/icons-sidebar/submenu-product-inativo.svg";
import IconImgEmail from "../../../../assets/imgs/icons-sidebar/submenu-email-inativo.svg";
import IconImgEmailAtivo from "../../../../assets/imgs/icons-sidebar/submenu-email-ativo.svg";
import IconImgHome from "../../../../assets/imgs/icon-homepage.svg";
import IconImgHomeAtivo from "../../../../assets/imgs/icon-homepage-active.svg";
import IconImgTeste from "../../../../assets/imgs/teste-00.svg";
import { useHistory, useLocation } from 'react-router';
import { Tooltip } from '../bar-config-product-ajustes/styled';

const BarConfigHomeConfig = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const selectedMenu = useLocation();
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);




    const saveTheme = () => {

        dispatch({
            type: "SAGA_UPDATE_CONFIG", payload: {
                id: config.data?.id,
                homereviews: configControl.homereviews ? 1 : 0,
                editor: configControl.editor ? 1 : 0,

                homereviews_title: configControl.homereviews_title,
                homereviews_widget_style: configControl.homereviews_widget_style,
                homereviews_animation: configControl.homereviews_animation ? 1 : 0,
                homereviews_font_title: configControl.homereviews_font_title,
                homereviews_font_size_title: configControl.homereviews_font_size_title,
                homereviews_align_title: configControl.homereviews_align_title,

                // homereviews_background_color: configControl.homereviews_background_color,
                // homereviews_arrow_color: configControl.homereviews_arrow_color,
                // homereviews_step_color: configControl.homereviews_arrow_color,
                // homereviews_title: configControl.homereviews_color_title,

            }
        })

    }

    const getDefaultTheme = () => {
        const themaDefault = {
            id: config.data.id,

            homereviews: 0,
            editor: 0,

            homereviews_title: "Avaliações dos clientes",
            homereviews_widget_style: "grid",
            homereviews_animation: 1,
            homereviews_font_title: "Poppins",
            homereviews_font_size_title: "22px",
            homereviews_align_title: "center",

            // homereviews_background_color: "#ffffff",
            // homereviews_arrow_color: "#161718",
            // homereviews_step_color: "#161718",
            // homereviews_color_title: "#000000",

        }
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault })

    }

    const isSaveHomeConfig = () => {
        console.log("config homereviews", config.data.homereviews, configControl.homereviews)
        console.log("config editor", config.data.editor, configControl.editor)
        console.log("config homereviews_title", config.data.homereviews_title, configControl.homereviews_title,)
        console.log("config homereviews_animation", config.data.homereviews_animation, configControl.homereviews_animation)
        console.log("config homereviews_widget_style", config.data.homereviews_widget_style, configControl.homereviews_widget_style,)

        if (
            configControl.homereviews == config.data.homereviews &&
            configControl.homereviews_title == config.data.homereviews_title &&
            configControl.homereviews_animation == config.data.homereviews_animation &&
            configControl.homereviews_widget_style == config.data.homereviews_widget_style &&

            configControl.homereviews_align_title == config.data.homereviews_align_title &&
            configControl.homereviews_font_size_title == config.data.homereviews_font_size_title &&
            configControl.homereviews_font_title == config.data.homereviews_font_title
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_CONFIG_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_CONFIG_SET", payload: false })
            return false
        }
    }

    const linkPosition = () => {

        if (JSON.parse(localStorage.getItem("depoimentos@login")).integration?.url != null)
            window.open(`https://${JSON.parse(localStorage.getItem("depoimentos@login")).integration.url}?editorlily=${JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}`)

    }

    return (
        <Main>
            <BarSubmenu>
                <BarSubmenuItem
                    active={false}
                    style={{position: "relative"}}
                    onClick={() => { isSaveHomeConfig() && history.push("/personalizar/tema") }}
                >
                    {selectedMenu.pathname.slice(13) == "/" || selectedMenu.pathname.slice(13) == "" || selectedMenu.pathname.slice(13) == "/tema" ?
                        <img width={24} src={IconImgProduct} /> :
                        <img width={24} src={IconImgProductInativo} />}

                    <Tooltip>
                        Página do produto
                    </Tooltip>

                </BarSubmenuItem>
                <BarSubmenuItem
                    active={false}
                    style={{position: "relative"}}
                    onClick={() => { isSaveHomeConfig() && history.push("/personalizar/email") }}
                >
                    {selectedMenu.pathname.slice(13) == "/email" ?
                        <img width={24} src={IconImgEmailAtivo} /> :
                        <img width={24} src={IconImgEmail} />}

                    <Tooltip>
                        E-mail
                    </Tooltip>

                </BarSubmenuItem>


                <BarSubmenuItem
                    active={true}
                    style={{position: "relative"}}
                    onClick={() => { isSaveHomeConfig() && history.push("/personalizar/home") }}
                >
                    {selectedMenu.pathname.slice(13) == "/home" ?
                        <img width={24} src={IconImgHomeAtivo} /> :
                        <img width={24} src={IconImgHome} />}

                    <Tooltip>
                        Página do inicial
                    </Tooltip>
                </BarSubmenuItem>


            </BarSubmenu>

            <Container>
                <Title>
                    {/* <span onClick={() => {
                        isSaveHomeConfig() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "" })
                    }}>
                        <IoIosArrowBack />
                    </span>
                    <label onClick={() => {
                        isSaveHomeConfig() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "" })
                    }}>
                        {translation.login.password.voltar}
                    </label> */}
                    <label> {translation.thema.homepage.title}</label>
                </Title>

                <MenuOption>
                    {/* <ItemOption active={false} onClick={() => { dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "home/habilitar" }) }}>
                    Habilitar
                </ItemOption> */}
                    <ItemOption active={false} onClick={() => {
                        isSaveHomeConfig() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "home/cores" })
                    }}>
                        {translation.thema.homepage.cores}
                    </ItemOption>
                    <ItemOption active={true} onClick={() => { dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "home/config" }) }}>
                        {translation.thema.homepage.avancado}
                    </ItemOption>

                </MenuOption>

                <BoxOptions>
                    <Carrossel>
                        <span>
                            <div>
                                <img width={20} src={IconSlider} />
                                <label>{translation.thema.boxConfigHome.ativar}</label>
                            </div>
                            <ItemActive onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews: !configControl.homereviews } }) }} active={configControl.homereviews == 1 ? true : false}>
                                <span />
                            </ItemActive>
                        </span>
                        <label>{translation.thema.boxConfigHome.ativarDesc}</label>
                    </Carrossel>
                </BoxOptions>

                <BoxOptions>
                    {/* <Carrossel>
                        <span>
                            <div>
                                <img width={20} src={IconPosicao} />
                                <label>{translation.thema.boxConfigHome.editor}</label>
                            </div>
                            <ItemActive onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { editor: !configControl.editor } }) }} active={configControl.editor == 1 ? true : false}>
                                <span />
                            </ItemActive>
                        </span>
                    </Carrossel> */}
                    <Posicao>

                        <div>
                            <div onClick={() => { linkPosition() }}>
                                <label onClick={() => { linkPosition() }} >
                                    {translation.thema.boxConfigHome.btnEditor}
                                </label>
                                <img onClick={() => { linkPosition() }} src={IconLink} />
                            </div>
                        </div>
                        <label>{translation.thema.boxConfigHome.editorDesc}</label>
                    </Posicao>
                </BoxOptions>

                {/* <BoxOptions>
                    <Favoritas>
                        <span>
                            <img width={20} src={IconFavorite} />
                            <label>AVALIAÇÕES FAVORITAS</label>
                        </span>
                        <label>
                            Escolha suas melhores avaliações para mostrar na página inicial da sua loja.
                        </label>
                        <div>
                            <div>
                                <label onClick={() => { history.push("/avaliacoes") }} >Escolher</label>
                                <img src={IconLink} />
                            </div>

                        </div>
                    </Favoritas>
                </BoxOptions> */}

                <BoxOptions>
                    <Titulo>
                        <span>
                            <div>
                                <img width={20} src={IconText} />
                                <label> {translation.thema.boxConfigHome.titulo.toUpperCase()}</label>
                            </div>
                        </span>
                        <input
                            type={"text"}
                            value={configControl.homereviews_title}
                            placeholder={"Título do widget"}
                            onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_title: e.target.value } }) }}
                        />
                    </Titulo>

                    <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_font_title: e.target.value } }) }}>
                        <option value={"Poppins"} selected={configControl.homereviews_font_title == "Poppins" ? true : false}>Poppins</option>
                        <option value={"Times New Roman"} selected={configControl.homereviews_font_title == "Times New Roman" ? true : false}>Times New Roman</option>
                        <option value={"Arial"} selected={configControl.homereviews_font_title == "Arial" ? true : false}>Arial</option>
                        <option value={"Courier New"} selected={configControl.homereviews_font_title == "Courier New" ? true : false}>Courier New</option>
                        <option value={"Georgia"} selected={configControl.homereviews_font_title == "carGeorgiarossel" ? true : false}>Georgia</option>
                    </SelectCustom>

                    <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_font_size_title: e.target.value } }) }}>
                        <option value={"16px"} selected={configControl.homereviews_font_size_title == "16px" ? true : false}>16px</option>
                        <option value={"18px"} selected={configControl.homereviews_font_size_title == "18px" ? true : false}>18px</option>
                        <option value={"20px"} selected={configControl.homereviews_font_size_title == "20px" ? true : false}>20px</option>
                        <option value={"22px"} selected={configControl.homereviews_font_size_title == "22px" ? true : false}>22px</option>
                        <option value={"24px"} selected={configControl.homereviews_font_size_title == "24px" ? true : false}>24px</option>
                        <option value={"26px"} selected={configControl.homereviews_font_size_title == "26px" ? true : false}>26px</option>
                        <option value={"28px"} selected={configControl.homereviews_font_size_title == "28px" ? true : false}>28px</option>
                        <option value={"30px"} selected={configControl.homereviews_font_size_title == "30px" ? true : false}>30px</option>
                    </SelectCustom>

                    <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_align_title: e.target.value } }) }}>
                        <option value={"center"} selected={configControl?.homereviews_align_title == "center" ? true : false}>Centralizado</option>
                        <option value={"left"} selected={configControl?.homereviews_align_title == "left" ? true : false}>Alinhado à esquerda</option>
                    </SelectCustom>
                </BoxOptions>

                <BoxOptions>
                    <EstiloWidget>
                        <span>
                            <div>
                                <img src={IconEstiloWidget} />
                                <label> {translation.thema.boxConfigHome.estiloWidget}</label>
                            </div>
                        </span>
                        <SelectCustom onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_widget_style: e.target.value } }) }}>
                            <option value={"grid"} selected={configControl.homereviews_widget_style == "grid" ? true : false}>Grid</option>
                            <option value={"carousel"} selected={configControl.homereviews_widget_style == "carousel" ? true : false}>Carrossel</option>
                        </SelectCustom>
                        {/* <span>
                        <div>
                            <img width={20} src={IconVideoHome} />
                            <label>ANIMAR AVALIAÇÕES</label>
                        </div>
                        <ItemActive onClick={() => { setActiveAnimation(!activeAnimation) }} active={activeAnimation}>
                            <span />
                        </ItemActive>
                    </span> */}
                    </EstiloWidget>
                </BoxOptions>

                <BoxOptions>
                    <EstiloWidget>
                        <span>
                            <div>
                                <img src={IconVideoHome} />
                                <label> {translation.thema.boxConfigHome.animarAvaliacoes}</label>
                            </div>
                            <ItemActive onClick={() => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { homereviews_animation: !configControl.homereviews_animation } }) }} active={configControl.homereviews_animation == 1 ? true : false}>
                                <span />
                            </ItemActive>
                        </span>
                        <label>
                            {translation.thema.boxConfigHome.AnimarAvaliacoesDesc}
                        </label>
                    </EstiloWidget>
                </BoxOptions>

                {/* <BoxOptions>
                <Posicao>
                    <span>
                        <img width={20} src={IconPosicao} />
                        <label>POSIÇÃO:</label>
                    </span>
                    <label>Defina em que espaço as avaliações ficarão posicionados na sua página inicial</label>
                    <div>
                        <div>
                            <label>Definir posição</label>
                        </div>
                    </div>
                </Posicao>
            </BoxOptions> */}
                {/* <BoxOptions>
                <Layout>
                    <span>
                        <img width={20} src={IconLayout} />
                        <label>LAYOUT DAS AVALIAÇÕES</label>
                    </span>
                    <div>
                        <ItemLayout onClick={() => { setLayout("grid") }} active={layout == "grid" ? true : false}>
                            <img src={IconGridMobile} />
                            <img src={IconGridDesktop} />
                        </ItemLayout>
                        <ItemLayout onClick={() => { setLayout("box") }} active={layout == "box" ? true : false}>
                            <img src={IconLineMobile} />
                            <img src={IconBoxDesktop} />
                        </ItemLayout>
                        <ItemLayout onClick={() => { setLayout("line") }} active={layout == "line" ? true : false}>
                            <img src={IconLineMobile} />
                            <img src={IconLineDesktop} />
                        </ItemLayout>
                    </div>
                </Layout>
            </BoxOptions> */}

                {/* {
                widget == "grid" ?
                    <BoxOptions>
                        <EstiloWidget>
                            <span>
                                <div>
                                    <img src={IconVideoHome} />
                                    <label>ANIMAÇÃO:</label>
                                </div>
                                <ItemActive onClick={() => { setActiveAnimation(!activeAnimation) }} active={activeAnimation}>
                                    <span />
                                </ItemActive>
                            </span>
                            <label>
                                O grid se movimenta para cima de modo automático
                            </label>
                        </EstiloWidget>
                    </BoxOptions> :
                    <BoxOptions>
                        <EstiloWidget>
                            <span>
                                <div>
                                    <img src={IconScroll} />
                                    <label>SCROLL AUTOMÁTICO:</label>
                                </div>
                                <ItemActive onClick={() => { setActiveScroll(!activeScroll) }} active={activeScroll}>
                                    <span />
                                </ItemActive>
                            </span>

                            {activeScroll ?
                                <label>
                                    Neste modo, as setas não ficam disponíveis para seus clientes
                                </label> :
                                <label>
                                    Neste modo, as setas ficam disponíveis para seus clientes
                                </label>}
                        </EstiloWidget>
                    </BoxOptions>
            } */}


                <ButtonHelp>
                    <img width={20} src={IconBossVideo} />
                    <label>{translation.dashboard.precisaAjuda}</label>
                    <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                </ButtonHelp>

                <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

                <BoxButtons>
                    <ButtonReset onClick={() => getDefaultTheme()}>
                        <label>{translation.thema.boxConfigEmail.restaurar}</label>
                        {/* <img src={IconReset} /> */}
                    </ButtonReset>
                    <ButtonSave onClick={() => saveTheme()}>
                        {translation.thema.boxConfigEmail.salvar}
                    </ButtonSave>
                </BoxButtons>
            </Container>
        </Main>

    )
}

export default BarConfigHomeConfig