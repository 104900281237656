import React, { useState, useEffect } from 'react'
import { BoxButtons, BoxColors, BoxTema, ButtonHelp, ButtonReset, ButtonSave, Container, InputTextColor, Item, SelectCustom, Subtitle } from './styled'

import IconReset from "../../../../assets/imgs/icon-reset.svg";
import IconSeta from "../../../../assets/imgs/seta-back.svg";
import IconBossVideo from "../../../../assets/imgs/boss/boss-video.svg";
import { useSelector, useDispatch } from 'react-redux';
import IconVideo from "../../../../assets/imgs/icon-video.svg";
import { getTranslation } from '../../../../translations';
import { IoIosArrowBack } from "react-icons/io";
import { BarSubmenu, BarSubmenuItem, ItemOption, Main, MenuOption, Title } from '../bar-config-product-cores/styled';

import IconImgProduct from "../../../../assets/imgs/icons-sidebar/submenu-product.svg";
import IconImgProductInativo from "../../../../assets/imgs/icons-sidebar/submenu-product-inativo.svg";
import IconImgEmail from "../../../../assets/imgs/icons-sidebar/submenu-email-inativo.svg";
import IconImgEmailAtivo from "../../../../assets/imgs/icons-sidebar/submenu-email-ativo.svg";
import IconImgHome from "../../../../assets/imgs/icon-homepage.svg";
import IconImgHomeAtivo from "../../../../assets/imgs/icon-homepage-active.svg";
import IconImgTeste from "../../../../assets/imgs/teste-00.svg";
import { useHistory, useLocation } from 'react-router';
import { Tooltip } from '../bar-config-product-ajustes/styled';
const BarConfigEmail = () => {

    const dispatch = useDispatch()
    const history = useHistory()
    const selectedMenu = useLocation();

    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)


    const [theme, setTheme] = useState("")

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);


    const saveTheme = () => {

        dispatch({
            type: "SAGA_UPDATE_CONFIG", payload: {
                id: config.data?.id,
                color_text_button: configControl.color_text_button,
                background_button: configControl.background_button,
                color_text: configControl.color_text,
                color_border: configControl.color_border,
                background_body: configControl.background_body
            }
        })

    }

    const getDefaultTheme = () => {
        const themaDefault = {
            id: config.data.id,
            color_text_button: "#FFFFFF",
            background_button: "#000000",
            color_text: "#000000",
            background_body: "#FFFFFF",
            color_border: "#D3D3D3",
        }
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault })
    }


    useEffect(() => {
        switch (theme) {
            case "dark":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        background_button: "#FFFFFF",
                        color_text_button: "#000000",
                        background_body: "#000000",
                        color_text: "#FFFFFF",
                        color_border: "#808080"
                    }
                })
                break;
            case "light":
                dispatch({
                    type: "CONFIG_CONTROL_SET", payload: {
                        background_button: "#000000",
                        color_text_button: "#FFFFFF",
                        background_body: "#FFFFFF",
                        color_text: "#000000",
                        color_border: "#D3D3D3"
                    }
                })
                break;
            default:
                break;
        }
    }, [theme])

    useEffect(() => {
        if (
            configControl.background_button == "#000000" &&
            configControl.color_text_button == "#ffffff" &&
            configControl.background_body == "#ffffff" &&
            configControl.color_text == "#000000" &&
            configControl.color_border == "#d3d3d3"
        ) {
            setTheme("light")
        }
        else if (
            configControl.background_button == "#ffffff" &&
            configControl.color_text_button == "#000000" &&
            configControl.background_body == "#000000" &&
            configControl.color_text == "#ffffff" &&
            configControl.color_border == "#808080"

        ) {
            setTheme("dark")
        }
        else {
            setTheme("custom")
        }
    }, [configControl])

    const isSaveEmailColor = () => {
        if (
            configControl.color_text_button == config.data.color_text_button &&
            configControl.background_button == config.data.background_button &&
            configControl.color_text == config.data.color_text &&
            configControl.color_border == config.data.color_border &&
            configControl.background_body == config.data.background_body
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_COLOR_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_COLOR_SET", payload: false })
            return false
        }
    }


    return (
        <Main>
            <BarSubmenu>
                <BarSubmenuItem
                    active={false}
                    style={{ position: "relative" }}
                    onClick={() => { isSaveEmailColor() && history.push("/personalizar/tema") }}
                >
                    {selectedMenu.pathname.slice(13) == "/" || selectedMenu.pathname.slice(13) == "" || selectedMenu.pathname.slice(13) == "/tema" ?
                        <img width={24} src={IconImgProduct} /> :
                        <img width={24} src={IconImgProductInativo} />}

                    <Tooltip>
                        Página do produto
                    </Tooltip>

                </BarSubmenuItem>
                <BarSubmenuItem
                    active={true}
                    style={{ position: "relative" }}
                    onClick={() => { isSaveEmailColor() && history.push("/personalizar/email") }}
                >
                    {selectedMenu.pathname.slice(13) == "/email" ?
                        <img width={24} src={IconImgEmailAtivo} /> :
                        <img width={24} src={IconImgEmail} />}

                    <Tooltip>
                        E-mail
                    </Tooltip>

                </BarSubmenuItem>


                <BarSubmenuItem
                    active={false}
                    style={{ position: "relative" }}
                    onClick={() => { isSaveEmailColor() && history.push("/personalizar/home") }}
                >
                    {selectedMenu.pathname.slice(13) == "/home" ?
                        <img width={24} src={IconImgHomeAtivo} /> :
                        <img width={24} src={IconImgHome} />}

                    <Tooltip>
                        Página do inicial
                    </Tooltip>

                </BarSubmenuItem>


            </BarSubmenu>

            <Container>
                <Title>
                    {/* <span onClick={() => {
                        isSaveEmailColor() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "" })
                    }}>
                        <IoIosArrowBack />
                    </span>
                    <label onClick={() => {
                        isSaveEmailColor() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "" })
                    }}>
                        {translation.login.password.voltar}
                    </label> */}
                    <label>{translation.thema.boxConfigEmail.title}</label>
                </Title>

                <MenuOption>
                    <ItemOption active={true} onClick={() => {
                        isSaveEmailColor() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "email/cores" })
                    }}>
                        {translation.thema.boxConfigEmail.cores}
                    </ItemOption>
                    <ItemOption active={false} onClick={() => {
                        isSaveEmailColor() && dispatch({ type: "CONFIG_CONTROL_EDIT_SET", payload: "email/config" })
                    }}>
                        {translation.thema.boxConfigEmail.avancado}
                    </ItemOption>
                </MenuOption>

                <BoxTema>
                    <label>{translation.thema.boxConfigEmail.combinacaoCores}:</label>
                    <SelectCustom name="select_theme_reviews" onChange={(event) => { setTheme(event.target.value) }}                        >
                        <option value="light" selected={theme == "light" ? true : false}>Light</option>
                        <option value="dark" selected={theme == "dark" ? true : false}>Dark</option>
                        <option disabled value="custom" selected={theme == "custom" ? true : false}>{translation.thema.boxConfigEmail.personalizado}</option>
                    </SelectCustom>
                </BoxTema>

                <BoxColors>
                    <label>{translation.thema.boxConfigEmail.cores}:</label>
                    <Item>
                        <label>{translation.thema.boxConfigEmail.corTexto}</label>
                        <span>
                            <input type={"color"} value={configControl.color_text} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_text: e.target.value } }) }} />
                            {/* <label>{configControl.color_text}</label> */}
                            <InputTextColor type='text' value={"" + configControl.color_text} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_text: e.target.value } }) }} />
                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.boxConfigEmail.corBotao}</label>
                        <span>
                            <input type={"color"} value={configControl.background_button} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_button: e.target.value } }) }} />
                            {/* <label>{configControl.background_button}</label> */}
                            <InputTextColor type='text' value={configControl.background_button} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_button: e.target.value } }) }} />
                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.boxConfigEmail.corTextoBotao}</label>
                        <span>
                            <input type={"color"} value={configControl.color_text_button} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_text_button: e.target.value } }) }} />
                            {/* <label>{configControl.color_text_button}</label> */}
                            <InputTextColor type='text' value={configControl.color_text_button} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_text_button: e.target.value } }) }} />
                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.boxConfigEmail.corFundo}</label>
                        <span>
                            <input type={"color"} value={configControl.background_body} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_body: e.target.value } }) }} />
                            {/* <label>{configControl.background_body}</label> */}
                            <InputTextColor type='text' value={configControl.background_body} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { background_body: e.target.value } }) }} />

                        </span>
                    </Item>

                    <Item>
                        <label>{translation.thema.boxConfigEmail.corBorda}</label>
                        <span>
                            <input type={"color"} value={configControl.color_border} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_border: e.target.value } }) }} />
                            {/* <label>{configControl.color_border}</label> */}
                            <InputTextColor type='text' value={configControl.color_border} onChange={(e) => { dispatch({ type: "CONFIG_CONTROL_SET", payload: { color_border: e.target.value } }) }} />
                        </span>
                    </Item>
                </BoxColors>

                <ButtonHelp>
                    <img src={IconBossVideo} />
                    <label>{translation.dashboard.precisaAjuda}</label>
                    <img style={{ width: "24px", height: "24px", }} src={IconVideo} />
                </ButtonHelp>

                <hr style={{ width: "calc(100% + 40px)", margin: "0px 0px 0px -20px" }} />

                <BoxButtons>
                    <ButtonReset onClick={() => getDefaultTheme()}>
                        <label>{translation.thema.boxConfigEmail.restaurar}</label>
                        {/* <img src={IconReset} /> */}
                    </ButtonReset>
                    <ButtonSave onClick={() => { saveTheme() }}>
                        {translation.thema.boxConfigEmail.salvar}
                    </ButtonSave>
                </BoxButtons>
            </Container>
        </Main>

    )
}

export default BarConfigEmail