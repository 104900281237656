import { Container, Btn } from "./styled";
import { useState } from "react";
import { useEffect } from "react";
import { AiFillCheckCircle } from "react-icons/ai"
import ModalInfor from "../Modal-Integration";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ModalConfirmation from "../../../../components/Modal-Confirmation";
import { BsTools } from "react-icons/bs";

const CardLoading = () => {

    return (
        <Container >
            <Btn>
                <span />
            </Btn>
            <div>
                <span />
                <label />
            </div>
            <span>
                <label />
            </span>
        </Container >
    )
}

export default CardLoading
