import { BoxBarApps, UserDropdownSubMenuInfor, UserDropdownSubMenu, QtdNotify, SubMenuNotify, Notify, ChaveEmpreender, Container, Title, SectionOptions, IconMenu, UserDropdown, UserDropdownMenu, SubMenu, Nav, Tooltip, Tooltips, TooltipMenu, CustomStrongAlert, ContentsAlert, BoxMenu, BoxApps, Trial } from "./styled";
import { RiDashboardFill, } from "react-icons/ri";
import { AiFillStar, AiOutlineLogout, AiOutlineCopy } from "react-icons/ai";
import { RiQuestionnaireFill, RiAccountBoxFill, RiKeyFill, RiCloseFill } from "react-icons/ri";
import { FaPiggyBank, FaLayerGroup, FaUserCircle, FaRegQuestionCircle, FaUserAlt } from "react-icons/fa";
import { HiUser } from "react-icons/hi";
import { FiHelpCircle } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { useBurgerMenu } from '../../hooks/useBurgerMenu'
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { displayScript } from "../../utils/displayScript";
import { IoNotifications, IoPersonSharp } from "react-icons/io5";
import iconMenu from "../../assets/imgs/icon-login.svg";
import iconNotify from "../../assets/imgs/icon-notify.svg";
import logoLily from "../../assets/imgs/Girrafe.svg";
import iconMenuDropi from "../../assets/imgs/userIcon.svg";
import iconNotifyNew from "../../assets/imgs/icon-notification.svg";
import AlertClose from "../Alert-Close";
import { Children } from "react";
import { getTranslation } from "../../translations";


import iconMenuNew from "../../assets/imgs/icon-menu.svg";
import { intervalToDuration } from "date-fns";

const Header = (props) => {

    const config = useSelector(state => state.reducerGetConfig)
    const configControl = useSelector(state => state.reducerControlConfig)
    const edit = useSelector(state => state.reducerControlEdit)

    const { burgerMenu, setBurgerMenu } = useBurgerMenu()

    const menuOpen = useSelector(state => state.reducerControlMenu)

    const icon = { size: 20, color: "gray" }
    const selectedMenu = useLocation();
    const history = useHistory();
    const [visibleMenu, setVisibleMenu] = useState(false)
    const [visibleMenuNotify, setVisibleMenuNotify] = useState(false)

    const [visibleInforTheme, setVisibleInforTheme] = useState(true)

    const dispatch = useDispatch()

    const listNotify = useSelector(state => state.reducerListNotify)

    const closeMenu = (event) => {
        setVisibleMenu(false)
    };
    const closeMenuNotify = (event) => {
        setVisibleMenuNotify(false)
    };

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language)

    useEffect(() => {

        if (visibleMenu) {
            window.addEventListener('mouseup', closeMenu);
            // cleanup this component
            return () => {
                window.removeEventListener('mouseup', closeMenu);
            };
        }
    }, [visibleMenu])

    useEffect(() => {
        if (visibleMenuNotify) {
            window.addEventListener('mouseup', closeMenuNotify);
            // cleanup this component
            return () => {
                window.removeEventListener('mouseup', closeMenuNotify);
            };
        }
    }, [visibleMenuNotify])

    const copy = () => {
        navigator.clipboard.writeText(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender)
        //setCopyKey(text)
        toast.success(translation.header.copy)
    }

    function handleBurgerMenuClick() {
        burgerMenu ? setBurgerMenu(false) : setBurgerMenu(true)

    }

    useEffect(() => {
        if (localStorage.getItem("depoimentos@login")) {

        } else {
            history.push("/login")
        }

        dispatch({ type: "SAGA_LIST_NOTIFY" })
    }, [])

    let userInformations = localStorage.getItem('depoimentos@login');

    useEffect(function () {
        try {
            let toolbarTimeout = setInterval(() => {
            let token = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.token;
    
            let tokenType = JSON.parse(localStorage.getItem("depoimentos@login"))?.access_token.type;
    
            if (token) {
              window.empreenderPlusApiInstancia({
                caminhoParaRepassarRequestAoPlus: process.env.REACT_APP_API_URL + "/eplus/hub", //OBRIGATORIO
                afiliado: "", //OPCIONAL
                insert: "insert-toolbar-plus", //classe para inserir script se não passar insere no body - OPCIOANL
                app: "LILY", //code do app - OBRIGATORIO
                // callbackLogout: () => console.log("logout"),
                // headers - OPCIONAL
                headers: {
                  Authorization: tokenType+" "+token,
                  "Content-Type": "application/json",
                },
              });
    
              clearInterval(toolbarTimeout);
            }
          }, 250);
        } catch (e) {
          console.error("empreenderPlusApiInstancia - error", e);
        }
        // try {
        //     let infosParsed = JSON.parse(userInformations);

        //     if (infosParsed?.user?.id_user_plus && infosParsed?.access_token?.token) {
        //         let user_access_token = infosParsed.access_token.type + " " + infosParsed.access_token.token;

        //         if (user_access_token) {
        //             window.initProdutosEmpreenderTopMenu({
        //                 caminhoParaRepassarRequestAoPlus: process.env.REACT_APP_API_URL + "/eplus/hub",
        //                 headers: {
        //                     'Authorization': user_access_token,
        //                     'Content-Type': "application/json"
        //                 }
        //             });
        //         }
        //     }
        // } catch (e) { console.log(e) }
    }, [selectedMenu, userInformations, window?.initProdutosEmpreenderTopMenu]);

    useEffect(function () {
        try {
            let infosParsed = JSON.parse(userInformations);
            if (infosParsed?.user?.id_user_plus) {
                window.empreenderPlusRegistrarConversao(infosParsed.user.id_user_plus); // passar o id user plus
            }
        } catch (err) { }
    }, [userInformations]);

    const getMessageReviews = (qtd) => {
        if (qtd == 1) {
            return "1 " + translation.header.notificacoes.novaAvaliacao
        } else {
            return qtd + " " + translation.header.notificacoes.novasAvaliacoes
        }
    }
    const getMessageQuestions = (qtd) => {
        if (qtd == 1) {
            return "1 " + translation.header.notificacoes.novaPergunta
        } else {
            return qtd + " " + translation.header.notificacoes.novasPerguntas
        }
    }

    // useEffect(() => {
    //     const script = document.createElement("script")
    //     script.src = "https://empreender.nyc3.cdn.digitaloceanspaces.com/static/OUTROS/top-menu-produtos-empreeder?app=LILY&insert=container__script_top_menu_lily"
    //     script.async = true
    //     document.body.appendChild(script)
    // }, [])

    // useEffect(() => {
    //     const dateUpdate = new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.updated_at)
    //     const dateVerify = new Date("2022-07-01")

    //     console.log("data", new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.updated_at))
    //     console.log("data update", dateUpdate < dateVerify)

    // }, [])

    const handleMenu = () => {
        //console.log("teste menuOpen", menuOpen)
        //teste
        dispatch({ type: "HEADER_CONTROL_MENU", payload: { isOpen: !menuOpen.isOpen } })
    }


    const isSaveHomeConfig = () => {

        if (
            configControl.homereviews == config.data.homereviews &&
            configControl.editor == config.data.editor &&
            configControl.homereviews_title == config.data.homereviews_title &&
            configControl.homereviews_animation == config.data.homereviews_animation &&
            configControl.homereviews_widget_style == config.data.homereviews_widget_style
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_CONFIG_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_CONFIG_SET", payload: false })
            return false
        }
    }

    const isSaveHomeColor = () => {

        if (
            configControl.homereviews_background_color == config.data.homereviews_background_color &&
            configControl.homereviews_arrow_color == config.data.homereviews_arrow_color &&
            configControl.homereviews_step_color == config.data.homereviews_step_color &&
            configControl.homereviews_color_title == config.data.homereviews_color_title
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_COLOR_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_HOME_COLOR_SET", payload: false })
            return false
        }
    }

    const isSaveEmailColor = () => {

        if (
            configControl.color_text_button == config.data.color_text_button &&
            configControl.background_button == config.data.background_button &&
            configControl.color_text == config.data.color_text &&
            configControl.color_border == config.data.color_border &&
            configControl.background_body == config.data.background_body
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_COLOR_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_COLOR_SET", payload: false })
            return false
        }
    }

    const isSaveEmailConfig = () => {

        if (
            config.data.font_email == configControl.font_email &&
            config.data.request_email_logourl == configControl.request_email_logourl &&
            config.data.request_email_content == configControl.request_email_content &&
            config.data.request_email_subject == configControl.request_email_subject &&
            config.data.request_email_buttontext == configControl.request_email_buttontext &&
            config.data.request_email == configControl.request_email &&
            config.data.request_email_date == configControl.request_email_date
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_CONFIG_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_EMAIL_CONFIG_SET", payload: false })
            return false
        }
    }

    const isSaveProductColor = () => {
        //console.log("config", config.data)
        if (
            config.data.star_color_primary == configControl.star_color_primary &&
            config.data.star_color_secondary == configControl.star_color_secondary &&
            config.data.font_color_primary == configControl.font_color_primary &&
            config.data.font_color_secondary == configControl.font_color_secondary &&
            config.data.primary_color == configControl.primary_color &&
            config.data.background_color_primary == configControl.background_color_primary &&
            config.data.background_color_secondary == configControl.background_color_secondary
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_COLOR_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_COLOR_SET", payload: false })
            return false
        }
    }

    const isSaveProductConfig = () => {
        //console.log("config", config.data)
        if (
            configControl.no_review_star == config.data.no_review_star &&
            configControl.visible_button_review == config.data.visible_button_review &&
            configControl.visible_button_ask == config.data.visible_button_ask &&
            configControl.visible_date_review == config.data.visible_date_review &&
            configControl.itens_per_page == config.data.itens_per_page &&
            configControl.layout == config.data.layout &&
            configControl.layout_question == config.data.layout_question &&
            configControl.layout_form == config.data.layout_form &&
            configControl.layout_form_ask == config.data.layout_form_ask &&
            configControl.terms_use == config.data.terms_use &&
            configControl.font == config.data.font &&
            configControl.order_reviews == config.data.order_review &&
            configControl.order_asks == config.data.order_asks &&
            configControl.layout_stars == config.data.layout_stars &&
            configControl.resume_reviews == config.data.resume_reviews &&
            configControl.layout_resume == config.data.layout_resume &&
            configControl.auto_approve == config.data.auto_approve
        ) {
            dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_CONFIG_SET", payload: true })
            return true
        } else {
            dispatch({ type: "CONFIG_CONTROL_SAVE_PRODUCT_CONFIG_SET", payload: false })
            return false
        }
    }

    const verifiSave = (action) => {

        if (edit == "home/cores") {
            isSaveHomeColor() && history.push(action)
        } else if (edit == "home/config") {
            isSaveHomeConfig() && history.push(action)
        } else if (edit == "email/cores") {
            isSaveEmailColor() && history.push(action)
        } else if (edit == "email/config") {
            isSaveEmailConfig() && history.push(action)
        } else if (edit == "produto/cores") {
            isSaveProductColor() && history.push(action)
        } else if (edit == "produto/config") {
            isSaveProductConfig() && history.push(action)
        } else {
            history.push(action)
        }
    }

    const verifiSave2 = (type) => {
        if (type == "plus") {
            if (edit == "home/cores") {
                if (isSaveHomeColor()) { window.location.href = process.env.REACT_APP_EPLUS_PAGINA_APPS; }
            } else if (edit == "home/config") {
                if (isSaveHomeConfig()) { window.location.href = process.env.REACT_APP_EPLUS_PAGINA_APPS; }
            } else if (edit == "email/cores") {
                if (isSaveEmailColor()) { window.location.href = process.env.REACT_APP_EPLUS_PAGINA_APPS; }
            } else if (edit == "email/config") {
                if (isSaveEmailConfig()) { window.location.href = process.env.REACT_APP_EPLUS_PAGINA_APPS; }
            } else if (edit == "produto/cores") {
                if (isSaveProductColor()) { window.location.href = process.env.REACT_APP_EPLUS_PAGINA_APPS; }
            } else if (edit == "produto/config") {
            } else {
                window.location.href = process.env.REACT_APP_EPLUS_PAGINA_APPS;
            }
        } else if (type == "logout") {

            if (edit == "home/cores") {
                if (isSaveHomeColor()) {
                    localStorage.setItem("depoimentos@pais", localStorage.getItem("depoimentos@login")?.user?.country)
                    localStorage.removeItem("depoimentos@login");
                    history.push("/login")
                }
            } else if (edit == "home/config") {
                if (isSaveHomeConfig()) {
                    localStorage.removeItem("depoimentos@login");
                    history.push("/login")
                }
            } else if (edit == "email/cores") {
                if (isSaveEmailColor()) {
                    localStorage.removeItem("depoimentos@login");
                    history.push("/login")
                }
            } else if (edit == "email/config") {
                if (isSaveEmailConfig()) {
                    localStorage.removeItem("depoimentos@login");
                    history.push("/login")
                }
            } else if (edit == "produto/cores") {
                if (isSaveProductColor()) {
                    localStorage.removeItem("depoimentos@login");
                    history.push("/login")
                }
            } else if (edit == "produto/config") {
            } else {
                localStorage.removeItem("depoimentos@login");
                history.push("/login")
            }
        }
    }

    const getTrial = () => {
        let result = "";
        let dates = intervalToDuration({
            start: new Date(),
            end: new Date(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).setDate(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).getDate() + (JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.affiliate == null ? 3 : 10))),
        });
        //console.log("gettrial", dates)
        //console.log("teste data ", new Date(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).setDate(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).getDate() + (JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.affiliate == null ? 3 : 10))))

        if (new Date() > new Date(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).setDate(new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.created_at).getDate() + (JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.affiliate == null ? 3 : 10)))) {
            return `Fim do período de testes.`
        }
        if (dates.days == 0 && dates.hours > 0) {
            return `Restam ${dates.hours} horas grátis.`
        } else if (dates.days == 0 && dates.hours == 0) {
            return `Fim do período de testes.`
        } else if (dates.days > 0 && dates.hours == 0) {
            return `Restam ${dates.days} dias grátis.`
        } else {
            //console.log("teste default")
            return `Restam ${dates.days} dias e ${dates.hours} horas grátis.`
        }

        //return dates.hours > 0 ? `Restam ${dates.days} dias e ${dates.hours} horas grátis.` : `Restam ${dates.days} dias grátis.`
    }

    return (
        <>
            <div className="insert-toolbar-plus" style={{ position: "relative", minHeight: "62px" }}></div>
            {/* <BoxApps menuOpen={menuOpen.isOpen}>
                <BoxBarApps className="container__script_top_menu_lily"></BoxBarApps>
            </BoxApps> */}
            {props.children}
            <Container menuOpen={menuOpen.isOpen}>

                <BoxMenu>
                    <img onClick={() => { handleMenu() }} src={iconMenuNew} />
                </BoxMenu>

                {/* <Title>
                    <img style={{ margin: "5px 0px 0px 5px" }} height={"50px"} src={logoLily} />
                </Title> */}
                <SectionOptions>

                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.cred_expire == 0 &&
                        <Trial onClick={() => { history.push("/pagamentos"); }}>
                            {getTrial()}
                            <span> Assinar</span>
                        </Trial>
                    }


                    <UserDropdown onClick={() => { setVisibleMenu(true) }} >
                        {/* <label className="user__name">{JSON.parse(localStorage.getItem("depoimentos@login"))?.user.name}</label> */}
                        {/* <IoPersonSharp size={30} color={"#A1A5B7"} /> */}
                        {/* <img width={50} src={iconMenuDropi} /> */}

                        <span>
                            <span>
                                {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.name[0].toLowerCase()}
                            </span>

                        </span>
                        <UserDropdownSubMenu visible={visibleMenu}>
                            <UserDropdownSubMenuInfor>
                                <label>{JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.name}</label>
                                <label>{JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.email}</label>
                                {
                                    JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url != null &&
                                    <label>{JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.url}</label>
                                }
                            </UserDropdownSubMenuInfor>
                            <hr />
                            <span onClick={() => { copy() }} style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", }}>
                                <p style={{ display: "flex", width: "100%", alignItems: "center", marginBottom: "0px", marginLeft: "-10px" }}>
                                    <label style={{ display: "flex", alignItems: "center", marginLeft: "5px", color: "#20D489" }} >
                                        {translation.header.menu.chave}:
                                        <TooltipMenu>
                                            <FiHelpCircle size={17} color={"#20D489"} />
                                            <label>{translation.header.menu.identificacao}</label>
                                        </TooltipMenu>
                                    </label>
                                </p>
                            </span>
                            <span onClick={() => { copy() }}>
                                <label style={{ width: "100%", display: "flex", alignItems: "center", }}>
                                    <AiOutlineCopy size={19} color={"gray"} />
                                    <label style={{ textTransform: "uppercase", marginLeft: "7px" }} >{JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.chave_empreender}</label>
                                </label>
                            </span>
                            {/* <span>
                                <FaPiggyBank size={20} color={"gray"} />
                                <label>Suporte</label>
                            </span> */}
                            {process.env.REACT_APP_HABILITAR_PLUS ?
                                <span onClick={() => { verifiSave2("plus") }}                                >
                                    <img alt="Empreender Plus" width="16" src="https://empreender.nyc3.digitaloceanspaces.com/landingpage/landingpage/ap8n31063713.svg" />
                                    <label style={{ marginLeft: "8px" }}>Empreender Plus</label>
                                </span>
                                : ''}

                            <span onClick={() => { verifiSave("/conta") }}                            >
                                <AiOutlineLogout size={20} color={"gray"} />
                                <label style={{ marginLeft: "7px" }}>{translation.header.menu.minhaConta}</label>
                            </span>

                            <span style={{ marginBottom: "0px" }} onClick={() => { verifiSave2("logout") }}                            >
                                <AiOutlineLogout size={20} color={"gray"} />
                                <label style={{ marginLeft: "7px" }}>{translation.header.menu.sair}</label>
                            </span>
                        </UserDropdownSubMenu>
                    </UserDropdown>



                    <Notify onClick={() => { setVisibleMenuNotify(true) }}  >
                        {/* <IoNotifications size={30} color={"#A1A5B7"} /> */}
                        <img style={{ color: "#718eb8" }} onClick={() => { setVisibleMenuNotify(true) }} width={40} src={iconNotifyNew} />

                        {
                            listNotify.data.data?.newReview > 0 || listNotify.data.data?.newAsk > 0 ?
                                <QtdNotify>
                                    <label>{listNotify.data.data?.newReview + listNotify.data.data?.newAsk}</label>
                                </QtdNotify> :
                                <></>
                        }
                        <SubMenuNotify visible={visibleMenuNotify}>
                            {
                                listNotify.data.data?.newReview == 0 && listNotify.data.data?.newAsk == 0 ?
                                    <span>
                                        <label>0 {translation.header.notificacoes.notificacoes}</label>
                                    </span> :
                                    <>
                                        {
                                            listNotify.data.data?.newReview > 0 &&
                                            <span style={{ marginBottom: "5px" }} onClick={() => { verifiSave("/avaliacoes") }}                                            >
                                                <label>{getMessageReviews(listNotify.data.data?.newReview)}</label>
                                            </span>
                                        }
                                        {
                                            listNotify.data.data?.newAsk > 0 &&
                                            <span onClick={() => { verifiSave("/perguntas") }}                                            >
                                                <label>{getMessageQuestions(listNotify.data.data?.newAsk)}</label>
                                            </span>
                                        }

                                    </>
                            }

                        </SubMenuNotify >
                    </Notify >


                    {/* {burgerMenu ?
                        <GiHamburgerMenu id="burger__id" className="hideBugerMenu" color={"gray"} size={25} onClick={handleBurgerMenuClick} style={{ cursor: 'pointer', marginLeft: '16px', marginRight: "5px" }} />
                        :
                        <RiCloseFill id="burger__id" className="hideBugerMenu" color={"gray"} size={25} onClick={handleBurgerMenuClick} style={{ cursor: 'pointer', marginLeft: '16px' }} />
                    } */}
                </SectionOptions >
            </Container >
            <AlertClose margin={"20px 30px 0px 280px"}
                visible={
                    JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "shopify" &&
                        new Date(JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.updated_at) < new Date("2022-07-01") ?
                        true : false
                }
                close={false}>
                <label>{translation.header.notificacoes.shopifyAlerta}</label>
            </AlertClose>
        </>
    )
}

export default Header
