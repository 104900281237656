import { addDays } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { GrFormClose } from "react-icons/gr";
import { AiFillTag, AiOutlineNumber } from "react-icons/ai";
import { BsFillImageFill } from "react-icons/bs";
import { FaBoxOpen } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import ModalPreview from "../modal-preview";
import { BiCopy } from "react-icons/bi";

import { CheckCustom, Container, Error, Title, FormCustom, Close, ButtonCustom, InputCustomText, InputCustomDate, BoxInput, TextAreaCustom } from "./styled";

import { getTranslation } from "../../../../translations";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};



const ModalCode = (props) => {

    //console.log("teste")
    const [visibleModalPreview, setVisibleModalPreview] = useState(false)
    const dispatch = useDispatch()
    const [tagForm, setTagForm] = useState(`<iframe id="depoimento-review-iframe" style="border:none;" width=100% src="${process.env.REACT_APP_REVIEW_URL}/reviews/${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.user_token}/${props.item.product_id}/${JSON.parse(localStorage.getItem("depoimentos@login"))?.integration.platform}"></iframe>`)
    const [tagStar, setTagStar] = useState(`<div class="js-product-name"> </div>`)
    const [scriptForm, setScriptForm] = useState(`<script type="text/javascript" src="${process.env.REACT_APP_SCRIPT_CUSTOM}" ></script>`)

    const copy = (tagCode) => {
        if (tagCode == "form") {
            navigator.clipboard.writeText(tagForm + scriptForm)
            toast.success("Código copiado.")
        }
        else if (tagCode == "star") {
            navigator.clipboard.writeText(tagStar)
            toast.success("Código copiado.")
        }
    }

    useEffect(() => {
        //console.log("props modalcode", props)
    }, [])

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <Container>
                <ModalPreview visible={visibleModalPreview} setVisibleModal={setVisibleModalPreview} data={tagForm + scriptForm} />
                <Close onClick={() => { props.setVisibleModal(false) }}><IoIosClose size={25} color={"gray"} /></Close>
                <Title><strong>{translation.products.modalCode.codigos}</strong></Title>
                <FormCustom>
                    <BoxInput>
                        <label>{translation.products.modalCode.codigoFormulario} <BiCopy onClick={() => copy("form")} color={"gray"} size={20} /></label>
                        <TextAreaCustom rows={5} name={"message"} value={tagForm + scriptForm} onChange={() => { }} />
                    </BoxInput>
                    <BoxInput>
                        <label>{translation.products.modalCode.codigoEstrelas} <BiCopy onClick={() => copy("star")} color={"gray"} size={20} /></label>
                        <TextAreaCustom rows={2} name={"message"} value={tagStar} onChange={() => { }} />
                    </BoxInput>
                </FormCustom>
                {/* <ButtonCustom type="button" onClick={() => setVisibleModalPreview(true)}>Preview</ButtonCustom> */}
                {/* <ButtonCustom type="button" onClick={() => copy()}>Copiar</ButtonCustom> */}
            </Container>
        </Modal>
    )
}

export default ModalCode
