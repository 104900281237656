import { useEffect } from 'react';
import { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { GrFormClose } from "react-icons/gr";

import { Container, Title, Close, FormCustom, BoxInput, TextAreaCustom, ButtonCustom } from "./styled";
import { CounterItemsCircle } from '../../../thema/components/box-config-email/styled';
import { getTranslation } from '../../../../translations';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};

const ModalQuestion = (props) => {

    const dispatch = useDispatch()
    const [replies, setReplies] = useState(props.item.reply_message == null ? "" : props.item.reply_message)
    const statusAddReplies = useSelector(state => state.reducerAddReplies)
    const statusUpdateReplies = useSelector(state => state.reducerUpdateReplies)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        if (!(statusAddReplies.loading == false && !statusAddReplies.error == false && statusAddReplies.data.length == 0)) {
            const id = 0
            if (statusAddReplies.loading) {
                toast.loading(translation.questions.modal.aguarde, { toastId: id })
            } else {
                if (statusAddReplies.error) toast.update(id, { render: translation.questions.modal.erroResposta, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.questions.modal.respostaSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    props.setVisibleModal(false)
                }
            }
        }
    }, [statusAddReplies])

    useEffect(() => {
        if (!(statusUpdateReplies.loading == false && !statusUpdateReplies.error == false && statusUpdateReplies.data.length == 0)) {
            const id = "statusUpdateReplies"
            if (statusUpdateReplies.loading) {
                toast.loading(translation.questions.modal.aguarde, { toastId: id })
            } else {
                if (statusUpdateReplies.error) toast.update(id, { render: translation.questions.modal.erroAtualizar, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                else {
                    toast.update(id, { render: translation.questions.modal.respostaAtualizada, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    props.setVisibleModal(false)
                }
            }
        }
    }, [statusUpdateReplies])

    const save = () => {

       

        if (!replies.trim() == "") {
            //alert("Save()")
            if (props.item.reply_message == null) {
               
                dispatch({
                    type: "SAGA_ADD_REPLIES",
                    payload: {
                        ask_id: props.item.id,
                        message: replies
                    }
                })
            } else {
               
                dispatch({
                    type: "SAGA_UPDATE_REPLIES",
                    payload: {
                        id: props.item.reply_id,
                        message: replies
                    }
                })
            }

        }
    }

    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => props.setVisibleModal(false)}
        >
            {/* <Container>
                <p> {props.item.message} </p>
                <textarea value={replies} onChange={(e) => { setReplies(e.target.value) }} />
                <Actions>
                    <button onClick={() => { props.setVisibleModal(false) }} >Fechar</button>
                    <button onClick={() => { save() }} >Salvar</button>
                </Actions>
            </Container> */}

            <Container>
                <Close onClick={() => { props.setVisibleModal(false); setReplies(props.item.reply_message == null ? "" : props.item.reply_message) }}><GrFormClose size={25} color={"#5e6278"} /></Close>
                <Title><strong>{translation.questions.modal.responderPergunta}</strong></Title>
                <FormCustom>
                    <BoxInput>
                        <label>{translation.questions.modal.pergunta}</label>
                        <TextAreaCustom disabled rows={5} placeholder={translation.questions.modal.ex} name={"description"} value={props.item.message} />
                        {
                            //formik.errors.description && formik.touched.description && <Error>{formik.errors.description}</Error>
                        }
                    </BoxInput>
                    <BoxInput style={{ position: "relative" }}>
                        <label>{translation.questions.modal.resposta}</label>
                        <TextAreaCustom maxLength="255" rows={5} name={"message"} value={replies} onChange={(e) => { setReplies(e.target.value) }} />
                        {
                            //formik.errors.message && formik.touched.message && <Error>{formik.errors.message}</Error>
                        }
                        <CounterItemsCircle porc={`${Math.round(replies.length / 255 * 100)}%`}>
                            <label>{255 - replies.length}</label>
                        </CounterItemsCircle>
                    </BoxInput>
                </FormCustom>
                <ButtonCustom onClick={() => save()} type="button">{translation.questions.modal.salvar}</ButtonCustom>
            </Container>

        </Modal>
    )
}

export default ModalQuestion
