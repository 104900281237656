import { PaginateNav, PaginateInfor, Container, Paginate, Filters, BoxLoading, Search, Check, DropDown, Infor, ListCard, ButtonAdd, ActionsSelect, ActionsSelectButton, ButtonCustom, ContainerInfor, BoxInforInitial, BoxInforInitialLeft, BoxMessage, BoxInforInitialRight } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import { AiOutlineSearch, AiOutlineLoading } from "react-icons/ai";
import { IoIosAdd, IoIosAddCircle } from "react-icons/io";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { RiCheckFill } from "react-icons/ri";
import { useState } from "react";
import Card from "../../components/card";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalAdd from "../../components/modal-add";
import ModalListReviews from "../../components/modal-list-reviews";
import ModalListQuestions from "../../components/modal-list-reviews";
import { isVisibleScript } from "../../../../utils/displayScript";
import AlertInfor from "../../../../components/Alert";
import { toast } from "react-toastify";
import { AiOutlineCheck, AiFillEyeInvisible, AiOutlineDelete } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import CardLoading from "../../components/card-loading";
import ModalInforVideo from "../../../../components/modal-infor-video";
import ModalInforVideoIntegration from "../../../../components/modal-infor-video";
import ModalInforVideoClean from "../../../../components/modal-infor-video";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import { getTranslation } from "../../../../translations";
import { VscFileCode } from "react-icons/vsc";
import ModalCodeHome from "../../components/modal-code-home";
import LoadingLogo from "../../../../components/Loading-logo";

const Home = () => {

    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const [visibleModalAdd, setVisibleModalAdd] = useState(false)
    const [visibleModalListQuestions, setVisibleModalListQuestions] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    //teste
    const [visibleModalCodeHome, setVisibleModalCodeHome] = useState(false)
    const [controlUpdate, setControlUpdate] = useState(false)

    const products = useSelector(state => state.reducerProducts)
    const statusUpdateProduct = useSelector(state => state.reducerUpdateProduct)
    const statusDeleteProduct = useSelector(state => state.reducerDeleteProduct)
    const statusAddProduct = useSelector(state => state.reducerAddProduct)

    const [visibleModalInforProducts, setVisibleModalInforProducts] = useState(false)
    const [visibleModalInforProductsIntegration, setVisibleModalInforProductsIntegration] = useState(false)
    const [visibleModalInforProductsClean, setVisibleModalInforProductsClean] = useState(false)

    const [checkedAll, setCheckedAll] = useState(false)
    const statusDeleteSelectProducts = useSelector(state => state.reducerDeleteSelectProducts)
    const statusUpdateSelectProducts = useSelector(state => state.reducerUpdateSelectProducts)
    const productsSelect = useSelector(state => state.reducerControlSelectProducts)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        if (!statusUpdateProduct.loading && !statusUpdateProduct.error && statusUpdateProduct.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_PRODUCTS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [statusUpdateProduct])

    useEffect(() => {
        if (!statusDeleteProduct.loading && !statusDeleteProduct.error && statusDeleteProduct.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_PRODUCTS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [statusDeleteProduct])

    useEffect(() => {
        if (!statusAddProduct.loading && !statusAddProduct.error && statusAddProduct.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_PRODUCTS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [statusAddProduct])

    useEffect(() => {
        if (typeof search !== "undefined") {
            dispatch({
                type: "SAGA_LIST_PRODUCTS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [])

    const goSearch = () => {
        dispatch({
            type: "SAGA_LIST_PRODUCTS", payload: {
                search: search,
                perPage: perPage,
                page: page
            }
        })
    }

    useEffect(() => {
        goSearch()
    }, [page])

    const countItens = () => {
        let result = 0
        if (page == products.data?.meta?.last_page) result = products.data?.meta?.total
        else result = page * perPage
        return result
    }


    useEffect(() => {
        if (!statusUpdateSelectProducts.loading && !statusUpdateSelectProducts.error && statusUpdateSelectProducts.data.length == 0) {
        } else {
            const id = "statusUpdateSelectReviews"
            if (statusUpdateSelectProducts.loading) {
                toast.loading(translation.products.aguarde, { toastId: id })
            } else {
                if (statusUpdateSelectProducts.error) {
                    toast.update(id, { render: translation.products.errorOperacao, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.products.operacaoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //setVisibleModalConfirmation(false)
                    handleChecked(false)
                    goSearch()
                }
            }
        }
    }, [statusUpdateSelectProducts])

    useEffect(() => {
        if (!statusDeleteSelectProducts.loading && !statusDeleteSelectProducts.error && statusDeleteSelectProducts.data.length == 0) {
        } else {
            const id = "statusDeleteSelectReviews"
            if (statusDeleteSelectProducts.loading) {
                toast.loading(translation.products.aguarde, { toastId: id })
            } else {
                if (statusDeleteSelectProducts.error) {
                    toast.update(id, { render: translation.products.errorExcluir, type: "error", isLoading: false, hideProgressBar: false, autoClose: 3000, closeOnClick: true });
                } else {
                    toast.update(id, { render: translation.products.excluidoSucesso, type: "success", isLoading: false, hideProgressBar: false, autoClose: 2000, closeOnClick: true })
                    //setVisibleModalConfirmation(false)
                    handleChecked(false)
                    goSearch()
                }
            }
        }
    }, [statusDeleteSelectProducts])

    // const updateAll = (action) => {
    //     dispatch({
    //         type: "SAGA_UPDATE_SELECT_PRODUCTS",
    //         payload: {
    //             itens: productsSelect,
    //             action: action
    //         }
    //     })
    // }

    const deleteAll = () => {
        //if (selectReviews.length > 0) dispatch({ type: "SAGA_DELETE_SELECT_REVIEWS", payload: { itens: selectReviews } })
        dispatch({
            type: "SAGA_DELETE_SELECT_PRODUCTS",
            payload: {
                itens: productsSelect
            }
        })
    }


    const handleChecked = (checked) => {
        setCheckedAll(checked)
    }

    useEffect(() => {
        //console.log("productsSelect : ", productsSelect)
    }, [productsSelect])

    useEffect(() => {
        //console.log("products", products?.data, products.data?.data?.length)
    }, [products])

    const getInforProduct = () => {
        if (
            products.data?.length <= 0 &&
            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform !== "custom" &&
            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform !== "hotmart" &&
            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform !== "landpage"
        ) {
            return (
                <label>
                    {translation.products.nenhumProduto}.
                    <strong onClick={() => { history.push("/integracoes") }}>{translation.products.cliqueAqui}</strong>
                    {translation.products.integrarPlataforma}
                </label>
            )

        } else if (products.data?.length <= 0) {
            return (
                <label>
                    {translation.products.nenhumProduto},
                    <strong onClick={() => { setVisibleModalAdd(true) }}>{translation.products.cliqueAqui}</strong>
                    {translation.products.importarProduto}
                </label>
            )

        }
    }

    useEffect(() => {
        //console.log("teste update", controlUpdate)
        if (controlUpdate == true) {
            dispatch({
                type: "SAGA_LIST_PRODUCTS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
            setControlUpdate(false)
        }
    }, [controlUpdate])



    return (
        <Body>
            <ModalInforVideo visible={visibleModalInforProducts} setVisibleModal={setVisibleModalInforProducts} video={"https://www.youtube.com/embed/j-vJcVcwoVU"} />
            <ModalInforVideoIntegration visible={visibleModalInforProductsIntegration} setVisibleModal={setVisibleModalInforProductsIntegration} video={"https://www.youtube.com/embed/Ve4aHQ3y1-s"} />
            <ModalInforVideoClean visible={visibleModalInforProductsClean} setVisibleModal={setVisibleModalInforProductsClean} video={"https://www.youtube.com/embed/2XA-aqmmXvQ"} />
            <ModalCodeHome visible={visibleModalCodeHome} setVisibleModal={setVisibleModalCodeHome} />

            {/* <ModalListQuestions visible={visibleModalListQuestions} setVisibleModal={setVisibleModalListQuestions} /> */}
            <ModalAdd visible={visibleModalAdd} setVisibleModal={setVisibleModalAdd} />
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1" }} >

                <Header />
                {/* <AlertInfor margin={"30px 30px 0px 30px"}>
                    <p>Para que os cupons criados no lily funcionem corretamente é necessário que o mesmo cupom exista na sua loja. (configure na sua plataforma, ex.: Nuvemshop, Shopify, Yampi...)</p>
                </AlertInfor> */}
                <Contents>
                    <Container>

                        {products.loading ?
                            // <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
                            //     {Array(5).fill(<CardLoading />)}
                            // </div> 
                            <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                {/* {Array(6).fill(<CardLoading />)} */}
                                <LoadingLogo />
                            </div>
                            :
                            typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == "undefined" || JSON.parse(localStorage.getItem("depoimentos@login"))?.integration == null ?
                                <ContainerInfor ContainerInfor >
                                    <BoxMessage>
                                        <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>

                                        <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                            {translation.products.nenhumaIntegracao}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { history.push("/integracoes") }} >{translation.products.cliqueAqui}</strong>.
                                            {/* {translation.products.comoFunciona} */}
                                        </label>
                                        {/* <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "10px", fontWeight: "500", fontSize: "12px" }}>
                                            Não encontrou sua integração?
                                            <strong style={{ marginLeft: "5px", cursor: "pointer" }}>Clique aqui</strong>
                                            , fale com nosso suporte.
                                        </label> */}
                                    </BoxMessage>
                                </ContainerInfor> :
                                products.data?.default == true && products.data?.data.length == 0 ?
                                    <BoxMessage>
                                        <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                        <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                            {translation.products.naoPossui}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { setVisibleModalInforProductsClean(true) }} >{translation.products.cliqueAqui}</strong>.
                                            {/* {translation.products.comoFunciona} */}
                                        </label>
                                    </BoxMessage> :
                                    <>
                                        <AlertCloseBoss close={true} margin={"0px 0px 20px 0px"} visible={true} >
                                            <label style={{ alignItems: "center", margin: "0px" }} >
                                                {translation.products.muitoMais}
                                            </label>
                                            <span style={{}}>
                                                <label onClick={() => { setVisibleModalInforProducts(true) }} style={{ display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                                                    {translation.products.verComo}
                                                </label>
                                            </span>
                                        </AlertCloseBoss>
                                        <Filters>
                                            <Search >
                                                <input
                                                    type={"text"}
                                                    placeholder={translation.products.pesquisar}
                                                    value={search}
                                                    onChange={(e) => { setSearch(e.target.value) }} />
                                                <span onClick={() => { setPage(1); goSearch() }}>
                                                    <AiOutlineSearch size={25} color={"gray"} />
                                                </span>
                                            </Search>
                                            {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ?
                                                <div style={{ display: "flex", gap: "20px" }}>
                                                    <ButtonAdd onClick={() => { setVisibleModalCodeHome(true) }}>
                                                        <label>
                                                            Código homepage
                                                            {/* {translation.products.importarManual} */}
                                                        </label>
                                                    </ButtonAdd>
                                                    <ButtonAdd onClick={() => { setVisibleModalAdd(true) }}>
                                                        <label>
                                                            {translation.products.importarManual}
                                                        </label>
                                                    </ButtonAdd>
                                                </div> :
                                                <></>}

                                        </Filters>

                                        {products.data?.data?.length > 0 ?
                                            <>
                                                <ActionsSelect>
                                                    <span>
                                                        <input type="checkbox" checked={checkedAll} onChange={(e) => { handleChecked(e.target.checked) }} />
                                                    </span>
                                                    <label>{translation.products.selecionarTodos}</label>

                                                    <ActionsSelectButton>
                                                        {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                                            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ||
                                                            JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ?
                                                            <>
                                                                {/* <span>
                                                                    <VscFileCode size={20} color={"gray"} onClick={() => { productsSelect.length > 0 ? deleteAll() : toast.warning(translation.products.nenhumItem) }} />
                                                                    <label>{translation.products.desejaExcluir}</label>
                                                                </span> */}
                                                                <span>
                                                                    <AiOutlineDelete size={20} color={"gray"} onClick={() => { productsSelect.length > 0 ? deleteAll() : toast.warning(translation.products.nenhumItem) }} />
                                                                    <label>{translation.products.desejaExcluir}</label>
                                                                </span>
                                                            </>
                                                            : null
                                                        }
                                                    </ActionsSelectButton>
                                                </ActionsSelect>
                                                <ListCard>
                                                    {products.data.data?.map((item) => { return <Card setControlUpdate={setControlUpdate} key={item.id} checkedAll={checkedAll} item={item} /> })}
                                                    <Paginate>
                                                        <PaginateNav>
                                                            <span onClick={() => { if (page != 1) setPage(page - 1) }}>
                                                                <MdKeyboardArrowLeft size={35} color={products.data.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                                            </span>
                                                            <label>{products.data?.meta?.current_page}</label>
                                                            <span onClick={() => { if (page < products.data?.meta?.last_page) setPage(page + 1) }}>
                                                                <MdKeyboardArrowRight size={35} color={products.data.meta?.last_page == page ? "gray" : "#19B674"} />
                                                            </span>
                                                        </PaginateNav>
                                                        <PaginateInfor>
                                                            <label>{translation.products.total} {countItens()} - {products.data?.meta?.total}</label>
                                                        </PaginateInfor>
                                                    </Paginate>
                                                </ListCard>
                                            </>
                                            :
                                            <label style={{ marginTop: "50px", display: "flex", flex: "1", justifyContent: "center" }}>{translation.products.nenhumProduto}.</label>}
                                    </>}


                        {/* <Filters>
                            <Search >
                                <input
                                    type={"text"}
                                    placeholder={"Pesquisar"}
                                    value={search}
                                    onChange={(e) => { setSearch(e.target.value) }} />
                                <span onClick={() => { setPage(1); goSearch() }}>
                                    <AiOutlineSearch size={25} color={"gray"} />
                                </span>
                            </Search>
                            {
                                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                    JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ||
                                    JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ?
                                    <ButtonAdd onClick={() => { setVisibleModalAdd(true) }}>
                                        <label>
                                            Importar manual
                                        </label>
                                     </ButtonAdd> :
                                    <></>
                            }

                        </Filters> */}

                        {/* {
                            products.loading == false && products.data?.data?.length > 0 &&
                            <ActionsSelect>
                                <span>
                                    <input type="checkbox" checked={checkedAll} onChange={(e) => { handleChecked(e.target.checked) }} />
                                </span>
                                <label>Selecionar todos</label>

                                <ActionsSelectButton>
                                    {JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "hotmart" ||
                                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "custom" ||
                                        JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform == "landpage" ?
                                        <span>
                                            <AiOutlineDelete size={20} color={"gray"} onClick={() => { productsSelect.length > 0 ? deleteAll() : toast.warning("Nenhum item foi selecionado.") }} />
                                            <label>Deseja excluir todos os produtos selecionados?</label>
                                        </span> : null
                                    }
                                </ActionsSelectButton>
                            </ActionsSelect>
                        } */}
                        {/* 
                        <Infor style={{ display: products.loading == false && products.data?.length <= 0 ? "flex" : "none" }}>
                            {
                                getInforProduct()
                            }

                        </Infor> */}
                        {/* <ListCard>
                            {products.loading && <div />}
                            {products.loading ?
                                Array(5).fill(<CardLoading />) :
                                products.data.data?.map((item) => { return <Card key={item.id} checkedAll={checkedAll} item={item} setControlUpdate={setControlUpdate} /> })}

                            {products.data.data?.length > 0 &&
                                <Paginate>
                                    <PaginateNav>
                                        <span onClick={() => {
                                            if (page != 1) setPage(page - 1)
                                        }}>
                                            <MdKeyboardArrowLeft size={35} color={products.data.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                        </span>
                                        <label>{products.data?.meta?.current_page}</label>
                                        <span onClick={() => {
                                            if (page < products.data?.meta?.last_page) setPage(page + 1)
                                        }}>
                                            <MdKeyboardArrowRight size={35} color={products.data.meta?.last_page == page ? "gray" : "#19B674"} />
                                        </span>
                                    </PaginateNav>
                                    <PaginateInfor>
                                        <label>Total {countItens()} - {products.data?.meta?.total}</label>
                                    </PaginateInfor>
                                </Paginate>}

                        </ListCard> */}

                    </Container>
                </Contents>
            </div>
            {isVisibleScript(false)}
        </Body >
    )
}

export default Home
