import { PaginateNav, PaginateInfor, Container, Paginate, Filters, BoxLoading, Search, Check, DropDown, Infor, ListCard, ButtonAdd, BoxAlert, BoxInforInitial, BoxInforInitialLeft, BoxInforInitialRight, ContainerInfor, BoxMessage, NavInfor } from "./styled";
import Body from "../../../../components/Body";
import SideBar from "../../../../components/SideBar";
import Contents from "../../../../components/Contents";
import Header from "../../../../components/Header";
import { AiOutlineSearch, AiOutlineLoading } from "react-icons/ai";
import { IoIosAdd, IoIosAddCircle } from "react-icons/io";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { RiCheckFill, RiAddCircleFill } from "react-icons/ri";
import { useState } from "react";
import Card from "../../components/card";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalAdd from "../../components/modal-add";
import { isVisibleScript } from "../../../../utils/displayScript";
import AlertInfor from "../../../../components/Alert";
import CardLoading from "../../components/card-loading";
import ModalInforVideo from "../../../../components/modal-infor-video";
import AlertCloseBoss from "../../../../components/alert-close-boss";
import { getTranslation } from "../../../../translations";
import LoadingLogo from "../../../../components/Loading-logo";

const Home = () => {

    const [search, setSearch] = useState("")
    const [page, setPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const cupons = useSelector(state => state.reducerCupons)
    const [visibleModalAdd, setVisibleModalAdd] = useState(false)
    const [visibleModalInforCupom, setVisibleModalInforCupom] = useState(false)
    const dispatch = useDispatch()


    const statusUpdateCupom = useSelector(state => state.reducerUpdateCupom)
    const statusDeleteCupom = useSelector(state => state.reducerDeleteCupom)
    const statusAddCupom = useSelector(state => state.reducerAddCupom)

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        if (!statusUpdateCupom.loading && !statusUpdateCupom.error && statusUpdateCupom.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_CUPONS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [statusUpdateCupom])

    useEffect(() => {
        if (!statusDeleteCupom.loading && !statusDeleteCupom.error && statusDeleteCupom.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_CUPONS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [statusDeleteCupom])

    useEffect(() => {
        if (!statusAddCupom.loading && !statusAddCupom.error && statusAddCupom.data.length !== 0) {
            dispatch({
                type: "SAGA_LIST_CUPONS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [statusAddCupom])

    useEffect(() => {
        if (typeof search !== "undefined" || typeof statusQuestion !== "undefined") {
            dispatch({
                type: "SAGA_LIST_CUPONS", payload: {
                    search: search,
                    perPage: perPage,
                    page: page
                }
            })
        }
    }, [])

    const goSearch = () => {
        dispatch({
            type: "SAGA_LIST_CUPONS", payload: {
                search: search,
                perPage: perPage,
                page: page
            }
        })
    }

    useEffect(() => {
        goSearch()
    }, [page])

    const countItens = () => {
        let result = 0
        if (page == cupons.data?.meta?.last_page) result = cupons.data?.meta?.total
        else result = page * perPage
        return result
    }

    return (
        <Body>
            <ModalInforVideo visible={visibleModalInforCupom} setVisibleModal={setVisibleModalInforCupom} video={"https://www.youtube.com/embed/FOabXIwtNRM"} />
            <ModalAdd visible={visibleModalAdd} setVisibleModal={setVisibleModalAdd} />
            <SideBar />
            <div style={{ margin: "0px", padding: "0px", display: "flex", flexDirection: "column", flex: "1" }} >

                <Header>
                    {/* <BoxAlert>
                        <AlertInfor>
                            <p>Para que os cupons criados no lily funcionem corretamente é necessário que o mesmo cupom exista na sua loja. (configure na sua plataforma, ex.: Nuvemshop, Shopify, Yampi...)</p>
                        </AlertInfor>
                    </BoxAlert> */}
                </Header>

                <Contents>
                    <Container>
                        {cupons.loading ?
                            // Array(6).fill(<CardLoading />) 
                            <div style={{ display: "flex", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                {/* {Array(6).fill(<CardLoading />)} */}
                                <LoadingLogo />
                            </div>
                            :
                            cupons.data.data?.length > 0 ?
                                <>
                                    <AlertCloseBoss close={true} margin={"0px 0px 20px 0px"} visible={true} >
                                        <label style={{ alignItems: "center", margin: "0px" }} >
                                            {translation.cupons.aviso}
                                        </label>
                                        <span style={{}}>
                                            <label onClick={() => { setVisibleModalInforCupom(true) }} style={{ display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                                                Ver como funciona
                                            </label>
                                        </span>
                                    </AlertCloseBoss>
                                    <Filters>
                                        <Search >
                                            <input
                                                type={"text"}
                                                placeholder={translation.cupons.pesquisar}
                                                value={search}
                                                onChange={(e) => { setSearch(e.target.value) }} />
                                            <span onClick={() => { setPage(1); goSearch() }}>
                                                <AiOutlineSearch size={25} color={"gray"} />
                                            </span>
                                        </Search>
                                        <span>
                                            <RiAddCircleFill onClick={() => { setVisibleModalAdd(true) }} color={"#54D18C"} size={35} />
                                            <label>{translation.cupons.tooltipAdicionar}</label>
                                        </span>
                                    </Filters>

                                    <ListCard>
                                        {cupons.data.data?.map((item) => { return <Card key={item.id} item={item} /> })}
                                        <Paginate>
                                            <PaginateNav>
                                                <span onClick={() => {
                                                    if (page != 1) setPage(page - 1)
                                                }}>
                                                    <MdKeyboardArrowLeft size={35} color={cupons.data.meta?.current_page == 1 ? "gray" : "#19B674"} />
                                                </span>
                                                <label>{cupons.data?.meta?.current_page}</label>
                                                <span onClick={() => {
                                                    if (page < cupons.data?.meta?.last_page) setPage(page + 1)
                                                }}>
                                                    <MdKeyboardArrowRight size={35} color={cupons.data.meta?.last_page == page ? "gray" : "#19B674"} />
                                                </span>
                                            </PaginateNav>
                                            <PaginateInfor>
                                                <label>{translation.cupons.total} {countItens()} - {cupons.data?.meta?.total}</label>
                                            </PaginateInfor>
                                        </Paginate>
                                    </ListCard>
                                </> :

                                cupons.data?.length == 0 && search != "" ?
                                    <>
                                        <AlertCloseBoss close={true} margin={"0px 0px 20px 0px"} visible={true} >
                                            <label style={{ alignItems: "center", margin: "0px" }} >
                                                {translation.cupons.aviso}
                                            </label>
                                            <span style={{}}>
                                                <label onClick={() => { setVisibleModalInforCupom(true) }} style={{ display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                                                    {translation.cupons.ver}
                                                </label>
                                            </span>
                                        </AlertCloseBoss>
                                        <Filters>
                                            <Search >
                                                <input
                                                    type={"text"}
                                                    placeholder={translation.cupons.pesquisar}
                                                    value={search}
                                                    onChange={(e) => { setSearch(e.target.value) }} />
                                                <span onClick={() => { setPage(1); goSearch() }}>
                                                    <AiOutlineSearch size={25} color={"gray"} />
                                                </span>
                                            </Search>
                                            <span>
                                                <RiAddCircleFill onClick={() => { setVisibleModalAdd(true) }} color={"#54D18C"} size={35} />
                                                <label>{translation.cupons.tooltipAdicionar}</label>
                                            </span>
                                        </Filters>
                                        <label style={{ marginTop: "50px", display: "flex", flex: "1", justifyContent: "center" }} >
                                            {translation.cupons.nenhumCupom}
                                        </label>
                                    </> :
                                    <ContainerInfor >
                                        <AlertCloseBoss close={true} margin={"0px 0px 20px 0px"} visible={true} >
                                            <label style={{ alignItems: "center", margin: "0px" }} >
                                                {translation.cupons.aviso}
                                            </label>
                                            <span style={{}}>
                                                <label onClick={() => { setVisibleModalInforCupom(true) }} style={{ display: "flex", justifyContent: "center", minWidth: "160px", cursor: "pointer", borderRadius: "4px", fontWeight: "400", fontSize: "14px", backgroundColor: "#FFC400", padding: "8px", color: "#755118" }}>
                                                    {translation.cupons.ver}
                                                </label>
                                            </span>
                                        </AlertCloseBoss>
                                        <BoxMessage>
                                            <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
                                            <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                                                {translation.cupons.cupomDisponivel}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { setVisibleModalAdd(true) }} >{translation.cupons.clique}</strong>, {translation.cupons.cadastrarCupom}
                                            </label>
                                        </BoxMessage>
                                    </ContainerInfor>
                        }
                    </Container>
                </Contents>
            </div>
            {isVisibleScript(false)}
        </Body >
    )
}

export default Home
