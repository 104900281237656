import React, { useState } from 'react'
import { Body, BoxActions, BoxItensColors, BoxLoadingCustom, BoxOptions, BoxOptionsColors, ButtonCustom, Circle, Container, CounterItemsCircle, Fill, InputFileCustom, InputFileCustomTeste, InputTextAreaCustom, InputTextCustom, InsideCircle, ItemColor, ItemMenu, MaskFull, Menu, Option, SecondMask, SelectCustom, Title, Wrapper } from './styled'
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { IoColorPaletteOutline } from "react-icons/io5";
import { VscGear } from "react-icons/vsc";
import { toast } from 'react-toastify';
import { AiOutlineMobile, AiOutlineDesktop, AiOutlineReload } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

import { FaUpload } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import imageCompression from 'browser-image-compression';
import { getTranslation } from '../../../../translations';


const BoxConfigEmail = () => {

    const dispatch = useDispatch()
    const config = useSelector(state => state.reducerGetConfig)

    const [isOpenBox, setopenBox] = useState(true)
    const [menuActive, setMenuActive] = useState("color")
    const [layout, setLayout] = useState(config.data.layout)
    const [theme, setTheme] = useState("")

    const [backgroundButton, setBackgroundButton] = useState(config.data.background_button)
    const [colorTextButton, setColorTextButton] = useState(config.data.color_text_button)
    const [backgroundBody, setBackgroundBody] = useState(config.data.background_body)
    const [colorText, setColorText] = useState(config.data.color_text)
    const [colorBorder, setColorBorder] = useState(config.data.color_border)

    const [font, setfont] = useState(config.data.font)
    const [order_review, set_order_review] = useState(config.data.order_reviews)
    const [order_ask, set_order_ask] = useState(config.data.order_asks)
    const [no_review_star, set_no_review_star] = useState(config.data.no_review_star)
    const [visible_button_ask, set_visible_button_ask] = useState(config.data.visible_button_ask)
    const [visible_button_review, set_visible_button_review] = useState(config.data.visible_button_review)
    const [visible_date_review, set_visible_date_review] = useState(config.data.visible_date_review)
    const [itens_per_page, set_itens_per_page] = useState(config.data.itens_per_page)

    const [email, setEmail] = useState(JSON.parse(localStorage.getItem("depoimentos@login"))?.user.email)
    const [request_email_content, set_request_email_content] = useState(config.data.request_email_content)
    const [request_email_subject, set_request_email_subject] = useState(config.data.request_email_subject)
    const [request_email_buttontext, set_request_email_buttontext] = useState(config.data.request_email_buttontext)
    const [request_email_date, set_request_email_date] = useState(config.data.request_email_date)
    const [fontEmail, setfontEmail] = useState(config.data.font_email)
    const [uploading, setUploading] = useState(false)
    const [logo, setLogo] = useState(config.data.request_email_logourl ? config.data.request_email_logourl : "a")

    const [productExemple, setProductExemple] = useState({ image_url: "https://avowhcbwko.cloudimg.io/v7/https://montink.s3.amazonaws.com/mockups/39328/Preto_784729.png", name: "Camiseta Lily", link: "https://montink.com/empreender/camiseta-lily-4rdti" })

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
        //console.log("CONFIG 0000000", backgroundButton)
    }, [backgroundButton])

    useEffect(() => {
        //console.log("CONFIG", order_review)
    }, [order_review])

    const saveEmail = () => {

        //console.log("save", config.data?.id)
        if (typeof logo == "string") {
            dispatch({
                type: "SAGA_UPDATE_CONFIG", payload: {
                    id: config.data?.id,
                    background_button: backgroundButton,
                    color_text_button: colorTextButton,
                    background_body: backgroundBody,
                    color_text: colorText,
                    color_border: colorBorder,
                    request_email: request_email_date == 0 ? 0 : 1,
                    font_email: fontEmail,
                    request_email_buttontext: request_email_buttontext,
                    request_email_date: request_email_date,
                    request_email_subject: request_email_subject,
                    request_email_content: request_email_content,
                }
            })
        } else {
            dispatch({
                type: "SAGA_UPDATE_CONFIG", payload: {
                    id: config.data?.id,
                    background_button: backgroundButton,
                    color_text_button: colorTextButton,
                    background_body: backgroundBody,
                    color_text: colorText,
                    color_border: colorBorder,
                    request_email: request_email_date == 0 ? 0 : 1,
                    font_email: fontEmail,
                    request_email_buttontext: request_email_buttontext,
                    request_email_date: request_email_date,
                    request_email_subject: request_email_subject,
                    request_email_content: request_email_content,
                    image: logo,
                }
            })
        }

    }


    useEffect(() => {
        dispatch({
            type: "CONFIG_CONTROL_SET", payload: {
                background_button: backgroundButton,
                color_text_button: colorTextButton,
                background_body: backgroundBody,
                color_text: colorText,
                color_border: colorBorder,

                font_email: fontEmail,
                request_email_buttontext: request_email_buttontext,
                request_email_date: request_email_date,
                logo: logo,
                request_email_subject: request_email_subject,
                request_email_content: request_email_content,
            }
        })
    }, [
        backgroundButton,
        colorTextButton,
        backgroundBody,
        colorText,
        colorBorder,

        fontEmail,
        request_email_buttontext,
        request_email_date,
        logo,
        request_email_subject,
        request_email_content,
    ])

    // const getDefaultTheme = () => {
    //     const themaDefault = {
    //         id: config.data.id,
    //         primary_color: "#000000",
    //         star_color_primary: "#DAA520",
    //         star_color_secondary: "#D3D3D3",
    //         font_color_primary: "#000000",
    //         font_color_secondary: "#FFFFFF",
    //         background_color_primary: "#FFFFFF",
    //         background_color_secondary: "#F1F1F1",
    //         layout: "lily",
    //         theme: "lily",
    //     }
    //     dispatch({ type: "SAGA_UPDATE_CONFIG", payload: themaDefault })

    // }


    const getDefaultEmail = () => {
        const EmailDefault = {
            id: config.data.id,
            font_email: "Poppins",
            request_email_logourl: "",
            request_email_content: translation.thema.boxConfigEmail.emailContent,
            request_email_subject: translation.thema.boxConfigEmail.emailSubject,
            request_email_buttontext: translation.thema.boxConfigEmail.emailButtontext,
            request_email: 1,
            request_email_date: 30,

            color_text_button: "#FFFFFF",
            background_button: "#000000",
            color_text: "#000000",
            background_body: "#FFFFFF",
            color_border: "#D3D3D3",

        }
        dispatch({ type: "SAGA_UPDATE_CONFIG", payload: EmailDefault })
    }

    async function handleImageUpload(imageFile) {
        //console.log("inicio", imageFile)
        setUploading(true)

        const options = {
            maxSizeMB: 0.6,
            maxWidthOrHeight: 1280,
            useWebWorker: true
        }

        try {
            const compressedFile = await imageCompression(imageFile, options);
            setLogo(compressedFile)
            //console.log("Fim", compressedFile)
            //return compressedFile;
            setUploading(false)
        } catch (error) {
            setUploading(false)
            //console.log(error);
        }
    }

    const senEmail = () => {
        //alert("teste")
        dispatch({
            type: "SAGA_SEND_EMAIL",
            payload: {
                email_logourl: logo,
                email_content: request_email_content,
                email_subject: request_email_subject,
                product_image_url: productExemple.image_url,
                product_name: productExemple.name,
                product_url: productExemple.link,
                email_buttontext: request_email_buttontext,
                email: email,
                color_text_button: colorTextButton,
                background_button: backgroundButton,
                color_text: colorText,
                background_body: backgroundBody,
                color_border: colorBorder,
                font_email: fontEmail
                //star_color: String(formikTheme.values.star_color).replace("#", ""),
                //font_color: String(formikTheme.values.font_color).replace("#", ""),
                //primary_color: String(formikTheme.values.primary_color).replace("#", "")
            }
        })
    }

    useEffect(() => {
       
        if (
            // fontColorPrimary == "#000000" &&
            // fontColorSecondary == "#FFFFFF" &&
            // primaryColor == "#000000" &&
            // backgroundColorPrimary == "#FFFFFF" &&
            // backgroundColorSecondary == "#F1F1F1"

            backgroundButton == "#000000" &&
            colorTextButton == "#FFFFFF" &&
            backgroundBody == "#FFFFFF" &&
            colorText == "#000000" &&
            colorBorder == "#D3D3D3"

        ) {
            
            setTheme("light")
        }
        else if (
            backgroundButton == "#FFFFFF" &&
            colorTextButton == "#000000" &&
            backgroundBody == "#000000" &&
            colorText == "#FFFFFF" &&
            colorBorder == "#808080"
        ) {
            
            setTheme("dark")
        }
        else {
            
            setTheme("custom")
        }

        //console.log("teste teheme", theme)
    }, [
        // starColorPrimary,
        // starColorSecondary,
        backgroundButton,
        colorTextButton,
        backgroundBody,
        colorText,
        colorBorder,
    ])

    useEffect(() => {

        
        switch (theme) {
            case "dark":
                // setStarColorPrimary(config.data.star_color_primary)
                // setStarColorSecondary(config.data.star_color_secondary)
                setBackgroundButton("#FFFFFF")
                setColorTextButton("#000000")
                setBackgroundBody("#000000")
                setColorText("#FFFFFF")
                setColorBorder("#808080")
                break;
            case "light":
                setBackgroundButton("#000000")
                setColorTextButton("#FFFFFF")
                setBackgroundBody("#FFFFFF")
                setColorBorder("#D3D3D3")
                setColorText("#000000")
                break;
            default:
                break;
        }
    }, [theme])

    return ( null
        // <Container open={isOpenBox}>
        //     <Title open={isOpenBox}>
        //         <span>
        //             {isOpenBox ?
        //                 <IoIosArrowForward onClick={() => { setopenBox(false) }} size={15} /> :
        //                 <IoIosArrowBack onClick={() => { setopenBox(true) }} size={15} />}
        //         </span>
        //         <label>Editar</label>
        //         <div>
        //             <AiOutlineDesktop onClick={() => { setopenBox(true) }} color={"#ffffff"} size={12} />
        //         </div>
        //         {/* <div>
        //             <AiOutlineMobile onClick={() => { setopenBox(true) }} color={"gray"} size={12} />
        //         </div> */}
        //     </Title>
        //     <Body open={isOpenBox}>
        //         <Menu>

        //             <ItemMenu onClick={() => { setMenuActive("color") }} menuActive={menuActive == "color" ? true : false}>
        //                 <IoColorPaletteOutline onClick={() => { setopenBox(true) }} size={16} />
        //                 <label>Cores</label>
        //             </ItemMenu>
        //             <ItemMenu onClick={() => { setMenuActive("config") }} menuActive={menuActive == "config" ? true : false}>
        //                 <VscGear onClick={() => { setopenBox(true) }} size={16} />
        //                 <label>Conteúdo</label>
        //             </ItemMenu>

        //         </Menu>
        //         {
        //             menuActive == "color" ?
        //                 <BoxOptionsColors>

        //                     <Option>
        //                         <label>Combinação de cores</label>
        //                         <SelectCustom name="select_theme_reviews" onChange={(event) => { setTheme(event.target.value) }}                        >
        //                             <option value="light" selected={theme == "light" ? true : false}>Light</option>
        //                             <option value="dark" selected={theme == "dark" ? true : false}>Dark</option>
        //                             <option disabled value="custom" selected={theme == "custom" ? true : false}>Personalizado</option></SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Cores do email</label>
        //                         <BoxItensColors>
        //                             <ItemColor>
        //                                 <input type={"color"} value={backgroundButton} onChange={(e) => setBackgroundButton(e.target.value)} />
        //                                 <label>Cor do botão</label>
        //                             </ItemColor>

        //                             <ItemColor>
        //                                 <input type={"color"} value={colorTextButton} onChange={(e) => setColorTextButton(e.target.value)} />
        //                                 <label>Cor do texto do botão</label>
        //                             </ItemColor>

        //                             <ItemColor>
        //                                 <input type={"color"} value={backgroundBody} onChange={(e) => setBackgroundBody(e.target.value)} />
        //                                 <label>Cor de fundo</label>
        //                             </ItemColor>

        //                             <ItemColor>
        //                                 <input type={"color"} value={colorText} onChange={(e) => setColorText(e.target.value)} />
        //                                 <label>Cor do texto</label>
        //                             </ItemColor>

        //                             <ItemColor>
        //                                 <input type={"color"} value={colorBorder} onChange={(e) => setColorBorder(e.target.value)} />
        //                                 <label>Cor da borda</label>
        //                             </ItemColor>
        //                         </BoxItensColors>
        //                     </Option>

        //                     <BoxActions>
        //                         <ButtonCustom onClick={() => { saveEmail() }} type={"save"}>
        //                             <label>Salvar</label>
        //                             <FaCheck color={"#FFFFFF"} size={12} />
        //                         </ButtonCustom>
        //                         <ButtonCustom onClick={() => { getDefaultEmail() }} type={"reset"}>
        //                             <label>Restaurar</label>
        //                             <AiOutlineReload color={"#353535"} size={12} />
        //                         </ButtonCustom>
        //                     </BoxActions>

        //                 </BoxOptionsColors> :

        //                 <BoxOptions>

        //                     <Option>
        //                         <label>Fonte</label>
        //                         <SelectCustom onChange={(event) => { setfontEmail(event.target.value) }}                        >
        //                             <option value="Poppins" selected={fontEmail == "Poppins" ? true : false}>Poppins</option>
        //                             <option value="Courier" selected={fontEmail == "Courier" ? true : false} >Courier</option>
        //                             <option value="Times" selected={fontEmail == "Times" ? true : false} >Times</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Texto do botão</label>
        //                         <InputTextCustom>
        //                             <input type={"text"} value={request_email_buttontext} onChange={(e) => { set_request_email_buttontext(e.target.value) }} />
        //                         </InputTextCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Tempo para envio</label>
        //                         <SelectCustom name="select" onChange={(event) => { set_request_email_date(event.target.value) }}>
        //                             <option value="0" selected={config.data.request_email_date == 0 ? true : false}>Nunca</option>
        //                             <option value="1" selected={config.data.request_email_date == 1 ? true : false} >01 dias após a compra</option>
        //                             <option value="2" selected={config.data.request_email_date == 2 ? true : false} >02 dias após a compra</option>
        //                             <option value="3" selected={config.data.request_email_date == 3 ? true : false} >03 dias após a compra</option>
        //                             <option value="4" selected={config.data.request_email_date == 4 ? true : false} >04 dias após a compra</option>
        //                             <option value="5" selected={config.data.request_email_date == 5 ? true : false} >05 dias após a compra</option>
        //                             <option value="6" selected={config.data.request_email_date == 6 ? true : false} >06 dias após a compra</option>
        //                             <option value="7" selected={config.data.request_email_date == 7 ? true : false} >07 dias após a compra</option>
        //                             <option value="8" selected={config.data.request_email_date == 8 ? true : false} >08 dias após a compra</option>
        //                             <option value="9" selected={config.data.request_email_date == 9 ? true : false} >09 dias após a compra</option>
        //                             <option value="10" selected={config.data.request_email_date == 10 ? true : false} >10 dias após a compra</option>
        //                             <option value="11" selected={config.data.request_email_date == 11 ? true : false} > 11 dias após a compra</option>
        //                             <option value="12" selected={config.data.request_email_date == 12 ? true : false} >12 dias após a compra</option>
        //                             <option value="13" selected={config.data.request_email_date == 13 ? true : false} >13 dias após a compra</option>
        //                             <option value="14" selected={config.data.request_email_date == 14 ? true : false} >14 dias após a compra</option>
        //                             <option value="15" selected={config.data.request_email_date == 15 ? true : false} >15 dias após a compra</option>
        //                             <option value="16" selected={config.data.request_email_date == 16 ? true : false} >16 dias após a compra</option>
        //                             <option value="17" selected={config.data.request_email_date == 17 ? true : false} >17 dias após a compra</option>
        //                             <option value="18" selected={config.data.request_email_date == 18 ? true : false} >18 dias após a compra</option>
        //                             <option value="19" selected={config.data.request_email_date == 19 ? true : false} >19 dias após a compra</option>
        //                             <option value="20" selected={config.data.request_email_date == 20 ? true : false} >20 dias após a compra</option>
        //                             <option value="21" selected={config.data.request_email_date == 21 ? true : false} >21 dias após a compra</option>
        //                             <option value="28" selected={config.data.request_email_date == 28 ? true : false} >28 dias após a compra</option>
        //                             <option value="30" selected={config.data.request_email_date == 30 ? true : false} >30 dias após a compra</option>
        //                             <option value="35" selected={config.data.request_email_date == 35 ? true : false} >35 dias após a compra</option>
        //                             <option value="42" selected={config.data.request_email_date == 42 ? true : false} >42 dias após a compra</option>
        //                             <option value="49" selected={config.data.request_email_date == 49 ? true : false} >49 dias após a compra</option>
        //                             <option value="56" selected={config.data.request_email_date == 56 ? true : false} >56 dias após a compra</option>
        //                             <option value="63" selected={config.data.request_email_date == 63 ? true : false} >63 dias após a compra</option>
        //                             <option value="70" selected={config.data.request_email_date == 70 ? true : false} >70 dias após a compra</option>
        //                         </SelectCustom>
        //                     </Option>

        //                     <Option>
        //                         <label>Logomarca (largura 400px)</label>

        //                         <InputFileCustomTeste>
        //                             <input style={{ display: "none" }} id="request_email_logourl" type={"file"} onChange={async (event) => { await handleImageUpload(event.currentTarget.files[0]) }} />
        //                             <input type={"text"} value={typeof logo == "string" ? logo : logo.name} />
        //                             <label for="request_email_logourl">
        //                                 {uploading ?
        //                                     <BoxLoadingCustom >
        //                                         <div>
        //                                             <AiOutlineLoading style={{ margin: "0px" }} size={12} color={"black"} />
        //                                         </div>
        //                                     </BoxLoadingCustom> :
        //                                     <FaUpload style={{ margin: "0px", cursor: "pointer" }} size={15} />}
        //                             </label>
        //                         </InputFileCustomTeste>

        //                     </Option>

        //                     <Option style={{ position: "relative" }}>
        //                         <label>Assunto e-mail</label>
        //                         <InputTextAreaCustom
        //                             value={request_email_subject}
        //                             rows={4}
        //                             maxLength="255"
        //                             placeholder={"Assunto de e-mail"}
        //                             onChange={(e) => { set_request_email_subject(e.target.value) }}
        //                         //name={"request_email_subject"}
        //                         //value={formikEmail.values.request_email_subject}
        //                         //onChange={formikEmail.handleChange}
        //                         />
        //                         <CounterItemsCircle porc={`${Math.round(request_email_subject?.length / 255 * 100)}%`} colorBorder={request_email_subject?.length < 255 ? "green" : "red"}>
        //                             <label>{255 - request_email_subject?.length}</label>
        //                         </CounterItemsCircle>
        //                     </Option>

        //                     <Option style={{ position: "relative" }}>
        //                         <label>Conteúdo do e-mail</label>
        //                         <InputTextAreaCustom
        //                             value={request_email_content}
        //                             rows={12}
        //                             maxLength="255"
        //                             placeholder={"Conteúdo do e-mail"}
        //                             onChange={(e) => { set_request_email_content(e.target.value) }}
        //                         //name={"request_email_subject"}
        //                         //value={formikEmail.values.request_email_subject}
        //                         //onChange={formikEmail.handleChange}
        //                         />
        //                         <CounterItemsCircle porc={`${Math.round(request_email_content?.length / 255 * 100)}%`} colorBorder={request_email_content?.length < 255 ? "green" : "red"}>
        //                             <label>{255 - request_email_content?.length}</label>
        //                         </CounterItemsCircle>
        //                         {/* <label>{Math.round(request_email_content?.length / 255 * 100)}</label> */}
        //                     </Option>

        //                     <Option>
        //                         <label>E-mail de teste</label>
        //                         <InputTextCustom >
        //                             <input type={"text"} value={email} onChange={(e) => { setEmail(e.target.value) }} />
        //                             <label onClick={() => { senEmail() }} >Enviar</label>
        //                         </InputTextCustom>
        //                     </Option>

        //                     <BoxActions>
        //                         <ButtonCustom onClick={() => saveEmail()} type={"save"}>
        //                             <label>Salvar</label>
        //                             <FaCheck color={"#FFFFFF"} size={12} />
        //                         </ButtonCustom>
        //                         <ButtonCustom onClick={() => getDefaultEmail()} type={"reset"} >
        //                             <label>Restaurar</label>
        //                             <AiOutlineReload color={"#353535"} size={12} />
        //                         </ButtonCustom>
        //                     </BoxActions>

        //                 </BoxOptions>
        //         }
        //     </Body>
        // </Container>
    )
}

export default BoxConfigEmail