import { useEffect, useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { BadgeCheck, BoxContents, BoxStars, Container, Infor, SectionMedia, SectionText } from './styled'
import { HiBadgeCheck } from "react-icons/hi";
import { getTranslation } from '../../../../translations';
import ImgCard from "../../../../assets/imgs/icon-bolsa.svg";

const CardLily = (props) => {

    const [icon, setIcon] = useState({ size: 20, color: "orange" })
    const config = useSelector(state => state.reducerControlConfig)

    useEffect(() => {
        setIcon({ ...icon, colorActive: config.star_color_primary, colorInative: config.star_color_secondary })
    }, [config])

    //console.log("´props card lily",props)

    useEffect(() => {
        //console.log("config => ", config)
    }, [config])

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    return (
        <Container background={config.background_color_secondary} borderRadius={config.border_card_radius} borderSize={config.border_card_size} borderColor={config.border_card_color}>
            <BoxStars fontColor={config.font_color_primary} position={config.position_star_review}>
                <div>
                    <span>
                        <AiFillStar size={icon.size} color={props.item.qtdStars > 0 ? icon.colorActive : icon.colorInative} />
                        <AiFillStar size={icon.size} color={props.item.qtdStars > 1 ? icon.colorActive : icon.colorInative} />
                        <AiFillStar size={icon.size} color={props.item.qtdStars > 2 ? icon.colorActive : icon.colorInative} />
                        <AiFillStar size={icon.size} color={props.item.qtdStars > 3 ? icon.colorActive : icon.colorInative} />
                        <AiFillStar size={icon.size} color={props.item.qtdStars > 4 ? icon.colorActive : icon.colorInative} />
                    </span>
                </div>
                <label style={{ visibility: config.visible_date_review == "1" ? "visible" : "hidden" }}>17/03/2023</label>
            </BoxStars>
            <BoxContents>
                <SectionText fontColor={config.font_color_primary} >{props.item.text}</SectionText>
                <SectionMedia><img src={ImgCard} /></SectionMedia>
            </BoxContents>
            <Infor fontColor={config.font_color_primary} >
                {translation.thema.cardReviewLilyPreview.enviadoPor} <strong style={{ margin: "0px 3px" }}>{props.item.name}</strong>
                <BadgeCheck>
                    {props.item?.verified == 1 && <HiBadgeCheck style={{ marginLeft: "5px" }} size={20} color={"#5cb85c"} />}
                    <label>{translation.thema.cardReviewLilyPreview.compraVerificada}</label>
                </BadgeCheck>
            </Infor>
        </Container>
    )
}

export default CardLily