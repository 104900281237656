
import { useDispatch, useSelector } from 'react-redux';
import { BoxContent, BoxImage, Container, Body, Item, Actions, Close, Title, ContainerPreview, ContainerColumn, ContainerTitle, BoxAlerts, BoxSwiper, BoxSlider, BodyNew, ContainerNew, TitleNew } from "./styled";
import { GrFormClose } from "react-icons/gr";
import imgBackground from "../../../../assets/imgs/preview-background.png";

import { useEffect, useState } from 'react';
import BoxConfigEmail from '../box-config-email';
import IconCam from "../../../../assets/imgs/icon-camera.svg";
import AlertCloseBoss from '../../../../components/alert-close-boss';
import { getTranslation } from '../../../../translations';
import { reviews } from "../../pages/home/dataPreview";

import CardDropi from "../card-review-dropi-preview";
import CardLily from "../card-review-lily-preview";
import CardTiktok from '../card-review-tiktok-preview';
import Card2Lily from '../card2-review-lily-preview';
import Card3Lily from '../card3-review-lily-preview';
import Card2Dropi from '../card2-review-dropi-preview';
import Card3Dropi from '../card3-review-dropi-preview';
import { BoxMessage } from '../../../questions/pages/home/styled';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';


import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Pagination, Autoplay, Navigation, Scrollbar, A11y, EffectCards, EffectFade, EffectCube, EffectCoverflow, EffectFlip, EffectCreative } from "swiper";
import "./styles.css";
import Card1Home from '../card1-home';

const PreviewHomeCarrossel = (props) => {
  const dispatch = useDispatch()
  const [optionSelected, setOptionSelected] = useState("reviews")
  const [icon, setIcon] = useState({ size: 20, color: "orange" })

  const configControl = useSelector(state => state.reducerControlConfig)
  const reviewsFavorites = useSelector(state => state.reducerReviews)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
  const history = useHistory();


  // useEffect(() => {
  //   //console.log("teste configControl 123 home => :", configControl)
  //   //console.log("Teste props => :", props)
  //   setIcon({ ...icon, color: configControl.fontColor })
  // }, [configControl])


  // const velocity = 6 * 25;

  const getCard = (card, item) => {
    switch (card) {
      case "lily3":
        return <Card3Lily item={item} />
      case "lily2":
        return <Card2Lily item={item} />
      case "lily":
        return <CardLily item={item} />
      case "dropi":
        return <CardDropi item={item} />
      case "dropi2":
        return <Card2Dropi item={item} />
      case "dropi3":
        return <Card3Dropi item={item} />
      case "tiktok":
        return <CardTiktok item={item} />
      case "home1":
        return <Card1Home item={item} />
      default:
        break;
    }
  }

  useEffect(() => {
    //console.log("configcxontrol", configControl)
  }, [configControl])

  // useEffect(() => {
  //   dispatch({
  //     type: "SAGA_LIST_REVIEWS", payload: {
  //       rating: "",
  //       status: "all",
  //       search: "",
  //       perPage: 10,
  //       page: 1,
  //       medias: "",
  //       favorite: true
  //     }
  //   })
  // }, [])

  // const selectedMenu = useLocation();

  // const visibleFormHome = () => {
  //   if (selectedMenu.pathname == "/personalizar/home") {
  //     if (
  //       JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "" &&
  //       JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != null &&
  //       typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "undefined"
  //       //&&        reviewsFavorites?.data?.meta?.total > 7
  //     ) {
  //       return true
  //     } else {
  //       return false
  //     }
  //   } else {
  //     return true
  //   }

  // }

  useEffect(() => {
    //document.getElementsByClassName('swiper').style.backgroundColor = "red";
    //console.log("document", document.getElementsByClassName("swiper").style.background = "#000")
    var elements = document.getElementsByClassName('swiper'); // get all elements

    //console.log("teste001", configControl.homereviews_animation)
    for (var i = 0; i < elements.length; i++) {
      elements[i].style.backgroundColor = configControl.homereviews_background_color;
    }

    var elements2 = document.getElementsByClassName('swiper-slide '); // get all elements
    for (var i = 0; i < elements2.length; i++) {
      elements2[i].style.backgroundColor = configControl.homereviews_background_color;
    }
  }, [configControl])


  return (
    <ContainerNew img={imgBackground}>
      <TitleNew
        backgroundColor={configControl.homereviews_background_color}
        fontsize={configControl.homereviews_font_size_title}
        color={configControl.homereviews_color_title}
        font={configControl.homereviews_font_title}
        align={configControl.homereviews_align_title}
      >
        <label>
          {configControl.homereviews_title}
        </label>
      </TitleNew>

      {
        configControl.homereviews_animation == "1" || configControl.homereviews_animation == true ?
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y, EffectCards, EffectCreative, EffectFlip, EffectFade, EffectCube, EffectCoverflow]}
            spaceBetween={50}
            slidesPerView={1}
            navigation
            pagination={{ clickable: true }}
            //scrollbar={{ draggable: false }}
            onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={() => console.log('slide change')}
            loop
            speed={2000}
            autoplay
            effect={"slide"}
          >

            <SwiperSlide>
              <Card1Home item={reviews[0]} />
            </SwiperSlide>
            <SwiperSlide>
              <Card1Home item={reviews[1]} />
            </SwiperSlide>

          </Swiper> :
          <div>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y, EffectCards, EffectCreative, EffectFlip, EffectFade, EffectCube, EffectCoverflow]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              //scrollbar={{ draggable: false }}
              onSwiper={(swiper) => console.log(swiper)}
              onSlideChange={() => console.log('slide change')}
              loop
              speed={2000}
              effect={"slide"}
            >

              {/* {
                reviews.map((item) => {
                  return (
                    <SwiperSlide>
                      {getCard(configControl.layout, item)}
                    </SwiperSlide>
                  )
                })
              } */}
              <SwiperSlide>
                <Card1Home item={reviews[0]} />
              </SwiperSlide>
              <SwiperSlide>
                <Card1Home item={reviews[1]} />
              </SwiperSlide>
            </Swiper>
          </div>

      }



    </ContainerNew >


    // <ContainerNew img={imgBackground}>

    //   <BodyNew>

    //     <TitleNew>
    //       <label>
    //         Avaliações dos clientes
    //       </label>
    //     </TitleNew>

    //     <Swiper
    //       modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y, EffectCards, EffectCreative, EffectFlip, EffectFade, EffectCube, EffectCoverflow]}
    //       spaceBetween={50}
    //       slidesPerView={1}
    //       navigation
    //       pagination={{ clickable: true }}
    //       //scrollbar={{ draggable: false }}
    //       onSwiper={(swiper) => console.log(swiper)}
    //       onSlideChange={() => console.log('slide change')}
    //       loop
    //       speed={2000}
    //       autoplay
    //       effect={"slide"}
    //     >

    //       {reviews.map((item) => (<SwiperSlide>              <Card1Home item={item} />            </SwiperSlide>))}

    //     </Swiper>
    //   </BodyNew>

    // </ContainerNew >

    // <>
    /*{<Container img={imgBackground}>

      {

        visibleFormHome() ?
          <div style={{ backgroundColor: "gray" }}>
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              onSlideChange={() => console.log('slide change')}
              onSwiper={(swiper) => console.log(swiper)}
            >
              <SwiperSlide>Slide 1</SwiperSlide>
              <SwiperSlide>Slide 2</SwiperSlide>
              <SwiperSlide>Slide 3</SwiperSlide>
              <SwiperSlide>Slide 4</SwiperSlide>

            </Swiper>

          </div> :

          <BoxAlerts >
            <label style={{ display: "flex", color: "#5E6278", justifyContent: "center", fontWeight: "700", fontSize: "44px" }}>Ooops...</label>
            <span>

              {
                JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "" &&
                  JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != null &&
                  typeof JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.platform != "undefined" ?
                  null : <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                    {translation.questions.nenhumaIntegracao}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { history.push("/integracoes") }} >{translation.questions.cliqueAqui}. </strong>
                  </label>
              } *

              {/* {
                  reviewsFavorites?.data?.meta?.total < 8 &&
                  <label style={{ textAlign: "center", color: "#5E6278", justifyContent: "center", marginTop: "0px", fontWeight: "500", fontSize: "18px" }}>
                    {translation.thema.minimoReviewsFavoritas}<strong style={{ marginLeft: "5px", cursor: "pointer" }} onClick={() => { history.push("/avaliacoes") }} >{translation.questions.cliqueAqui}. </strong>
                  </label>
                } *
{/* 
            </span>
          </BoxAlerts>
      }

    </Container>
    <BoxConfigEmail />
  </> */

  )
}

export default PreviewHomeCarrossel