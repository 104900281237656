import { useSelector } from "react-redux";
import { put, call, delay } from "redux-saga/effects";
import { POST, GET, PUT, DELETE, DELETE_CUSTOM } from "../../../../services/api";

export function* listQuestions(action) {
     
    yield put({ type: "QUESTION_REQUEST", })
    //yield delay(5000);
    const result = yield call(GET, { endpoint: `/asks?search=${action.payload.search}&status=${action.payload.status}&perPage=${action.payload.perPage}&page=${action.payload.page}&productId=${typeof action.payload.productId == "undefined" ? "" : action.payload.productId}` })
    //console.log("listQuestions - result", result)
    if (result === false) {
        yield put({ type: "QUESTION_ERROR" })
    } else {
        yield put({ type: "QUESTION_SUCCESS", payload: result.data.data })
    }
    yield put({ type: "SAGA_LIST_NOTIFY" })

}

export function* updateQuestion(action) {

    yield put({ type: "QUESTION_UPDATE_REQUEST", })
    //yield delay(200);
    const result = yield call(PUT, { endpoint: `/asks/${action.payload.id}`, data: action.payload })
    if (result === false) {
        yield put({ type: "QUESTION_UPDATE_ERROR" })
    } else {
        yield put({ type: "QUESTION_UPDATE_SUCCESS", payload: result.data })
    }
}

export function* deleteQuestion(action) {

    yield put({ type: "QUESTION_DELETE_REQUEST", })
    //yield delay(200);
    const result = yield call(DELETE, { endpoint: `/asks/${action.payload.id}` })
    if (result === false) {
        yield put({ type: "QUESTION_DELETE_ERROR" })
    } else {
        yield put({ type: "QUESTION_DELETE_SUCCESS", payload: result.data })
    }
}

export function* addReplies(action) { 

    yield put({ type: "REPLIES_ADD_REQUEST", })
    //yield delay(200);
    const result = yield call(POST, { endpoint: `/replies`, data: action.payload })
    if (result === false) {
        yield put({ type: "REPLIES_ADD_ERROR" })
    } else {
        yield put({ type: "REPLIES_ADD_SUCCESS", payload: result.data })
    }
}

export function* updateReplies(action) {

    yield put({ type: "REPLIES_UPDATE_REQUEST", })
    //yield delay(200);
    const result = yield call(PUT, { endpoint: `/replies/${action.payload.id}`, data: action.payload })
    if (result === false) {
        yield put({ type: "REPLIES_UPDATE_ERROR" })
    } else {
        yield put({ type: "REPLIES_UPDATE_SUCCESS", payload: result.data })
    }
}

export function* updateSelectQuestions(action) {

    yield put({ type: "QUESTION_UPDATE_SELECT_REQUEST", })
    //yield delay(5000);
    const result = yield call(PUT, { endpoint: `/asks/all`, data: action.payload })
    if (result === false) {
        yield put({ type: "QUESTION_UPDATE_SELECT_ERROR" })
    } else {
        yield put({ type: "QUESTION_UPDATE_SELECT_SUCCESS", payload: result.data })
    }
}

export function* deleteSelectQuestions(action) {

    //console.log("deleteSelectReviews payload: ", action.payload)
    yield put({ type: "QUESTION_DELETE_SELECT_REQUEST", })
    //yield delay(200);
    const result = yield call(DELETE_CUSTOM, { endpoint: `/asks/all`, data: action.payload })
    if (result === false) {
        yield put({ type: "QUESTION_DELETE_SELECT_ERROR" })
    } else {
        yield put({ type: "QUESTION_DELETE_SELECT_SUCCESS", payload: result.data })
    }
}