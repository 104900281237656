import { intervalToDuration, format, fromUnixTime } from "date-fns";

export const formatDate = (date, formatMask) => {
    return format(new Date(date), formatMask)
}

export const formatTimestamp = (date, formatMask) => {
    let completeDate = fromUnixTime(date)
    return format(completeDate, formatMask)
}

export const interval = (cretaed_at) => {
    let result = "";
    let dates = intervalToDuration({
        start: new Date(cretaed_at),
        end: new Date()
    })

    const dia = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "pt-br" ? " dia ": " día "
    const dias = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "pt-br" ? " dias ": " dias "
    const ano = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "pt-br" ? " ano ": " año "
    const anos = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "pt-br" ? " anos ": " años "
    const mes = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "pt-br" ? " mês ": " mes "
    const meses = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "pt-br" ? " meses ": " meses "
    const hora = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "pt-br" ? " hora ": " hora "
    const horas = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "pt-br" ? " horas ": " horas "
    const minuto = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "pt-br" ? " minuto ": " minuto "
    const minutos = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language == "pt-br" ? " minutos ": " minutos "
    //console.log("Dates: ", dates.minutes)

    if (dates.years == 1) return result += dates.years + ano
    else if (dates.years > 0 ) return result += dates.years + anos

    if (dates.months == 1) return result += dates.months + mes
    else if (dates.months > 0 ) return result += dates.months + meses

    if (dates.days == 1) return result += dates.days + dia
    else if (dates.days > 0 ) return result += dates.days + dias

    if (dates.hours == 1) return result += dates.hours + hora
    else if (dates.hours > 0 ) return result += dates.hours + horas

    if (dates.minutes == 1 || dates.minutes == 0) return result += dates.minutes + minuto
    else if (dates.minutes > 0 ) return result += dates.minutes + minutos

    return result
}