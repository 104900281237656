import { BoxLeft, BoxRight, Button, Container, SubTitle, Title } from "./styled"
import AnimationLily from "../../../../assets/animation/animation-empreender-lily.webm";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { getTranslation } from "../../../../translations";

const Animation = () => {

    const dashboard = useSelector(state => state.reducerDashboard)
    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

    useEffect(() => {
     
    }, [dashboard])


    return (
        <Container>
            <BoxLeft>
                <Title>{translation.dashboard.animation.ola} {JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.name.charAt(0).toUpperCase() + JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.name.slice(1)}</Title>
                <SubTitle>
                    {translation.dashboard.animation.useLily}
                </SubTitle>
                <Button onClick={() => { window.open("https://api.whatsapp.com/send?phone=558171033488") }}>{translation.dashboard.animation.suporteOnline}</Button>
            </BoxLeft>
            <BoxRight>
                <video muted="true" autoplay="true" loop="true" src={AnimationLily}></video>
            </BoxRight>
        </Container>
    )
}

export default Animation