import React from 'react'
import { useSelector } from 'react-redux'
import { getTranslation } from '../../../../translations'
import { Container, Contents, Row, RowCustom, Title } from './styled'

const TableEmailsPending = () => {

  const dashboard = useSelector(state => state.reducerDashboard)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);

  return (
    <Container>
      <Title>
        <label>{translation.dashboard.tableEmailPending.emailAguardando}</label>
      </Title>
      <Contents>
        <RowCustom> #
          <label style={{}}>{translation.dashboard.tableEmailPending.email}</label>
          <label>{translation.dashboard.tableEmailPending.pedido}</label>
          <label>{translation.dashboard.tableEmailPending.agendado}</label>
        </RowCustom>

        <RowCustom>1
          {dashboard?.data?.emailsNotSent?.length > 0 &&
            <>
              <label >
                {dashboard?.data?.emailsNotSent[0]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsNotSent[0]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsNotSent[0]?.dataSent).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>2
          {dashboard?.data?.emailsNotSent?.length > 1 &&
            <>
              <label >
                {dashboard?.data?.emailsNotSent[1]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsNotSent[1]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsNotSent[1]?.dataSent).toLocaleDateString('pt-BR')}</strong>

            </>
          }
        </RowCustom>
        <RowCustom>3
          {dashboard?.data?.emailsNotSent?.length > 2 &&
            <>
              <label >
                {dashboard?.data?.emailsNotSent[2]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsNotSent[2]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsNotSent[2]?.dataSent).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>4
          {dashboard?.data?.emailsNotSent?.length > 3 &&
            <>
              <label >
                {dashboard?.data?.emailsNotSent[3]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsNotSent[3]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsNotSent[3]?.dataSent).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>5
          {dashboard?.data?.emailsNotSent?.length > 4 &&
            <>
              <label>
                {dashboard?.data?.emailsNotSent[4]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsNotSent[4]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsNotSent[4]?.dataSent).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>6
          {dashboard?.data?.emailsNotSent?.length > 5 &&
            <>
              <label >
                {dashboard?.data?.emailsNotSent[5]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsNotSent[5]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsNotSent[5]?.dataSent).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>7
          {dashboard?.data?.emailsNotSent?.length > 6 &&
            <>
              <label >
                {dashboard?.data?.emailsNotSent[6]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsNotSent[6]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsNotSent[6]?.dataSent).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>8
          {dashboard?.data?.emailsNotSent?.length > 7 &&
            <>
              <label >
                {dashboard?.data?.emailsNotSent[7]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsNotSent[7]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsNotSent[7]?.dataSent).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>9
          {dashboard?.data?.emailsNotSent?.length > 8 &&
            <>
              <label >
                {dashboard?.data?.emailsNotSent[8]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsNotSent[8]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsNotSent[8]?.dataSent).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
        <RowCustom>10
          {dashboard?.data?.emailsNotSent?.length > 9 &&
            <>
              <label >
                {dashboard?.data?.emailsNotSent[9]?.email}
              </label>
              <label >
                {dashboard?.data?.emailsNotSent[9]?.order_id}
              </label>
              <strong>{new Date(dashboard?.data?.emailsNotSent[9]?.dataSent).toLocaleDateString('pt-BR')}</strong>
            </>
          }
        </RowCustom>
      </Contents>
    </Container>
  )
}

export default TableEmailsPending