import { Container } from './styled'

const Card = (props) => {

    return (
        <Container background={props.background} color={props.color}>
            <span>{props.headerText}</span>
            <strong>{props.mainText}</strong>
            <a href={props.btnAction} target={props.headerText !== 'Atendimento' && "_blank"} rel="noreferrer">{props.btnText}</a>
        </Container>
    );
}

export default Card